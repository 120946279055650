var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "notification-toasters-wrap" },
    _vm._l(_vm.toasters, function (toaster) {
      return _c(
        "div",
        {
          key: toaster.id,
          staticClass: "notification-toaster",
          class: [toaster.type],
          on: {
            click: function ($event) {
              return _vm.close(toaster.id)
            },
          },
        },
        [
          toaster.title
            ? _c("h4", { staticClass: "toaster-title" }, [
                _vm._v(_vm._s(toaster.title)),
              ])
            : _vm._e(),
          toaster.message
            ? _c("div", { staticClass: "toaster-message" }, [
                _vm._v(_vm._s(toaster.message)),
              ])
            : _vm._e(),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }