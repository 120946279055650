import axios from "./axios";
import { users } from "@/constants";
import { buildResponseArrayGetInterceptor, buildSearchURL } from "@/utils";

const SEARCH_FILTERS = [
  { name: "Name", value: "fullName" },
  { name: "Email", value: "email" },
  { name: "Country", value: "country" },
  { name: "Role", value: "role" },
  { name: "Created At", value: "createdAt" },
];

const userRoles = {
  admin: [{ id: "user", name: "User" }],
  accountadmin: [{ id: "accountadmin", name: "Account Admin" }],
  superadmin: [
    { id: "accountadmin", name: "Account Admin" },
    { id: "user", name: "User" },
  ],
  billingadmin: [
    { id: "accountadmin", name: "Account Admin" },
    { id: "user", name: "User" },
  ],
};

const baseUrl = "/api/v3/users";

export default {
  get filtersValues() {
    return ["name", "email", "country", "joinStatus", "role", "createdAt"];
  },
  get clearableFilters() {
    return ["clubId", "q", { name: "offset", value: "0" }];
  },
  get searchFilters() {
    return SEARCH_FILTERS;
  },

  getUserRoles(role) {
    return userRoles[role];
  },

  patch(id, data) {
    return axios.patch(`${baseUrl}/${id}`, data).then((response) => response.data);
  },

  createUser(data) {
    return axios.post(baseUrl, data, { skipAuth: true });
  },

  getAdminClubs(params) {
    return axios
      .get(`${baseUrl}/me/admin/clubs${buildSearchURL(params)}`)
      .then((response) => buildResponseArrayGetInterceptor(response.data, params));
  },

  updatePreRoll(clubId, preRollId, data) {
    return axios.patch(`${baseUrl}/me/admin/clubs/${clubId}/pre-rolls/${preRollId}`, data);
  },

  addPreRoll(clubId, data) {
    return axios.post(`${baseUrl}/me/admin/clubs/${clubId}/pre-rolls`, data);
  },

  deletePreRoll(clubId, preRollId) {
    return axios.delete(`${baseUrl}/me/admin/clubs/${clubId}/pre-rolls/${preRollId}`);
  },

  getAdminClub(id) {
    return axios
      .get(`${baseUrl}/me/admin/clubs/${id}`)
      .then((response) => Promise.resolve(response.data));
  },

  approveUser(clubId, requestId, data = null) {
    return axios
      .post(`/api/v3/clubs/${clubId}/request/${requestId}/approve`, data)
      .then((response) => response.data);
  },

  removeUserFromClub(userId, clubId) {
    return axios.delete(`${baseUrl}/${userId}/clubs/${clubId}`).then((response) => response.data);
  },

  rejectUser(clubId, requestId) {
    return axios
      .post(`/api/v3/clubs/${clubId}/request/${requestId}/reject`)
      .then((response) => response.data);
  },

  getUsers(params) {
    return axios
      .get(`${baseUrl}${buildSearchURL(params)}`)
      .then((response) => buildResponseArrayGetInterceptor(response, params));
  },

  getOneUser(id) {
    return axios.get(`${baseUrl}/${id}`).then((response) => response.data);
  },

  sendInvitation(params, config) {
    return axios
      .post(`${baseUrl}/invitations`, { emails: [params], params: config })
      .then((response) => response.data);
  },

  acceptInvitation(config, params) {
    return axios
      .post(`${baseUrl}/invitations/accept`, params, config)
      .then((response) => response.data);
  },

  get filterOptions() {
    return users.users.filterOptions;
  },

  deleteUser(userId) {
    return axios.delete(`${baseUrl}/${userId}`);
  },
  getUsersExportData(params) {
    return axios
      .get(`${baseUrl}/export-data${buildSearchURL(params)}`)
      .then((response) => response);
  },
};
