<template lang="pug">
    ValidationProvider.image-upload-component(
        ref="validator"
        tag="div"
        :vid="vid || name"
        :name="errorLabel || label || name"
        :rules="rules"
        v-slot="{ errors, reset }"
    )
        .upload-component.relative
            .upload-wrapper(@click="!image && changeImage()" v-on="uploadWrapperListeners" :class="{ banner: isBannerUpload }")
                .img-label.text-center(v-if="label || name")
                    | {{ label || name }}
                .upload-file(:class="{ dragover: dragover.accept, 'dragover-err': dragover.reject }" :disabled="disabled")
                    input(
                        v-show="false"
                        :id="name"
                        type="file"
                        ref="fileInput"
                        accept=".jpg, .jpeg, .png"
                        @change.stop="onFileChange($event.target.files[0])"
                        :disabled="disabled"
                    )

                    i.fa.fa-download(v-show="!image")
                        | Select or drag file here
                    img(:src="image" :class="{ invisible: !image }")
                    .opacity-wrapper(v-show="value" :class='{ hidden: disabled }')
                    .buttons-wrapper(v-show="value", :class='{ hidden: disabled, center: !isDeleteAllowed }')
                        button.pxl-button.btn.btn-danger.remove(@click.stop="removeImage(reset)" type="button" v-if="isDeleteAllowed")
                            i.fa.fa-trash-o(aria-hidden="true")
                            | Remove
                        button.btn.btn-success.change.pxl-button(@click.stop="changeImage" type="button")
                            i.fa.fa-exchange(aria-hidden="true")
                            | Change
        slot(name="hint")
        .selected-file-wrapper
            .selected-file(v-if="value && value.name && value.size")
                | Selected file:
                span &nbsp;{{ `${value.name}(${convertedSize}MB)` }}
            .errors-wrapper(v-if="errors[0]")
                span
                    strong Error: &nbsp;
                    | {{ errors[0] }}
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { fileToBase64, convertBytesToMB } from "@/utils";

export default {
  name: "ImageUpload",
  components: { ValidationProvider },
  props: {
    vid: {
      type: String,
      default: undefined,
    },
    // eslint-disable-next-line vue/require-prop-types
    value: {
      required: true,
      default: null,
    },
    name: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    errorLabel: {
      type: String,
      default: "",
    },
    disabled: Boolean,
    rules: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "",
    },
    isDeleteAllowed: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      image: "",
      dragover: {
        accept: false,
        reject: false,
      },
    };
  },
  computed: {
    uploadWrapperListeners: function () {
      const vm = this;

      return Object.assign({}, this.$listeners, {
        dragover: vm.onDropEnter,
        dragenter: vm.onDropEnter,
        dragleave: vm.onDropLeave,
        dragend: vm.onDropLeave,
        drop: vm.onDrop,
      });
    },
    convertedSize() {
      return convertBytesToMB(this.value.size);
    },
    isBannerUpload() {
      return ["banner", "mobileBanner"].includes(this.name);
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (typeof value === "string" || value === null) {
          this.image = value;
        }
      },
    },
  },
  methods: {
    onDrop(event) {
      event.preventDefault();
      this.dragover.accept = false;
      this.dragover.reject = false;

      if (!this.isImageDragged(event)) {
        return;
      }

      const evnt = event.originalEvent ? event.originalEvent : event;
      const file = evnt.dataTransfer.files[0];

      this.onFileChange(file);
    },
    onDropEnter(event) {
      event.preventDefault();

      if (!this.isImageDragged(event)) {
        this.dragover.reject = true;
      } else {
        this.dragover.accept = true;
      }
    },
    onDropLeave(event) {
      event.preventDefault();
      this.dragover.accept = false;
      this.dragover.reject = false;
    },
    async onFileChange(file) {
      if (!file) {
        return;
      }

      this.$refs.validator.syncValue([file]);
      this.$refs.validator.validate();
      this.$refs.validator.setFlags({ pristine: false });

      this.image = await fileToBase64(file);
      this.$emit("input", file);
    },
    removeImage(resetFileValidation) {
      const vm = this;

      this.$modal.confirm({
        title: "Confirm removing",
        text: "Are you sure you want to remove image?",
        success: () => {
          vm.clearImageData();
          resetFileValidation();
          this.$refs.validator.setFlags({ pristine: false });
        },
      });
    },
    clearImageData() {
      this.$emit("input", null);
      this.image = null;
      this.$refs.fileInput.value = null;
    },
    changeImage() {
      this.$refs.fileInput.click();
    },
    isImageDragged(event) {
      return event?.dataTransfer?.items[0]?.type.includes("image/");
    },
  },
};
</script>

<style lang="stylus">
.image-upload-component
    .upload-component
        .upload-wrapper
          &.banner
            width 380px
            .upload-file
                width 380px
                img
                    max-width 100%
                    object-fit cover

    @media only screen and (max-width: 992px)
        .upload-component
            .upload-wrapper
              &.banner
                width: 100%
                .upload-file
                  width 100%
                &:hover
                    .opacity-wrapper
                        display block
</style>
