<template lang="pug">
    table.table.table-striped.pxl-table
        colgroup.default-colgroup
            col(v-if="selection" :class="selection.colClass")
            template(v-for="(col, index) in cols")
                col(v-if="!col.hidden" :key="index" :class="col.class")
            col(v-if="actions" :class="actions.colClass")
        table-head(:cols="cols" :selected-options="selectedOptions")
            template(v-slot:selection="" v-if="selection" :class="selection.colClass")
                th(:class="selection.colClass")
                    checkbox(
                        :value="allSelectCheckboxIsSelected"
                        :indeterminate="allSelectCheckboxIsIndeterminate"
                        @input="onSelectAll"
                    )
            template(v-slot:actions="" v-if="actions" :class="actions.colClass")
                th(:class="actions.colClass")
                    div
                        span Actions
        tbody
            template(v-for="(item, itemIndex) in tableItems")
                table-row(
                    :cols="cols"
                    :item="item"
                    :item-index="itemIndex"
                    :selection="selection"
                    :actions="actions"
                    :key="item.id"
                    @select="onItemSelect"
                    @click.native="onRowClick(item)"
                )
                    template(v-for="(index, name) in $scopedSlots" v-slot:[name]="data")
                        slot(:name="name" v-bind="data")
                tr.details-section(v-if="viewedItem && viewedItem.id === item.id")
                    slot(name="rowViewedItemDetails" :item="item")
</template>
<script>
import { Checkbox } from "@/components/shared";
import TableRow from "./TableRow.vue";
import TableHead from "./TableHead.vue";

export default {
  name: "PixellotTable",
  components: { TableRow, Checkbox, TableHead },
  props: {
    items: {
      type: Array,
      required: true,
    },
    viewedItem: {
      type: Object,
      default: null,
    },
    cols: {
      type: Array,
      required: true,
    },
    actions: {
      type: Object,
      default: null,
    },
    selection: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    filtersValues: {
      type: Array,
      default: () => [],
    },
    ready: {
      type: Boolean,
    },
  },
  data() {
    return {
      selectedOptions: {},
      tableItems: [],
    };
  },
  computed: {
    selectedItems() {
      return this.tableItems.filter((item) => item.selected);
    },
    allSelectCheckboxState() {
      return this.selectedItems.length !== 0
        ? this.selectedItems.length === this.tableItems.length
          ? "selectedAll"
          : "indeterminate"
        : "nothingSelected";
    },
    allSelectCheckboxIsIndeterminate() {
      return this.allSelectCheckboxState === "indeterminate";
    },
    allSelectCheckboxIsSelected() {
      return this.allSelectCheckboxState === "selectedAll" || this.allSelectCheckboxIsIndeterminate;
    },
  },
  watch: {
    items: {
      immediate: true,
      handler() {
        this.mapTableItems();
        this.$emit("selection-change", []);
      },
    },
    "$route.query": {
      deep: true,
      immediate: true,
      handler() {
        this.syncSelectedOptionsWithQuery();
      },
    },
    ready: {
      handler() {
        this.syncSelectedOptionsWithQuery();
      },
    },
  },
  methods: {
    onSelectAll(value) {
      this.tableItems.forEach((item) => {
        if (!item.isSelectionDisabled) {
          item.selected = value;
        }
      });
      this.$emit("selection-change", this.selectedItems);
    },
    onItemSelect(item, value) {
      item.selected = value;
      this.$emit("selection-change", this.selectedItems, item);
    },
    onRowClick(item) {
      this.$emit("row-click", item);
    },
    syncSelectedOptionsWithQuery() {
      const query = this.$route.query;

      this.filtersValues.map((paramName) => {
        if (query[paramName] && this.options[paramName]) {
          const ids = query[paramName].split(",");

          if (paramName === "smStatus" && ids.length > 1) {
            this.selectedOptions[paramName] = undefined;

            return;
          }

          this.$set(
            this.selectedOptions,
            paramName,
            this.options[paramName].filter((option) => ids.includes(option.id)),
          );
        } else {
          this.$set(this.selectedOptions, paramName, undefined);
        }
      });
    },
    mapTableItems() {
      this.tableItems = this.items.map((item) => {
        const rowItem = Object.keys(item.attributes).reduce((acc, attrKey) => {
          const col = this.cols.find((col) => col.name === attrKey);

          if (col) {
            const value = item.attributes[attrKey];

            acc[attrKey] = col.valueKey ? value[col.valueKey] : value;
          }

          return acc;
        }, {});

        rowItem.id = item.id;
        rowItem.selected = item.selected || false;
        rowItem.pinned = item.pinned || false;
        rowItem.originalItem = item;
        rowItem.vodDeletedAt = item.attributes.vodDeletedAt;
        rowItem.isSelectionDisabled = item.isSelectionDisabled;
        rowItem.showMore = item.showMore || false;

        return rowItem;
      });
    },
  },
};
</script>
