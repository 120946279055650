export const routesMapper = {
  Applications: "applications",
  Events: "events",
  EventsCreate: "event-create",
  EventsCreateBulk: "event-bulk-create",
  EventsEdit: "event-edit",
  EventsEditBulk: "event-bulk-edit",
  Categories: "categories",
  CategoriesCreate: "category-create",
  CategoriesEdit: "category-edit",
  ClubsEdit: "club-edit",
  ClubsCreate: "club-create",
  ClubsEditBulk: "club-bulk-edit",
  Clubs: "clubs",
  Teams: "teams",
  TeamsCreate: "team-create",
  TeamsEdit: "team-edit",
  Tools: "tools",
  Accounts: "accounts",
  Users: "users",
  UsersCreate: "users-create",
  UsersEdit: "users-edit",
  Monitoring: "monitoring",
  BulkResult: "bulk-result",
  Media: "media",
  About: "about",
};
