import axios from "./axios";
import store from "@/store";
import { buildSearchURL } from "@/utils";
import countriesData from "@/assets/files/countries.json";
import { accountSettings, state } from "@/constants";

const { WHITE_LIST, BLACK_LIST } = accountSettings[state.ACCOUNT_SETTINGS].geoBlockingTypes;

const baseUrl = "/api/v3/account-settings";

const GEO_BLOCKING_TYPES = [
  {
    title: "Allow countries/regions",
    value: WHITE_LIST,
  },
  {
    title: "Restrict countries/regions",
    value: BLACK_LIST,
  },
];

export default {
  get geoBlockingTypes() {
    return GEO_BLOCKING_TYPES;
  },
  getAccountSettings(params = {}) {
    const activeTenant = store.state.user.activeTenant;

    if (activeTenant && !params.tenant) {
      params.tenant = activeTenant;
    }

    return axios.get(`${baseUrl}${buildSearchURL(params)}`).then((response) => response.data);
  },
  saveAccountSettings(data) {
    return axios.post(`${baseUrl}`, data);
  },
  getCountries() {
    return countriesData.countries.map(({ name, code, regions }) => ({
      name,
      code,
      regions,
      id: code,
    }));
  },
  getRegions(countriesList, selectedCountries = []) {
    const mappedSelectedCountries = selectedCountries.map(({ code }) => code);
    const countries = countriesList.filter(({ code }) => mappedSelectedCountries.includes(code));
    const foundedRegions = countries.map(({ regions = [] }) => regions);

    return [...new Set(foundedRegions.flat().map(({ name, code }) => ({ name, code, id: code })))];
  },
};
