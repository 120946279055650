export const Cookies = {
  get all() {
    const cookies = document.cookie ? document.cookie.split("; ") : [];
    const cookiesObj = {};

    for (let i = 0; i < cookies.length; i++) {
      const parts = cookies[i].split("=");

      cookiesObj[parts[0]] = parts[1];
    }

    return cookiesObj;
  },

  get(key) {
    return this.all[key];
  },

  set(key, value, days) {
    let expires = "";

    if (days) {
      const date = new Date();

      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `; expires=${date.toGMTString()}`;
    }

    document.cookie = `${key}=${value}${expires}; path=/; secure`;
  },
};
