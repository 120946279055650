<template lang="pug">
    #overlay(:class="{ 'block-overlay': partialLoader, 'white-background': whiteBackground }" v-show="showElement")
        #floatingCirclesG
            #frotateG_01.f_circleG
            #frotateG_02.f_circleG
            #frotateG_03.f_circleG
            #frotateG_04.f_circleG
            #frotateG_05.f_circleG
            #frotateG_06.f_circleG
            #frotateG_07.f_circleG
            #frotateG_08.f_circleG
</template>

<script>
export default {
  name: "Loader",
  props: {
    delay: {
      type: [String, Number],
      default: null,
    },
    partialLoader: Boolean,
    whiteBackground: Boolean,
  },
  data() {
    return {
      showTimer: false,
      showElement: false,
      showLoading: false,
    };
  },
  watch: {
    showLoading: {
      immediate: true,
      handler: "toggleElementShow",
    },
  },
  created() {
    this.$root.$on("loading", (value) => {
      this.showLoading = value;
    });
  },
  methods: {
    getDelay() {
      // eslint-disable-next-line radix
      const delay = parseInt(this.delay);

      return isNaN(delay) ? 200 : delay;
    },

    toggleElementShow(value) {
      setTimeout(() => {
        this.showElement = value;
      }, this.getDelay());
    },
  },
};
</script>

<style lang="stylus">
#overlay
    position fixed
    top 0
    left 0
    z-index loader-overlay-z-index
    width 100%
    height 100%
    background rgba(0, 0, 0, 0.3)
    &.block-overlay
        position absolute
    &.white-background
        background white

    #floatingCirclesG
        position absolute
        width 112px
        height 112px
        margin auto
        top 0
        left 0
        bottom 0
        right 0
        transform scale(0.6)

        .f_circleG
            position absolute
            background-color rgb(255,255,255)
            height 20px
            width 20px
            border-radius 10px
            animation-name f_fadeG
            animation-duration 1.2s
            animation-iteration-count infinite
            animation-direction normal

        #frotateG_01
            left 0
            top 45px
            animation-delay 0.45s

        #frotateG_02
            left 13px
            top 13px
            animation-delay 0.6s

        #frotateG_03
            left 45px
            top 0
            animation-delay 0.75s

        #frotateG_04
            right 13px
            top 13px
            animation-delay 0.9s

        #frotateG_05
            right 0
            top 45px
            animation-delay 1.05s

        #frotateG_06
            right 13px
            bottom 13px
            animation-delay 1.2s

        #frotateG_07
            left 45px
            bottom 0
            animation-delay 1.35s

        #frotateG_08
            left 13px
            bottom 13px
            animation-delay 1.5s

@keyframes f_fadeG
    0% {
        background-color rgb(0,0,0)
    }
    100% {
        background-color rgb(255,255,255)
    }
</style>
