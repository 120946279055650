export default [
  {
    name: "Events",
    path: "/events",
    meta: { title: "Events", group: "Events", requiresAuth: true, requiresAccountTenant: true },
    component: (resolve) => require(["@events/pages/index.vue"], resolve),
    beforeEnter: (to, from, next) => {
      if (!to.query.limit) {
        to.query.limit = 9;
        next(to);
      } else if (!to.query.status) {
        to.query.status = "upcoming,live,initializing";
        next(to);
      } else if (!to.query.sort) {
        to.query.sort = "startDateTime";
        next(to);
      } else {
        next();
      }
    },
  },
  {
    name: "LiveEvents",
    path: "/live-events",
    meta: {
      title: "Live Events",
      group: "LiveEvents",
      requiresAuth: true,
      requiresAccountTenant: true,
    },
    component: (resolve) => require(["@events/pages/index.vue"], resolve),
  },
  {
    name: "EventsCreate",
    path: "/events/create",
    meta: { title: "Create event", requiresAuth: true, group: "Events" },
    component: (resolve) => require(["@events/pages/create.vue"], resolve),
  },
  {
    name: "VodEventsCreate",
    path: "/events/create-vod",
    meta: { title: "Create VOD event", requiresAuth: true, group: "Events" },
    component: (resolve) => require(["@events/pages/create-vod.vue"], resolve),
  },
  {
    name: "EventsCreateBulk",
    path: "/events/create-bulk",
    meta: { title: "Bulk: Create events", requiresAuth: true, group: "Events" },
    component: (resolve) => require(["@events/pages/create-bulk.vue"], resolve),
  },
  {
    name: "EventsEdit",
    path: "/events/:id/edit",
    meta: { title: "Edit event", requiresAuth: true, group: "Events" },
    component: (resolve) => require(["@events/pages/_id/edit.vue"], resolve),
  },
  {
    name: "EventsEditBulk",
    path: "/events/bulk",
    meta: { title: "Bulk: Edit events", requiresAuth: true },
    component: (resolve) => require(["@events/pages/bulk.vue"], resolve),
  },
  {
    name: "Monitoring",
    path: "/monitoring",
    meta: { title: "Monitoring", requiresAuth: true, requiresAccountTenant: true },
    component: (resolve) => require(["@events/pages/monitoring.vue"], resolve),
  },
  {
    name: "PlayerHighlights",
    path: "/player-highlights",
    meta: { title: "Player Highlights", requiresAuth: true },
    component: (resolve) => require(["@events/pages/player-highlights.vue"], resolve),
  },
  {
    name: "MixerHighlights",
    path: "/mixer-highlights",
    meta: { title: "Mixer Highlights", requiresAuth: true },
    component: (resolve) => require(["@events/pages/mixer-highlights.vue"], resolve),
  },
];
