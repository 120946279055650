var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.hideDropdown,
          expression: "hideDropdown",
        },
      ],
      staticClass: "search-component pull-right",
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.searchValue,
            expression: "searchValue",
          },
        ],
        class: { "open-dropdown": _vm.dropdownFocused },
        attrs: { id: "search-field", placeholder: _vm.placeholder },
        domProps: { value: _vm.searchValue },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.searchValue = $event.target.value
            },
            function ($event) {
              return _vm.updateSearchQuery($event.target.value)
            },
          ],
          focus: function ($event) {
            _vm.dropdownFocused = true
          },
        },
      }),
      _c("label", {
        staticClass: "search-icon",
        attrs: { for: "search-field" },
      }),
      _vm.multiSearch
        ? _c("div", { staticClass: "dropdown-menu-search" }, [
            _c("div", { staticClass: "search-content" }, [
              _c("div", { staticClass: "search-by" }, [
                !_vm.checkAllOptions && _vm.selectedFiltersNames.length > 0
                  ? _c("span", [
                      _vm._v("Search by " + _vm._s(_vm.selectedFiltersNames)),
                    ])
                  : _vm._e(),
                _vm.checkAllOptions &&
                (_vm.$route.query.q || _vm.selectedFiltersNames.length > 0)
                  ? _c("span", [_vm._v("Search by all")])
                  : _vm._e(),
              ]),
              _c(
                "ul",
                _vm._l(_vm.searchFilters, function (option) {
                  return _c(
                    "li",
                    { key: option.id },
                    [
                      _c(
                        "checkbox",
                        {
                          on: {
                            input: function ($event) {
                              return _vm.toggleSearchOption(option)
                            },
                          },
                          model: {
                            value: option.selected,
                            callback: function ($$v) {
                              _vm.$set(option, "selected", $$v)
                            },
                            expression: "option.selected",
                          },
                        },
                        [_vm._v(_vm._s(option.name))]
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }