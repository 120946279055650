import axios from "./axios";
import store from "@/store";
import { buildSearchURL, buildResponseArrayGetInterceptor } from "@/utils";
import currencyData from "@/assets/files/currency.json";

const baseUrl = "/api/v3/subscriptions";

export default {
  getSubscriptionsByTenant(params) {
    const activeTenant = store.state.user.activeTenant;

    if (activeTenant && !params.tenant) {
      params.tenant = activeTenant;
    }

    return axios
      .get(`${baseUrl}${buildSearchURL(params)}`)
      .then((response) => buildResponseArrayGetInterceptor(response, params));
  },
  getSubscription(id) {
    return axios.get(`${baseUrl}/${id}`).then((response) => response.data);
  },
  createSubscription(subscription) {
    return axios.post(`${baseUrl}`, subscription);
  },
  updateSubscription(id, params) {
    return axios.patch(`${baseUrl}/${id}`, params);
  },
  getCurrency() {
    return currencyData.currency.map(({ alpha, symbol, numeric }) => ({
      alpha,
      id: numeric,
      label: `${alpha} ${symbol}`,
    }));
  },
};
