import axios from "./axios";
import { buildResponseArrayGetInterceptor, buildSearchURL } from "@/utils";

const baseUrl = "/api/v3/packages";

export default {
  getPackages(params) {
    return axios
      .get(`${baseUrl}${buildSearchURL(params)}`)
      .then((response) => buildResponseArrayGetInterceptor(response, params));
  },
  createPackage(data) {
    return axios.post(baseUrl, data);
  },
  updatePackage(id, data) {
    return axios.patch(`${baseUrl}/${id}`, data).then((response) => response.data);
  },
  deletePackage(pkgId) {
    return axios.delete(`${baseUrl}/${pkgId}`);
  },
};
