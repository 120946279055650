import axios from "./axios";
import { buildResponseArrayGetInterceptor, buildSearchURL } from "@/utils";

const baseUrl = "/api/v3/firmwares";

export default {
  getInstallers(params) {
    return axios
      .get(`${baseUrl}${buildSearchURL(params)}`)
      .then((response) => buildResponseArrayGetInterceptor(response, params));
  },
  createInstaller(data) {
    return axios.post(baseUrl, data);
  },
  updateInstaller(id, data) {
    return axios.patch(`${baseUrl}/${id}`, data).then((response) => response.data);
  },
  deleteInstaller(id) {
    return axios.delete(`${baseUrl}/${id}`);
  },
};
