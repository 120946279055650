<template lang="pug">
    validation-provider(
        tag="div"
        :vid="vid || name"
        :rules="rules"
        :name="errorLabel || label || name"
        class="pxl-select"
        v-slot="{ errors }"
    )
        v-select(
            ref="select"
            :options="filteredOptions"
            :id="name"
            :value="value"
            :reduce="reducer"
            :label="optionsLabel"
            :disabled="disabled"
            :multiple="multiple"
            :clearable="clearable"
            :placeholder="placeholder"
            :get-option-key="getOptionKey"
            :get-option-label="getOptionLabel"
            :create-option="createOption"
            :push-tags="pushTags"
            :taggable="taggable"
            :class="{ 'invalid': errors[0], 'has-value': hasValue, 'focus': focused }"
            @input="updateValue"
            @search="onSearch"
            @open="onOpen"
            @close="onClose"
        )
            template(#selected-option="option")
                slot(name="selected-option" :option="option")
            template(#option="option")
                slot(name="option" :option="option")
        label(v-if="label !== null" :for="name" :class="{ 'dropdown-open': focused }" @click="focusInput")
            span {{ label || name }}
        .errors-container(v-if="errors[0]")
            span(v-for="(error, index) in errors" :key="index")
                strong Error:&nbsp;
                | {{ error }}
</template>

<script>
import _ from "lodash";
import vSelect from "vue-select";
import { ValidationProvider } from "vee-validate";
import "vue-select/dist/vue-select.css";

export default {
  name: "OptionsSelect",
  components: { ValidationProvider, vSelect },
  props: {
    vid: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: "",
    },
    label: {
      type: [String, null],
      default: "",
    },
    errorLabel: {
      type: String,
      default: "",
    },
    rules: {
      type: [Object, String],
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      required: true,
    },
    optionsLabel: {
      type: String,
      default: "name",
    },
    optionsKey: {
      type: String,
      default: "id",
    },
    value: {
      type: [Array, Object, String, Number],
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    reduce: {
      type: Function,
      default: null,
    },
    getOptionLabel: {
      type: Function,
      default: function (option) {
        if (typeof option === "object") {
          if (!option.hasOwnProperty(this.label)) {
            return;
          }

          return option[this.label];
        }

        return option;
      },
    },
    getOptionKey: {
      type: Function,
      default: undefined,
    },
    createOption: {
      type: Function,
      default(newOption) {
        if (typeof this.options[0] === "object") {
          newOption = { [this.label]: newOption };
        }

        this.$emit("option:created", newOption);

        return newOption;
      },
    },
    pushTags: {
      type: Boolean,
      default: false,
    },
    taggable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    hasValue() {
      if (this.value instanceof Array) {
        return Boolean(this.value.length);
      }

      return Boolean(this.value);
    },
    reducer() {
      if (this.reduce) {
        return this.reduce;
      } else if (this.optionsKey) {
        const key = this.optionsKey;

        return (option) => option[key];
      }

      return (option) => option;
    },
    filteredOptions() {
      return this.options.filter((option) => {
        if (this.multiple && this.value) {
          return !this.value.some((item) =>
            typeof item === "object" && !this.optionsKey
              ? item.id === option.id
              : this.reducer(item) === this.reducer(option),
          );
        }

        return true;
      });
    },
  },
  methods: {
    onSearch(search, loading) {
      this.$emit("search", search, loading);
    },
    focusInput() {
      this.$refs.select && this.$refs.select.$refs.search.focus();
    },
    updateValue(value) {
      this.$emit("input", value);
    },
    setFocus(value) {
      this.focused = value;
    },
    async onOpen() {
      this.setFocus(true);
    },
    onClose() {
      this.setFocus(false);
    },
  },
};
</script>

<style lang="stylus"></style>
