export default [
  {
    name: "Marketplace",
    path: "/marketplace",
    meta: {
      title: "Marketplace",
      requiresAuth: true,
      accessRoles: ["billingadmin"],
    },
    component: (resolve) => require(["@marketplace/pages/index.vue"], resolve),
  },
  {
    name: "CreateExtension",
    path: "/marketplace/create",
    meta: {
      title: "Create Extension",
      requiresAuth: true,
      accessRoles: ["billingadmin"],
    },
    component: (resolve) => require(["@marketplace/pages/create.vue"], resolve),
  },
  {
    name: "EditExtension",
    path: "/marketplace/:id/edit",
    meta: {
      title: "Edit Extension",
      requiresAuth: true,
      accessRoles: ["billingadmin"],
    },
    component: (resolve) => require(["@marketplace/pages/_id/edit.vue"], resolve),
  },
];
