<template lang="pug">
    .color-picker
        verte(:value="value" @input="$emit('input', $event)" model="hex")
            svg(viewBox="0 0 24 24")
                rect(x="0" y="0" width="24" height="24" d="M0 20h24v4H0z")
        input(type="text" :value="value" disabled="true")
</template>

<script>
import Verte from "verte";
import "verte/dist/verte.css";

export default {
  name: "ColorPicker",
  components: { Verte },
  props: {
    value: {
      type: String,
      default: "#000",
    },
  },
};
</script>

<style lang="stylus">
.color-picker
    display flex
    align-items flex-start
    justify-content flex-start
    .verte__menu-origin
        z-index: 2000;
</style>
