/* globals firebaseConfig */
import store from "@/store";
import {
  getAuth,
  OAuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
  linkWithCredential,
  fetchSignInMethodsForEmail,
  createUserWithEmailAndPassword,
  updateProfile,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { initializeApp, getApp, getApps } from "firebase/app";
import { AuthApi } from "@/api";

let refreshTokenIntervalId = null;

initializeApp(firebaseConfig);

getAuth().onAuthStateChanged(async (user) => {
  const authToken = await AuthApi.getAuthToken();
  const isFirebaseProvider = store.getters["user/isFirebaseProvider"];

  if (user && authToken && isFirebaseProvider) {
    store.dispatch("auth/loginWithUser", {
      data: getFormattedUserData(user),
      token: user.accessToken,
    });
  }
});

function startTokenRefreshInterval() {
  if (refreshTokenIntervalId) {
    clearInterval(refreshTokenIntervalId);
  }

  refreshTokenIntervalId = setInterval(async () => {
    try {
      const auth = getFirebaseAuth();

      if (auth.currentUser) {
        const newToken = await auth.currentUser.getIdToken(true); // Force token refresh

        if (newToken) {
          return newToken;
        }
      }
    } catch (error) {
      console.error("Failed to refresh token:", error);
    }
  }, 3300 * 1000); // 55 minutes in milliseconds
}

function getFirebaseAuth() {
  const isFirebaseAppInitialized = Boolean(getApps().length);

  return isFirebaseAppInitialized ? getAuth(getApp()) : null;
}

function getFormattedUserData(user) {
  return {
    id: user.uid,
    attributes: {
      fullName: user.displayName,
      email: user.email,
      tenants: store.getters["user/tenants"],
      clubs: [],
      role: store.getters["user/role"] || "user",
    },
  };
}

function getFirebaseAuthUser() {
  const auth = getFirebaseAuth();

  if (auth.currentUser) {
    return {
      data: getFormattedUserData(auth.currentUser),
      included: [
        {
          type: "token",
          id: auth.currentUser.accessToken,
        },
      ],
    };
  }

  return null;
}

async function signInWithProvider(provider) {
  async function loginUser(user) {
    const token = await user.getIdToken();
    const userData = {
      token,
      data: getFormattedUserData(user),
    };

    store.dispatch("auth/loginWithUser", userData);
  }

  async function handleUserWithExistingCredential(email, credential) {
    const [signInMethod] = await fetchSignInMethodsForEmail(firebaseAuth, email);

    if (!signInMethod) {
      throw new Error("Account is linked to an unsupported provider");
    }

    const linkedProvider = getAuthProvider(signInMethod);

    linkedProvider.setCustomParameters({ login_hint: email });

    const { user } = await signInWithPopup(firebaseAuth, linkedProvider);

    await linkWithCredential(user, credential);

    await loginUser(user);
  }

  const firebaseAuth = getFirebaseAuth();

  if (!firebaseAuth) {
    throw new Error("Firebase app has not been initialized");
  }

  const authProvider = getAuthProvider(provider);

  try {
    const { user } = await signInWithPopup(firebaseAuth, authProvider);

    await loginUser(user);
  } catch (error) {
    if (error.code !== "auth/account-exists-with-different-credential") {
      throw error;
    }

    const credential = OAuthProvider.credentialFromError(error);

    await handleUserWithExistingCredential(error.customData.email, credential);
  }
}

function getAuthProvider(providerId) {
  switch (providerId) {
    case GoogleAuthProvider.PROVIDER_ID:
      return new GoogleAuthProvider().setCustomParameters({ prompt: "select_account" });
    case FacebookAuthProvider.PROVIDER_ID:
      return new FacebookAuthProvider();
    default:
      throw new Error(`Unknown provider: ${providerId}`);
  }
}

/**
 * Returns the
 * @param {*=} firebaseUser instance of the firebase user.
 * @param {boolean=} refresh specifies whether or not to force refresh the token.(`false` by default.)
 * @returns {Promise}
 */
function getFirebaseAuthToken(firebaseUser, refresh = false) {
  // Note: `getIdToken` function is synchronous if `true` did't passed
  // if `true` is passed then token refresh will be forced and firebase will send a request for tokens refresh.
  if (firebaseUser) {
    return firebaseUser.getIdToken(refresh);
  }

  const auth = getFirebaseAuth();

  if (auth.currentUser) {
    return auth.currentUser.getIdToken(refresh);
  }

  return Promise.resolve(null);
}

async function createFirebaseUserWithEmailAndPassword({ email, password, fullName }) {
  const firebaseAuth = getFirebaseAuth();

  if (!firebaseAuth) {
    throw new Error("Firebase app has not been initialized");
  }

  const { user } = await createUserWithEmailAndPassword(firebaseAuth, email, password);

  await updateProfile(firebaseAuth.currentUser, { displayName: fullName });

  return {
    included: [
      {
        type: "token",
        id: user.accessToken,
      },
    ],
    data: getFormattedUserData(user),
  };
}

async function signInUserWithEmailAndPassword({ email, password }) {
  const firebaseAuth = getFirebaseAuth();

  if (!firebaseAuth) {
    throw new Error("Firebase app has not been initialized");
  }

  const { user } = await signInWithEmailAndPassword(firebaseAuth, email, password);

  return {
    token: user.accessToken,
    data: getFormattedUserData(user),
  };
}

const firebaseAuth = {
  getToken: getFirebaseAuthToken,
  getUser: getFirebaseAuthUser,
  signInWithProvider: signInWithProvider,
  createUserWithEmailAndPassword: createFirebaseUserWithEmailAndPassword,
  signInUserWithEmailAndPassword: signInUserWithEmailAndPassword,
  startTokenRefreshInterval: startTokenRefreshInterval,
};

export default firebaseAuth;
