const supportedTypes = ["default", "confirm", "error", "custom"];

export default {
  name: "ModalMixin",
  computed: {
    visibleClass() {
      return this.visible ? "fade in" : "fade out";
    },
    modalWindowClass() {
      switch (this.type) {
        case "error":
          return "modal-danger";
        default:
          return "";
      }
    },
  },
  methods: {
    onSuccessConfirm() {
      this.options.success && this.options.success();
      this.close();
    },
    onCancelConfirm() {
      this.options.cancel && this.options.cancel();
      this.close();
    },
    show(type = "default", options = {}) {
      if (!supportedTypes.includes(type)) {
        throw new Error("Unsupported modal type!");
      }

      this.type = type;
      this.options = options;
      this.setShowing(true);
    },

    close() {
      if (!this.visible) {
        return;
      }

      this.setShowing(false);
    },

    setShowing(value) {
      if (value) {
        this.domInserted = value;
        setTimeout(() => {
          this.visible = value;
        }, 100);
      } else {
        this.visible = value;
        setTimeout(() => {
          this.domInserted = value;
        }, 100);
      }
    },
  },
};
