<template lang="pug">
    validation-provider(
        tag="div"
        :vid="vid || name"
        :rules="rules"
        :name="errorLabel || label || name"
        v-slot="{ errors }"
    )
        input(
            type="radio"
            :id="name"
            :name="name"
            :value="selectValue"
            :disabled="disabled"
            v-model="innerValue"
        )
        label(:for="name")
            span {{ label || name }}
        .errors-container(v-if="errors[0]")
            span(v-for="(error, index) in errors" :key="index")
                strong Error:&nbsp;
                | {{ error }}
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  name: "RadioButton",
  components: { ValidationProvider },
  props: {
    vid: {
      type: String,
      default: undefined,
    },
    value: {
      type: Boolean,
    },
    selectValue: {
      type: null,
      default: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    name: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    errorLabel: {
      type: String,
      default: "",
    },
    rules: {
      type: [Object, String],
      default: "",
    },
  },
  data() {
    return {
      innerValue: this.value,
    };
  },
  watch: {
    value(value) {
      this.innerValue = value;
    },
    innerValue(value) {
      this.$emit("input", value);
    },
  },
};
</script>
