var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationProvider", {
    ref: "validator",
    staticClass: "file-upload-container",
    attrs: {
      tag: "div",
      vid: _vm.vid || _vm.name,
      name: _vm.errorLabel || _vm.name,
      rules: _vm.rules,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function (ref) {
            var errors = ref.errors
            return [
              _c(
                "div",
                [
                  _c(
                    "label",
                    {
                      staticClass:
                        "btn btn-primary pxl-button file-upload-button full-width",
                      attrs: { disabled: _vm.disabled },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false",
                          },
                        ],
                        ref: "fileInput",
                        staticClass: "add-banner",
                        attrs: { type: "file", accept: _vm.accept },
                        on: {
                          change: function ($event) {
                            $event.stopPropagation()
                            return _vm.onChangeFile($event.target.files[0])
                          },
                        },
                      }),
                      _vm._t("label"),
                    ],
                    2
                  ),
                  _vm._t("description"),
                ],
                2
              ),
              _c("div", { staticClass: "full-width relative" }, [
                errors[0]
                  ? _c(
                      "div",
                      { staticClass: "errors-container" },
                      _vm._l(errors, function (error, index) {
                        return _c("span", { key: index }, [
                          _c("strong", [_vm._v("Error: ")]),
                          _vm._v(_vm._s(error)),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
              ]),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }