var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("validation-provider", {
    attrs: {
      tag: "div",
      vid: _vm.vid || _vm.name,
      rules: _vm.rules,
      name: _vm.errorLabel || _vm.label || _vm.name,
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var errors = ref.errors
          return [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.innerValue,
                  expression: "innerValue",
                },
              ],
              attrs: {
                type: "radio",
                id: _vm.name,
                name: _vm.name,
                disabled: _vm.disabled,
              },
              domProps: {
                value: _vm.selectValue,
                checked: _vm._q(_vm.innerValue, _vm.selectValue),
              },
              on: {
                change: function ($event) {
                  _vm.innerValue = _vm.selectValue
                },
              },
            }),
            _c("label", { attrs: { for: _vm.name } }, [
              _c("span", [_vm._v(_vm._s(_vm.label || _vm.name))]),
            ]),
            errors[0]
              ? _c(
                  "div",
                  { staticClass: "errors-container" },
                  _vm._l(errors, function (error, index) {
                    return _c("span", { key: index }, [
                      _c("strong", [_vm._v("Error: ")]),
                      _vm._v(_vm._s(error)),
                    ])
                  }),
                  0
                )
              : _vm._e(),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }