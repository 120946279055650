import Timer from "@/components/interface/Timer.vue";
import UiTabs from "@/components/interface/UiTabs.vue";
import Loader from "@/components/interface/Loader.vue";
import Popover from "@/components/interface/Popover.vue";
import Dropdown from "@/components/interface/Dropdown.vue";
import CreateBtn from "@/components/interface/CreateBtn.vue";
import Pagination from "@/components/interface/pagination/Pagination.vue";
import PixellotTable from "@/components/interface/table/PixellotTable.vue";
import PixellotHeader from "@/components/interface/header/PixellotHeader.vue";
import BulkBottomBar from "@/components/interface/BulkBottomBar.vue";

export {
  Timer,
  Loader,
  UiTabs,
  Popover,
  Dropdown,
  CreateBtn,
  Pagination,
  PixellotTable,
  PixellotHeader,
  BulkBottomBar,
};
