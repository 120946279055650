import { extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import enLocale from "vee-validate/dist/locale/en.json";

const maxArrayLengthRule = {
  validate(items, params) {
    return items.length <= params.max;
  },
  params: [
    {
      name: "max",
      cast: function (value) {
        return Number(value);
      },
    },
  ],
};

const imageExt = {
  validate: (file, params) => params.ext.includes(file[0].name.split(".").pop()),
  params: [
    {
      name: "ext",
      cast: (value) => value,
    },
  ],
};

const validationsRules = { ...rules, ...{ maxItems: maxArrayLengthRule, imageExt } };

const messages = {
  ...enLocale.messages,
  size: "The File size must be less than {size}KB",
  imageExt: "Supported image types: {ext}",
  maxItems: "Number of {_field_} must be {max} or less",
};

Object.keys(validationsRules).forEach((rule) => {
  extend(rule, {
    ...validationsRules[rule], // copies rule configuration
    message: messages[rule], // assign message
  });
});
