var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "accordion-container full-width relative" },
    [
      _c(
        "div",
        { staticClass: "accordion-trigger", on: { click: _vm.toggle } },
        [
          _c("img", {
            staticClass: "accordion-indicator",
            class: { "open-dropdown": _vm.opened },
            attrs: {
              src: "assets/images/arrow.svg",
              width: "10",
              height: "10",
            },
          }),
          _c("div", { staticClass: "accordion-title" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
        ]
      ),
      _c(
        "transition",
        {
          attrs: { name: "accordion" },
          on: {
            enter: _vm.startTransition,
            "after-enter": _vm.endTransition,
            "before-leave": _vm.startTransition,
            "after-leave": _vm.endTransition,
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.opened,
                  expression: "opened",
                },
              ],
              staticClass: "accordion-details",
            },
            [
              _c(
                "div",
                { staticClass: "accordion-details-inner" },
                [_vm._t("default")],
                2
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }