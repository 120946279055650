<template lang="pug">
    thead
        tr
            slot(name="selection")
            template(v-for="(col, index) in cols")
                th(v-if="!col.hidden" :key="index" :class="col.class")
                    template(v-if="col.multiple")
                        .first-wrapper(v-if="col.first")
                            col-head(:data="col.first" :selected-options="selectedOptions" :isMultiplyFilter="col.isMultiplyFilter")
                        .second-wrapper(v-if="col.second")
                            col-head(:data="col.second" :selected-options="selectedOptions" :isMultiplyFilter="col.isMultiplyFilter")
                    template(v-else)
                        col-head(:data="col" :selected-options="selectedOptions" :isMultiplyFilter="col.isMultiplyFilter")
            slot(name="actions")
</template>

<script>
import ColHead from "./ColHead.vue";

export default {
  name: "TableHead",
  components: { ColHead },
  props: {
    cols: {
      type: Array,
      required: true,
    },
    selectedOptions: {
      type: Object,
      required: true,
    },
  },
};
</script>
