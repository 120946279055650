import { debounce } from "lodash";

export default {
  bind(el, binding) {
    const checkScroll = () => {
      const { scrollTop, offsetHeight, scrollHeight } = el;
      const scrollPercent = scrollTop / (offsetHeight - scrollHeight);

      if (scrollPercent < -0.8) {
        binding.value();
      }
    };

    el.addEventListener("scroll", debounce(checkScroll, 250, { maxWait: 1000 }));
  },
};
