import BaseAxios from "axios";
import Bugsnag from "@bugsnag/js";
import AuthApi from "../AuthApi";
import { setEventMetadata } from "@/plugins/bugsnag";
import router from "@/router";
import store from "@/store";
import VueRouter from "vue-router";

const { isNavigationFailure, NavigationFailureType } = VueRouter;

const CANCEL_TOKEN_MAP = {
  EMPTY_TOKEN: (token, url) => `[RequestCancelled] Got an empty. Token: ${token}. Url: ${url}`,
  GET_TOKEN_FAIL: (message, url) =>
    `[RequestCancelled] Failed to get token. Reason: ${message}. Url: ${url}`,
};

const logout = () => {
  store.dispatch("auth/logout", null, { root: true });

  // https://v3.router.vuejs.org/guide/advanced/navigation-failures.html#detecting-navigation-failures
  router.push({ name: "Login" }).catch((failure) => {
    if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
      console.log("Navigation duplicated during login redirect.");
    } else {
      console.log("Navigation failure occurred.", failure);
    }
  });
};

const axios = BaseAxios.create({
  baseURL: globalUrls.originPlaceHolder,
});

axios.interceptors.request.use(
  async (config) => {
    if (!config.skipAuth) {
      if (config.sharedToken) {
        config.headers["X-Shared-Authorization"] = `Bearer ${config.sharedToken}`;
      } else {
        try {
          const token = await AuthApi.getAuthToken();

          if (token) {
            config.headers["X-Authorization"] = `Bearer ${token}`;
          } else {
            config.cancelToken = new BaseAxios.CancelToken((cancel) =>
              cancel(CANCEL_TOKEN_MAP.EMPTY_TOKEN(token, config.url)),
            );
          }
        } catch (error) {
          config.cancelToken = new BaseAxios.CancelToken((cancel) =>
            cancel(CANCEL_TOKEN_MAP.GET_TOKEN_FAIL(error.message, config.url)),
          );
        }
      }
    }

    return config;
  },
  (err) => {
    return Promise.reject(err.data);
  },
);

axios.interceptors.response.use(
  function (config) {
    return config.data;
  },
  function (error) {
    if (BaseAxios.isCancel(error)) {
      Bugsnag.notify(new Error(error.message), (event) => {
        event.severity = "info";
      });

      logout();

      return Promise.reject(new Error("Unauthorized: Request cancelled."));
    } else if (error.response) {
      const requestConfig = {
        url: error.config.url,
        baseURL: error.config.baseURL,
        headers: error.config.headers,
        data: error.config.data,
      };
      const response = {
        data: error.response.data,
        headers: error.response.headers,
        statusCode: parseInt(error.response.status),
        statusText: error.response.statusText,
      };

      Bugsnag.notify(error, (event) => {
        event.addMetadata("request-metadata", { requestConfig, response });
        event.severity = getSeverityByStatusCode(requestConfig.statusCode);
        event.context = requestConfig.url;

        setEventMetadata(event);
      });

      if (response.statusCode === 401) {
        logout();

        return Promise.reject(new Error("Unauthorized. Please login again."));
      }
    } else {
      Bugsnag.notify(error, (event) => setEventMetadata(event));
    }

    return Promise.reject(error.response);
  },
);

function getSeverityByStatusCode(code) {
  const infoSeverityCodes = [404];

  return code >= 500 ? "error" : infoSeverityCodes.includes(code) ? "info" : "warning";
}

export default axios;
