<template lang="pug">
.main-container
    pixellot-sidebar

    widget-player(
      v-if="!hidePlayer",
      :event="activeEvent",
      :active-highlight="activeHighlight",
      @close="hideWidgetPlayer"
    )
    .main-content.container-fluid.col-md-10
        slot
</template>

<script>
import _ from "lodash";
import WidgetPlayer from "@/components/WidgetPlayer.vue";
import PixellotSidebar from "@/components/PixellotSidebar.vue";

export default {
  name: "App",
  components: { PixellotSidebar, WidgetPlayer },
  data() {
    return {
      hidePlayer: true,
      activeEvent: null,
      activeHighlight: null,
    };
  },
  created() {
    this.$store.dispatch("user/fetchUser");
    this.$root.$on("widget-player", this.handleWidgetPlayerEvent);
  },
  beforeDestroyed() {
    this.$root.$off("widget-player", this.handleWidgetPlayerEvent);
  },
  methods: {
    hideWidgetPlayer() {
      this.hidePlayer = true;
    },

    handleWidgetPlayerEvent(event = {}, highlight = null) {
      this.hidePlayer = true;
      this.$root.$emit("loading", true);

      setTimeout(() => {
        this.activeEvent = _.cloneDeep(event);
        this.activeHighlight = highlight;

        this.hidePlayer = false;
        this.$root.$emit("loading", false);
      }, 0);
    },
  },
};
</script>
