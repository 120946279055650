var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("thead", [
    _c(
      "tr",
      [
        _vm._t("selection"),
        _vm._l(_vm.cols, function (col, index) {
          return [
            !col.hidden
              ? _c(
                  "th",
                  { key: index, class: col.class },
                  [
                    col.multiple
                      ? [
                          col.first
                            ? _c(
                                "div",
                                { staticClass: "first-wrapper" },
                                [
                                  _c("col-head", {
                                    attrs: {
                                      data: col.first,
                                      "selected-options": _vm.selectedOptions,
                                      isMultiplyFilter: col.isMultiplyFilter,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          col.second
                            ? _c(
                                "div",
                                { staticClass: "second-wrapper" },
                                [
                                  _c("col-head", {
                                    attrs: {
                                      data: col.second,
                                      "selected-options": _vm.selectedOptions,
                                      isMultiplyFilter: col.isMultiplyFilter,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      : [
                          _c("col-head", {
                            attrs: {
                              data: col,
                              "selected-options": _vm.selectedOptions,
                              isMultiplyFilter: col.isMultiplyFilter,
                            },
                          }),
                        ],
                  ],
                  2
                )
              : _vm._e(),
          ]
        }),
        _vm._t("actions"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }