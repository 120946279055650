import eventsModals from "./events/components/modals";
import inventoryModals from "./inventory/components/modals";
import teamsModals from "./teams/components/modals";
import mediaModals from "./media/components/modals";
import usersModals from "./users/components/modals";
import clubsModals from "./clubs/components/modals";

const modals = {
  ...eventsModals,
  ...inventoryModals,
  ...teamsModals,
  ...mediaModals,
  ...usersModals,
  ...clubsModals,
};

export default modals;
