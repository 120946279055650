import axios from "./axios";
import { buildResponseArrayGetInterceptor, buildSearchURL } from "@/utils";

const baseUrl = "/api/v3/extensions";

export default {
  getExtensions(params) {
    return axios
      .get(`${baseUrl}${buildSearchURL(params)}`)
      .then((response) => buildResponseArrayGetInterceptor(response, params));
  },
  getExtension(extensionId) {
    return axios.get(`${baseUrl}/${extensionId}`).then((response) => response.data);
  },
  createExtension(extension) {
    return axios.post(`${baseUrl}`, extension);
  },
  updateExtension(extensionId, params) {
    return axios.patch(`${baseUrl}/${extensionId}`, params);
  },
  removeExtension(extensionId) {
    return axios.delete(`${baseUrl}/${extensionId}`);
  },
  getExtensionsParams(params) {
    return axios
      .get(`${baseUrl}/parameters-list${buildSearchURL(params)}`)
      .then((response) => response.data);
  },
};
