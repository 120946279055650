<template lang="pug">
    validation-provider(
        tag="div"
        :vid="vid || name"
        :rules="rules"
        :custom-messages="customMessages"
        :name="errorLabel || label || name"
        class="pxl-input full-width relative"
        v-slot="{ errors }"
    )
        input(
            ref="input"
            class="form-control"
            :id="name"
            :type="type"
            :value="value"
            :disabled="disabled"
            :placeholder="placeholder"
            :class="{ 'ng-invalid ng-dirty': errors[0], 'ng-touched ng-valid has-value': hasValue, 'ng-untouched has-value': placeholder }"
            @input="updateValue($event.target.value)"
            :autocomplete="autocomplete"
        )
        label(:for="name" @click="focusInput")
            span {{ label || name }}
        .errors-container(v-if="errors[0]")
            span(v-for="(error, index) in errors" :key="index")
                strong Error:&nbsp;
                | {{ error }}
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  name: "TextInput",
  components: { ValidationProvider },
  props: {
    vid: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    errorLabel: {
      type: String,
      default: "",
    },
    customMessages: {
      type: Object,
      default: () => ({}),
    },
    rules: {
      type: [Object, String],
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
      validator: (value) =>
        ["url", "text", "password", "tel", "search", "number", "email"].includes(value),
    },
    value: {
      type: [String, Number],
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: "on",
    },
  },
  computed: {
    hasValue() {
      return Boolean(this.value) || (this.type === "number" && this.value === 0);
    },
  },
  methods: {
    focusInput() {
      this.$refs.input && this.$refs.input.focus();
    },
    updateValue(value) {
      this.$emit("input", value);
    },
  },
};
</script>
