var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationProvider", {
    ref: "validator",
    staticClass: "image-upload-component",
    attrs: {
      tag: "div",
      vid: _vm.vid || _vm.name,
      name: _vm.errorLabel || _vm.label || _vm.name,
      rules: _vm.rules,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function (ref) {
            var errors = ref.errors
            var reset = ref.reset
            return [
              _c("div", { staticClass: "upload-component relative" }, [
                _c(
                  "div",
                  _vm._g(
                    {
                      staticClass: "upload-wrapper",
                      class: { banner: _vm.isBannerUpload },
                      on: {
                        click: function ($event) {
                          !_vm.image && _vm.changeImage()
                        },
                      },
                    },
                    _vm.uploadWrapperListeners
                  ),
                  [
                    _vm.label || _vm.name
                      ? _c("div", { staticClass: "img-label text-center" }, [
                          _vm._v(_vm._s(_vm.label || _vm.name)),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "upload-file",
                        class: {
                          dragover: _vm.dragover.accept,
                          "dragover-err": _vm.dragover.reject,
                        },
                        attrs: { disabled: _vm.disabled },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: false,
                              expression: "false",
                            },
                          ],
                          ref: "fileInput",
                          attrs: {
                            id: _vm.name,
                            type: "file",
                            accept: ".jpg, .jpeg, .png",
                            disabled: _vm.disabled,
                          },
                          on: {
                            change: function ($event) {
                              $event.stopPropagation()
                              return _vm.onFileChange($event.target.files[0])
                            },
                          },
                        }),
                        _c(
                          "i",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.image,
                                expression: "!image",
                              },
                            ],
                            staticClass: "fa fa-download",
                          },
                          [_vm._v("Select or drag file here")]
                        ),
                        _c("img", {
                          class: { invisible: !_vm.image },
                          attrs: { src: _vm.image },
                        }),
                        _c("div", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.value,
                              expression: "value",
                            },
                          ],
                          staticClass: "opacity-wrapper",
                          class: { hidden: _vm.disabled },
                        }),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.value,
                                expression: "value",
                              },
                            ],
                            staticClass: "buttons-wrapper",
                            class: {
                              hidden: _vm.disabled,
                              center: !_vm.isDeleteAllowed,
                            },
                          },
                          [
                            _vm.isDeleteAllowed
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "pxl-button btn btn-danger remove",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.removeImage(reset)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-trash-o",
                                      attrs: { "aria-hidden": "true" },
                                    }),
                                    _vm._v("Remove"),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-success change pxl-button",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.changeImage.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-exchange",
                                  attrs: { "aria-hidden": "true" },
                                }),
                                _vm._v("Change"),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
              _vm._t("hint"),
              _c("div", { staticClass: "selected-file-wrapper" }, [
                _vm.value && _vm.value.name && _vm.value.size
                  ? _c("div", { staticClass: "selected-file" }, [
                      _vm._v("Selected file:"),
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.value.name + "(" + _vm.convertedSize + "MB)"
                            )
                        ),
                      ]),
                    ])
                  : _vm._e(),
                errors[0]
                  ? _c("div", { staticClass: "errors-wrapper" }, [
                      _c("span", [
                        _c("strong", [_vm._v("Error:  ")]),
                        _vm._v(_vm._s(errors[0])),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }