import Vue from "vue";
import Toast from "./Toaster.vue";

const toastService = () => {
  let instance = null;
  const serviceFactory = Vue.extend(Toast);
  const createInstance = () => {
    // eslint-disable-next-line new-cap
    instance = new serviceFactory({
      el: document.createElement("div"),
    });

    document.body.appendChild(instance.$el);
  };

  return {
    show(options, type) {
      if (!instance) {
        createInstance();
      }

      if (typeof options === "string") {
        instance.show({ message: options, type });
      } else {
        instance.show({ ...options, type });
      }
    },

    closeAll() {
      this.instance.closeAll();
    },

    info(options) {
      this.show(options, "info");
    },

    success(options) {
      this.show(options, "success");
    },

    error(options) {
      this.show(options, "error");
    },
  };
};

// eslint-disable-next-line new-cap
export default new toastService();
