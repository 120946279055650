var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "color-picker" },
    [
      _c(
        "verte",
        {
          attrs: { value: _vm.value, model: "hex" },
          on: {
            input: function ($event) {
              return _vm.$emit("input", $event)
            },
          },
        },
        [
          _c("svg", { attrs: { viewBox: "0 0 24 24" } }, [
            _c("rect", {
              attrs: {
                x: "0",
                y: "0",
                width: "24",
                height: "24",
                d: "M0 20h24v4H0z",
              },
            }),
          ]),
        ]
      ),
      _c("input", {
        attrs: { type: "text", disabled: "true" },
        domProps: { value: _vm.value },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }