<template lang="pug">
    .widget-wrapper(:id="(activeHighlightId || event.id) + '-wrapper'")
        .widget-box(:id="event.id + '-box'" style="margin: auto;" ref="playerBox")
            .player-move(data-action="player-move")
                span(v-if="event.id && !activeHighlightId")
                    | {{event.attributes.name}}, {{event.attributes.system.name}}
                span(v-if="event.id && activeHighlightId")
                    | {{event.attributes.name}}, {{activeHighlight && activeHighlight.name}}
            .player-close
                i.fa.fa-times(aria-hidden="true" @click="closePlayer")
            div
              pixellot-player(:source="streamData" :event="event" :config="playerConfig")
</template>

<script>
import { PixellotPlayer } from "@/components/shared";
const isMobile = {
  Android: () => navigator.userAgent.match(/Android/i),
  BlackBerry: () => navigator.userAgent.match(/BlackBerry/i),
  iOS: () => navigator.userAgent.match(/iPhone|iPad|iPod/i),
  Opera: () => navigator.userAgent.match(/Opera Mini/i),
  Windows: () => navigator.userAgent.match(/IEMobile/i),
  any: function () {
    return this.Android() || this.BlackBerry() || this.iOS() || this.Opera() || this.Windows();
  },
};
const mousemove = isMobile.any() ? "touchmove" : "mousemove";
const mouseup = isMobile.any() ? "touchend" : "mouseup";
const playerConstants = {
  STREAM_NAME: {
    HD: "hd",
    PANO: "pano",
  },
  playerDefaultVolume: 0.5,
};

export default {
  name: "WidgetPlayer",
  components: { PixellotPlayer },
  props: {
    event: {
      type: Object,
      required: true,
    },
    activeHighlight: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      player: null,
      playerBoxStartPosition: null,
      playerCursorStartPosition: null,
    };
  },
  computed: {
    activeHighlightId() {
      return this.activeHighlight && this.activeHighlight.id;
    },
    eventTitle() {
      if (this.event.attributes?.gameInfo) {
        const { team1, team2, name } = this.event.attributes.gameInfo;

        return team1 ? (team2 ? `${team1} vs. ${team2}` : team1) : name;
      }
    },
    playerConfig() {
      const isEventSource = typeof this.streamData === "object";

      return {
        title: this.eventTitle,
        volume: playerConstants.playerDefaultVolume,
        autoplay: true,
        playbackType: isEventSource ? "event" : "highlight",
        showTags: isEventSource,
        enableAnalytics: true,
      };
    },
    streamData() {
      const { urls } = this.event.attributes;

      const hdUrl = this.getStreamUrl(playerConstants.STREAM_NAME.HD, urls);
      const panoUrl = this.getStreamUrl(playerConstants.STREAM_NAME.PANO, urls);

      if (this.activeHighlight) {
        return this.activeHighlight.url;
      }

      return {
        hd: hdUrl,
        pano: panoUrl,
      };
    },
  },
  mounted() {
    this.$nextTick(() => {
      const mousedown = isMobile.any() ? "touchstart" : "mousedown";
      this.$refs.playerBox.addEventListener(mousedown, this.onPlayerBoxMouseDown.bind(this));
    });
  },
  beforeDestroy() {
    this.closePlayer();
  },
  methods: {
    getStreamUrl(streamName, urls) {
      return urls[streamName] || urls[`${streamName}MP4`];
    },
    onPlayerBoxMouseDown($event) {
      const el = $event.target;
      const action = el.getAttribute("data-action");

      switch (action) {
        case "player-move":
          this.onPlayerMoveStart($event);
          break;
        case "player-detach-to-new-window":
          break;
        default:
          break;
      }
    },
    onPlayerMoveStart($event) {
      const playerBoxBoundingRectangle = this.$refs.playerBox.getBoundingClientRect();
      const { clientX, clientY } = $event;

      this.playerCursorStartPosition = [clientX, clientY];
      this.playerBoxStartPosition = [
        playerBoxBoundingRectangle.left,
        playerBoxBoundingRectangle.top,
      ];

      if (this.$refs.playerBox.style.margin === "auto") {
        this.$refs.playerBox.style.left = `${this.$refs.playerBox.offsetLeft}px`;
        this.$refs.playerBox.style.top = `${this.$refs.playerBox.offsetTop}px`;
        this.$refs.playerBox.style.position = "absolute";
        this.$refs.playerBox.style.margin = "";
      }

      document.addEventListener(mousemove, this.onPlayerMove);
      document.addEventListener(mouseup, this.onPlayerMoveEnd);

      $event.preventDefault();
    },
    onPlayerMove($event) {
      const playerBoxBoundingRectangle = this.$refs.playerBox.getBoundingClientRect(),
        clientX = $event.clientX || $event.clientX,
        clientY = $event.clientY || $event.clientY,
        playerShifts = [
          clientX - this.playerCursorStartPosition[0],
          clientY - this.playerCursorStartPosition[1],
        ],
        playerSize = [playerBoxBoundingRectangle.width, playerBoxBoundingRectangle.height],
        windowSize = [window.innerWidth, window.innerHeight],
        playerNewPosition = [
          this.playerBoxStartPosition[0] + playerShifts[0],
          this.playerBoxStartPosition[1] + playerShifts[1],
        ];

      this.$refs.playerBox.style.left = `${Math.min(
        Math.max(0, playerNewPosition[0]),
        windowSize[0] - playerSize[0],
      )}px`;
      this.$refs.playerBox.style.top = `${Math.min(
        Math.max(0, playerNewPosition[1]),
        windowSize[1] - playerSize[1],
      )}px`;

      $event.preventDefault();
    },
    onPlayerMoveEnd($event) {
      this.playerCursorStartPosition = null;
      this.playerBoxStartPosition = null;

      document.removeEventListener(mousemove, this.onPlayerMove);
      document.removeEventListener(mouseup, this.onPlayerMoveEnd);

      $event.preventDefault();
    },
    closePlayer() {
      if (this.player) {
        this.player.destroy();
      }

      this.$emit("close", true);
    },
  },
};
</script>

<style lang="stylus">
.widget-wrapper
    position fixed
    height 100%
    width 100%
    top 0
    left 0
    display block
    z-index 1001
    pointer-events none

    .widget-box
        position relative
        height auto
        width 730px
        margin auto
        padding 10px
        box-sizing border-box
        background-color #1E1E1E
        overflow hidden
        border-radius 5px
        pointer-events auto

        .player-move
            width 92%
            top 10px
            left 10px
            cursor move
            color #fff
            font-size 15px
            margin auto
            padding-bottom 5px
            white-space normal
            text-align center
            min-height 24px

        .player-close
            position absolute
            height 20px
            width 20px
            top 10px
            right 10px
            cursor pointer

            .fa.fa-times
                font-size 20px
                color #fff

        .player-detach
            display none
            position absolute
            height 20px
            width 20px
            top 5px
            right 30px
            cursor pointer

.hidden
    display none

.urls-error
    position absolute
    top 50%
    left 0
    right 0
    font-size 25px
    text-align center

.aspect-ratio-box
    height 0
    overflow hidden
    padding-top 56.25%
    background white
    position relative

    .aspect-ratio-box-inside
        position: absolute
        top 0
        left 0
        width 100%
        height 100%
</style>
