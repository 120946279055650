var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("validation-provider", {
    attrs: {
      tag: "div",
      vid: _vm.vid || _vm.name,
      name: _vm.errorLabel || _vm.label || _vm.name,
      rules: _vm.rules,
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var errors = ref.errors
          return [
            _c("vue-datepicker", {
              staticClass: "pixellot-inline-date-picker",
              attrs: {
                disabled: _vm.disabled,
                type: _vm.type,
                format: _vm.format,
                value: _vm.value,
                showSecond: _vm.showSecond,
                "disabled-date": _vm.disabledDate,
                "disabled-time": _vm.disabledTime,
              },
              on: { input: _vm.onChange },
              scopedSlots: _vm._u(
                [
                  {
                    key: "input",
                    fn: function () {
                      return [
                        _c("input", {
                          ref: "input",
                          staticClass: "form-control",
                          class: {
                            "ng-invalid ng-dirty": errors[0],
                            "ng-touched ng-valid has-value": _vm.hasValue,
                          },
                          attrs: {
                            id: _vm.name,
                            type: "text",
                            disabled: _vm.disabled,
                            autocomplete: _vm.autocomplete,
                          },
                          domProps: { value: _vm.formatTime },
                        }),
                        _c(
                          "label",
                          {
                            attrs: { for: _vm.name },
                            on: { click: _vm.focusInput },
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.label || _vm.name))])]
                        ),
                        errors[0]
                          ? _c(
                              "div",
                              { staticClass: "errors-container" },
                              _vm._l(errors, function (error, index) {
                                return _c("span", { key: index }, [
                                  _c("strong", [_vm._v("Error: ")]),
                                  _vm._v(_vm._s(error)),
                                ])
                              }),
                              0
                            )
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }