/* global google */

const preventEnter = (event) => {
  if (event.keyCode === 13) {
    event.preventDefault();
    event.stopPropagation();
  }
};

const googleAutocompleteOptions = {
  types: ["(regions)"],
};
const googlePlaceFields = [
  "address_components",
  "formatted_address",
  "name",
  "place_id",
  "types",
  "geometry",
];
const zipcodeRegex = /^([0-9]{5})(-[0-9]{4})?$/i;

export default class LocationApi {
  constructor(inputEl, onInputCallback = null) {
    this.place = null;
    this.predictions = [];
    this.onInputCallback = onInputCallback;
    this.initAutocomplete(inputEl);
    this.initGoogleServices();
  }

  static isGooglePlacesAPIAvailable() {
    return Boolean(window.google?.maps?.places);
  }

  initAutocomplete(inputEl) {
    this.autocomplete = new google.maps.places.Autocomplete(inputEl, googleAutocompleteOptions);
    this.autocomplete.addListener("place_changed", this.onPlaceChange.bind(this));
    this.autocomplete.setFields(googlePlaceFields);
    google.maps.event.addDomListener(inputEl, "keydown", preventEnter);
  }

  initGoogleServices() {
    this.autocompleteService = new google.maps.places.AutocompleteService();
    this.placesService = new google.maps.places.PlacesService(document.createElement("div"));
  }

  onPlaceChange(place = null) {
    this.place = place || this.autocomplete.getPlace();

    this.onInputCallback && this.onInputCallback(this.place);
  }

  getLocationPredictions(input, autoSelectPostalCodePlace = false) {
    function setPredictions(predictions, status) {
      // eslint-disable-next-line eqeqeq
      if (status != google.maps.places.PlacesServiceStatus.OK) {
        return;
      }
      const isValidZip = zipcodeRegex.test(input);

      this.predictions = predictions;

      if (autoSelectPostalCodePlace && isValidZip) {
        const place = this.predictions[0];
        const options = { placeId: place.place_id, fields: googlePlaceFields };

        this.placesService.getDetails(options, this.onPlaceChange.bind(this));
      }
    }

    if (input) {
      this.autocompleteService.getPlacePredictions(
        { ...googleAutocompleteOptions, input },
        setPredictions.bind(this),
      );
    }
  }

  getPlaceLocationType(type, name) {
    if (type === "location") {
      return this.place && this.place.formatted_address;
    }

    const components = (this.place && this.place.address_components) || [];
    const placeType = components.find((addr) => addr.types.includes(type));

    return placeType && placeType[name || "long_name"];
  }

  getPlaceLocationTypes(types = []) {
    return types.reduce((acc, type) => {
      acc[type] = this.getPlaceLocationType(type);

      return acc;
    }, {});
  }
}
