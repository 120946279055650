const state = () => {
  return {
    previousRoute: null,
  };
};

const getters = {};

const actions = {};

const mutations = {
  setPreviousRoute(state, route) {
    state.previousRoute = route;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
