var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "validation-provider",
    {
      attrs: {
        tag: "div",
        vid: _vm.vid || _vm.name,
        name: _vm.errorLabel || _vm.name,
        rules: _vm.rules,
      },
    },
    [
      _c("input", {
        staticClass: "cmn-toggle cmn-toggle-round",
        attrs: { id: _vm.name, type: "checkbox", disabled: _vm.disabled },
        domProps: { checked: _vm.value, value: _vm.value },
        on: {
          input: function ($event) {
            return _vm.$emit("input", $event.target.checked)
          },
        },
      }),
      _c("label", { attrs: { for: _vm.name } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }