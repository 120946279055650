var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "widget-wrapper",
      attrs: { id: (_vm.activeHighlightId || _vm.event.id) + "-wrapper" },
    },
    [
      _c(
        "div",
        {
          ref: "playerBox",
          staticClass: "widget-box",
          staticStyle: { margin: "auto" },
          attrs: { id: _vm.event.id + "-box" },
        },
        [
          _c(
            "div",
            {
              staticClass: "player-move",
              attrs: { "data-action": "player-move" },
            },
            [
              _vm.event.id && !_vm.activeHighlightId
                ? _c("span", [
                    _vm._v(
                      _vm._s(_vm.event.attributes.name) +
                        ", " +
                        _vm._s(_vm.event.attributes.system.name)
                    ),
                  ])
                : _vm._e(),
              _vm.event.id && _vm.activeHighlightId
                ? _c("span", [
                    _vm._v(
                      _vm._s(_vm.event.attributes.name) +
                        ", " +
                        _vm._s(_vm.activeHighlight && _vm.activeHighlight.name)
                    ),
                  ])
                : _vm._e(),
            ]
          ),
          _c("div", { staticClass: "player-close" }, [
            _c("i", {
              staticClass: "fa fa-times",
              attrs: { "aria-hidden": "true" },
              on: { click: _vm.closePlayer },
            }),
          ]),
          _c(
            "div",
            [
              _c("pixellot-player", {
                attrs: {
                  source: _vm.streamData,
                  event: _vm.event,
                  config: _vm.playerConfig,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }