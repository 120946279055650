import axios from "./axios";
import store from "@/store";
import { categories, stateRefs, root } from "@/constants";
import { buildResponseArrayGetInterceptor, buildSearchURL } from "@/utils";

const baseUrl = "/api/v3/categories";

export default {
  getCategories(params) {
    const activeTenant = store.state.user.activeTenant;

    if (activeTenant && !params.tenant) {
      params.tenant = activeTenant;
    }

    return axios
      .get(`${baseUrl}${buildSearchURL(params)}`)
      .then((response) => buildResponseArrayGetInterceptor(response, params));
  },
  createCategory(category) {
    return axios.post(`${baseUrl}`, category);
  },
  getCategory(id) {
    return axios.get(`${baseUrl}/${id}`).then((response) => response.data);
  },
  updateCategory(id, params) {
    return axios.patch(`${baseUrl}/${id}`, params);
  },
  getSubCategories(mainId, params) {
    return axios
      .get(`${baseUrl}/${mainId}/sub${buildSearchURL(params)}`)
      .then((response) => response);
  },
  updateCategoryOrder(params) {
    return axios.patch(`${baseUrl}/change-order`, params);
  },
  bulkUpdateCategories(params) {
    return axios.patch(`${baseUrl}/bulk`, params);
  },
  get filterOptions() {
    return categories[stateRefs.category].filterOptions;
  },
  get permissions() {
    return categories[stateRefs.category].permissions;
  },
  get typeOptions() {
    return categories[stateRefs.category].typeOptions;
  },
  get socialMediasLinks() {
    return root.SOCIAL_MEDIA_LINK_TYPES;
  },
  get generalUrlRegex() {
    return root.GENERAL_URL_REGEX;
  },
};
