<template lang="pug">
    validation-provider(
        tag="div"
        :vid="vid || name"
        :name="errorLabel || name"
        :rules="rules"
    )
        input.cmn-toggle.cmn-toggle-round(
            :id="name"
            type="checkbox"
            :checked="value"
            :disabled="disabled"
            :value="value"
            @input="$emit('input', $event.target.checked)"
        )
        label(:for="name")
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  name: "Toggle",
  components: { ValidationProvider },
  props: {
    vid: {
      type: String,
      default: undefined,
    },
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    errorLabel: {
      type: String,
      default: "",
    },
    rules: {
      type: [Object, String],
      default: "",
    },
  },
  methods: {},
};
</script>

<style lang="stylus">
.cmn-toggle
    position absolute
    margin-left -9999px
    visibility hidden
    & + label
        position relative
        cursor pointer
        outline none
        user-select none
        margin-bottom 0

input
    &.cmn-toggle-round
        & + label
            padding 2px
            width 54px
            height 24px
            background-color toggle-border
            border-radius 60px
            &:before
                right 1px
                background-color toggle-disable-background
                border-radius 60px
                transition background 0.4s
            &:after
                width 23px
                height 22px
                background-color toggle-round-button
                border-radius 100%
                box-shadow 0 2px 5px rgba(0, 0, 0, 0.3)
                transition margin 0.4s
        &:checked
            & + label
                &:before
                    background-color toggle-enable-background
                &:after
                    margin-left 29px

input.cmn-toggle-round + label:before,
input.cmn-toggle-round + label:after
    display block
    position absolute
    top 1px
    left 1px
    bottom 1px
    content ""
</style>
