<template lang="pug">
    .accordion-container.full-width.relative
        .accordion-trigger(@click="toggle")
            img.accordion-indicator(:class="{ 'open-dropdown': opened }" src="assets/images/arrow.svg" width="10" height="10")
            .accordion-title {{title}}
        transition(
            name="accordion"
            @enter="startTransition"
            @after-enter="endTransition"
            @before-leave="startTransition"
            @after-leave="endTransition"
        )
            .accordion-details(v-show="opened")
                .accordion-details-inner
                    slot
</template>

<script>
export default {
  name: "Accordion",
  props: {
    title: {
      type: String,
      default: "",
    },
    value: {
      type: Boolean,
      default: false,
    },
    isOpened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      opened: false,
    };
  },
  watch: {
    value(value) {
      this.opened = value;
    },
  },
  created() {
    this.opened = this.value || this.isOpened;
  },
  methods: {
    toggle() {
      this.opened = !this.opened;
      this.$emit("input", this.opened);
    },
    startTransition(el) {
      el.style.height = `${el.scrollHeight}px`;
      el.style.overflow = "hidden";
    },
    endTransition(el) {
      el.style.height = "";
      el.style.overflow = "visible";
    },
  },
};
</script>

<style lang="stylus">
.accordion-container
    margin-bottom 15px
    .accordion-trigger
        display flex
        align-items center
        height 36px
        text-align left
        cursor pointer
        position relative
        background-color #f8f8f8
        border 1px solid #ccc
        padding-left 10px

    .accordion-details
        border 1px solid #ccc
        border-top none

    .accordion-details-inner
        padding 10px

    .accordion-enter-active, .accordion-leave-active
        will-change height
        transition height .2s ease

    .accordion-enter, .accordion-leave-to
        height 0!important

    .accordion-indicator
        float right
        vertical-align middle
        margin-right 6px
        transition transform .1s ease-in-out
        transform rotate(-90deg)
        &.open-dropdown
            transform rotate(0deg)
</style>
