export default [
  {
    name: "Login",
    path: "/login",
    meta: { title: "Login" },
    component: (resolve) => require(["@auth/pages/login.vue"], resolve),
  },
  {
    name: "Invite",
    path: "/invite",
    meta: { title: "Invite" },
    component: (resolve) => require(["@auth/pages/invite.vue"], resolve),
  },
  {
    name: "Forgot",
    path: "/login/forgot",
    meta: { title: "Forgot password" },
    component: (resolve) => require(["@auth/pages/forgot.vue"], resolve),
  },
  {
    name: "Reset",
    path: "/login/reset",
    meta: { title: "Reset password" },
    component: (resolve) => require(["@auth/pages/reset.vue"], resolve),
  },
  {
    name: "Confirm",
    path: "/login/confirm",
    meta: { title: "Confirm reset password" },
    component: (resolve) => require(["@auth/pages/confirm.vue"], resolve),
  },
  {
    name: "AcceptInvite",
    path: "/accept-invite",
    meta: { title: "Accept Invite" },
    component: (resolve) => require(["@auth/pages/accept-invite.vue"], resolve),
  },
  {
    name: "ApproveEmail",
    path: "/email-approve",
    meta: { title: "Approve Email" },
    component: (resolve) => require(["@auth/pages/approve-email.vue"], resolve),
  },
];
