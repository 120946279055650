<template lang="pug">
    vue-datepicker(
        :disabled="disabled"
        type="date"
        :format="format"
        :value="value"
        :disabled-date="disabledDates"
        :range="true"
        class="pixellot-date-range-picker"
        popup-class="pixellot-date-range-picker-popup"
        :shortcuts="shortcuts[mode]"
        :confirm="true"
        confirm-text="OK"
        @input="onChange"
        @close="() => isActive = false"
    )
        template(v-slot:icon-calendar)
            div
        template(v-slot:input)
            .date-range-wrapper(
                :class="{ 'open-input': isOpenDatePickerInput, 'header-button-block-wrapper': !isOpenDatePickerInput }"
                @click.prevent="() => isActive = true"
                :disabled="disabled"
            )
                label.glyphicon.glyphicon-calendar#calendar(for="input-picker" :class="{ 'change-color': isOpenDatePickerInput }")
                input.input-picker#input-picker(
                    type="text"
                    :value="formattedDates"
                    placeholder="Choose Date Range"
                    :autocomplete="autocomplete"
                    :disabled="true"
                )
</template>

<script>
import moment from "moment";
import vueDatepicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "DateRangePicker",
  components: { vueDatepicker },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: "off",
    },
    format: {
      type: String,
      default: "",
    },
    mode: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isActive: false,
      shortcuts: {
        future: [
          {
            text: "Today",
            onClick: () => [moment().toDate(), moment().toDate()],
          },
          {
            text: "Tomorrow",
            onClick: () => [moment().add(1, "days").toDate(), moment().add(1, "days").toDate()],
          },
          {
            text: "Next 7 days",
            onClick: () => [moment().toDate(), moment().add(7, "days").toDate()],
          },
          {
            text: "Next 30 days",
            onClick: () => [moment().toDate(), moment().add(30, "days").toDate()],
          },
          {
            text: "Next 90 days",
            onClick: () => [moment().toDate(), moment().add(90, "days").toDate()],
          },
          {
            text: "All",
            onClick: () => [],
          },
        ],
        past: [
          {
            text: "Today",
            onClick: () => [moment().toDate(), moment().toDate()],
          },
          {
            text: "Yesterday",
            onClick: () => [
              moment().subtract(1, "days").toDate(),
              moment().subtract(1, "days").toDate(),
            ],
          },
          {
            text: "Last 7 days",
            onClick: () => [moment().subtract(7, "days").toDate(), moment().toDate()],
          },
          {
            text: "Last 30 days",
            onClick: () => [moment().subtract(30, "days").toDate(), moment().toDate()],
          },
          {
            text: "Last 90 days",
            onClick: () => [moment().subtract(90, "days").toDate(), moment().toDate()],
          },
          {
            text: "All",
            onClick: () => [],
          },
        ],
      },
    };
  },
  computed: {
    hasDatePickerDates() {
      return this.value.length && this.value.every((item) => item);
    },

    isOpenDatePickerInput() {
      return this.hasDatePickerDates || this.isActive;
    },

    formattedDates() {
      return this.hasDatePickerDates
        ? this.value.map((item) => moment(item).format(this.format)).join(" - ")
        : "";
    },
  },
  methods: {
    onChange(value) {
      this.$emit("input", value);
    },
    disabledDates(date) {
      switch (this.mode) {
        case "future":
          return date < moment().startOf("day").toDate();
        case "past":
          return date > moment().endOf("day").toDate();
        default:
          return false;
      }
    },
  },
};
</script>

<style lang="stylus">
.mx-table-date th
    text-align center

.mx-calendar-content .cell.active
    background-color button-background-color

.mx-datepicker-btn-confirm
    width 70px
    background-color button-background-color
    color white-color
    font-size 14px
    border-radius 60px
    height 32px
    outline none
    border none

    &:hover
        color white-color

.pixellot-date-range-picker
    width auto
    padding-right 10px

    .date-range-wrapper
        display inline-block
        position relative
        margin 0
        &[disabled]
            pointer-events none
            .glyphicon-calendar
                opacity .55

        &.header-button-block-wrapper
            padding 10px 0

        &.open-input
            .input-picker
                width 180px
                height 42px
                border 1px solid #93a8ba
                background white-color

        .input-picker
            width 37px
            height 22px
            border none
            background side-menu-background
            outline none
            padding 5px 0 5px 36px
            border-radius 3px
            transition width .5s
            color text-color

        @supports (-moz-appearance:none)
            .input-picker
                padding 5px 0 0 36px

        .glyphicon-calendar
            position absolute
            font-size 16px
            vertical-align middle
            cursor pointer
            color white-color
            height inherit
            width 100%
            display flex
            justify-content: center
            align-items center

            &.change-color
                color text-color
                left 7px
                top 50%
                transform translateY(-50%)
                display block

        @media (max-width 1320px)
            .date-range-wrapper
                .input-picker
                    &.open-input
                        width 190px
                        font-size 11px
</style>
