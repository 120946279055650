import store from "@/store";

export default [
  {
    name: "Users",
    path: "/users",
    meta: {
      title: "Users",
      requiresAuth: true,
      accessRoles: ["superadmin", "admin"],
    },
    component: (resolve) => require(["@users/pages/index.vue"], resolve),
    beforeEnter: (to, from, next) => {
      const role = store.getters["user/role"];

      if (!to.query.joinStatus && role === "admin") {
        to.query.joinStatus = "joined";
        next(to);
      } else {
        next();
      }
    },
  },
  {
    name: "UsersCreate",
    path: "/users/create",
    meta: { title: "Create user", requiresAuth: true, group: "Users" },
    component: (resolve) => require(["@users/pages/create.vue"], resolve),
  },
  {
    name: "UsersEdit",
    path: "/users/:id/edit",
    meta: { title: "Edit user", requiresAuth: true, group: "Users" },
    component: (resolve) => require(["@users/pages/_id/edit.vue"], resolve),
  },
];
