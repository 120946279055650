export default [
  {
    name: "Accounts",
    path: "/accounts",
    meta: { title: "Accounts", group: "Accounts", requiresAuth: true, accessRoles: ["superadmin"] },
    component: (resolve) => require(["@accounts/pages/index.vue"], resolve),
  },
  {
    name: "AccountsEdit",
    path: "/accounts/:id/edit",
    meta: {
      title: "Edit account",
      group: "Accounts",
      requiresAuth: true,
      accessRoles: ["superadmin"],
    },
    component: (resolve) => require(["@accounts/pages/_id/edit.vue"], resolve),
  },
];
