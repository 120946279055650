import axios from "./axios";
import store from "@/store";
import { buildResponseArrayGetInterceptor, buildSearchURL } from "@/utils";

const baseUrl = "/api/v3/tenants";

export default {
  getTenants(params = { limit: 10, offset: 0 }) {
    const isAccountAdmin = store.getters["user/isAccountAdmin"];

    if (isAccountAdmin) {
      const activeTenant = store.state.user.activeTenant;

      return Promise.resolve({ items: [{ name: activeTenant }] });
    }

    return axios
      .get(`${baseUrl}${buildSearchURL(params)}`)
      .then((response) => buildResponseArrayGetInterceptor(response, params));
  },
  getTenant(name) {
    return axios.get(`${baseUrl}/${name}`).then((response) => response.data);
  },
  getTenantSettings(name) {
    return axios.get(`${baseUrl}/${name}/settings`).then((response) => response.data);
  },
  getSettings() {
    return axios.get(`${baseUrl}/settings`).then((response) => response.data);
  },
  updateTenantSettings(name, data) {
    return axios.patch(`${baseUrl}/${name}/settings`, data).then((response) => response.data);
  },
  getApprovedVASProvidersForTenant(name) {
    return axios.get(`${baseUrl}/${name}/vas/providers`).then((response) => response.data);
  },
  getVASProviders() {
    return axios.get(`${baseUrl}/vas/providers`).then((response) => response.data);
  },
  addAccountVAS(tenantName, vas) {
    return axios.post(`${baseUrl}/${tenantName}/vas`, { vas });
  },
  removeAccountVAS(tenantName, vasName) {
    return axios.delete(`${baseUrl}/${tenantName}/vas/${vasName}`);
  },
  getPlugins() {
    return axios.get(`${baseUrl}/plugins`).then((response) => response.data);
  },
  getApprovedPluginsForTenant(name) {
    return axios.get(`${baseUrl}/${name}/plugins`).then((response) => response.data);
  },
  addAccountPlugin(tenantName, plugin) {
    return axios.post(`${baseUrl}/${tenantName}/plugin`, { plugin });
  },
  removeAccountPlugin(tenantName, pluginName) {
    return axios.delete(`${baseUrl}/${tenantName}/plugin/${pluginName}`);
  },
  getApprovedExtensionsForTenant(name) {
    return axios.get(`${baseUrl}/${name}/extensions`).then((response) => response.data);
  },
  addAccountExtension(tenantName, extensionId) {
    return axios.post(`${baseUrl}/${tenantName}/extension`, { extensionId });
  },
  removeAccountExtension(tenantName, extensionId) {
    return axios.delete(`${baseUrl}/${tenantName}/extension/${extensionId}`);
  },
};
