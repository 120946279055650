/* globals analytics */
import * as appConstants from "@/constants";

const { BUTTON, PAGINATION, EXPANDED_SECTION, STREAM_ACTION } =
  appConstants.analytics.analytics.CATEGORY;

const { CLICK, OPEN } = appConstants.analytics.analytics.ACTION;

const { VPU } = appConstants.analytics.analytics.LABEL;

const {
  OPEN_VPU,
  PAGINATION_CLICK,
  EXPANDED_SECTION_OPEN,
  STREAM_ACTION_CLICK,
  EXTERNAL_CONTENT_CREATE,
  ADDED_BREAKDOWN,
  ADDED_STREAM_TARGET,
  ADDED_SET_OF_MAIN_CATEGORY,
} = appConstants.analytics.analytics.EVENT;

const { FILTER_BY_LIFECYCLE_STATUSES, SYSTEM_DEACTIVATE_STATUS_CLICK } =
  appConstants.analytics.analytics.INVENTORY;

const { DIMENSION_EVENT_ID } = appConstants.analytics.analytics.CUSTOM_DIMENSIONS;

const eventMapper = {
  [OPEN_VPU]: () => ({
    action: CLICK,
    params: {
      category: BUTTON,
      label: VPU,
    },
  }),
  [PAGINATION_CLICK]: (eventParams) => ({
    action: CLICK,
    params: {
      category: PAGINATION,
      label: eventParams.label,
    },
  }),
  [EXPANDED_SECTION_OPEN]: () => ({
    action: OPEN,
    params: {
      category: EXPANDED_SECTION,
    },
  }),
  [STREAM_ACTION_CLICK]: (eventParams) => ({
    action: eventParams.action,
    params: {
      category: STREAM_ACTION,
      label: eventParams.label,
      [DIMENSION_EVENT_ID]: eventParams[DIMENSION_EVENT_ID],
    },
  }),
  [EXTERNAL_CONTENT_CREATE]: (eventParams) => ({
    action: eventParams.action,
    params: {
      eventId: eventParams.eventId,
      eventStartDate: eventParams.eventStartDate,
      tenant: eventParams.tenant,
    },
  }),
  [ADDED_BREAKDOWN]: (eventParams) => ({
    action: eventParams.action,
    params: {
      eventId: eventParams.eventId,
      eventStartDate: eventParams.eventStartDate,
      tenant: eventParams.tenant,
      breakDownType: eventParams.breakDownType,
      breakDownId: eventParams.breakDownId,
    },
  }),
  [FILTER_BY_LIFECYCLE_STATUSES]: (eventParams) => ({
    action: eventParams.action,
    params: {
      selectedStatus: eventParams.selectedStatus,
    },
  }),
  [SYSTEM_DEACTIVATE_STATUS_CLICK]: (eventParams) => ({
    action: eventParams.action,
    params: {
      label: eventParams.label,
      systemType: eventParams.systemType,
      date: eventParams.date,
    },
  }),
  [ADDED_STREAM_TARGET]: (eventParams) => ({
    action: eventParams.action,
    params: {
      label: eventParams.label,
      eventId: eventParams.eventId,
    },
  }),
  [ADDED_SET_OF_MAIN_CATEGORY]: (eventParams) => ({
    action: eventParams.action,
    params: {
      label: eventParams.label,
      eventId: eventParams.eventId,
    },
  }),
};

const AnalyticsService = {
  track(name, eventParams) {
    const { action, params } = eventMapper[name](eventParams);

    analytics.track(action, params);
  },
  page(data) {
    analytics.page(data);
  },
};

export default AnalyticsService;
