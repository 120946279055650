<template lang="pug">
    ValidationProvider.file-upload-container(
        ref="validator"
        tag="div"
        :vid="vid || name"
        :name="errorLabel || name"
        :rules="rules"
        v-slot="{ errors }"
    )
        div
            label.btn.btn-primary.pxl-button.file-upload-button.full-width(:disabled="disabled")
                input(
                    ref="fileInput"
                    v-show="false"
                    type="file"
                    :accept="accept"
                    @change.stop="onChangeFile($event.target.files[0])"
                    class="add-banner"
                )
                slot(name="label")
            slot(name="description")
        .full-width.relative
            .errors-container(v-if="errors[0]")
                span(v-for="(error, index) in errors" :key="index")
                    strong Error:&nbsp;
                    | {{ error }}
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  name: "FileUpload",
  components: { ValidationProvider },
  props: {
    vid: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: "",
    },
    errorLabel: {
      type: String,
      default: "",
    },
    accept: {
      type: String,
      default: "",
    },
    disabled: Boolean,
    rules: {
      type: [Object, String],
      default: "",
    },
    value: {
      type: null,
      default: null,
    },
  },
  methods: {
    async onChangeFile(file) {
      this.$refs.validator.syncValue([file]);
      const validationResult = await this.$refs.validator.validate();

      if (!validationResult.valid) {
        return;
      }

      this.$refs.validator.setFlags({ pristine: false });

      this.$emit("input", file);
      this.$refs.fileInput.value = null;
    },
  },
};
</script>

<style lang="stylus">
.file-upload-button
    margin-right 10px
</style>
