import axios from "./axios";
import { buildResponseArrayGetInterceptor, buildSearchURL } from "@/utils";

const baseUrl = "/api/v3/vidswap";

export default {
  getLeagues(params) {
    return axios
      .get(`${baseUrl}/leagues${buildSearchURL(params)}`)
      .then((response) => buildResponseArrayGetInterceptor(response, params));
  },
  getTeams(params) {
    return axios
      .get(`${baseUrl}/teams${buildSearchURL(params)}`)
      .then((response) => buildResponseArrayGetInterceptor(response, params));
  },
  getCreditTypesPerSport(params) {
    return axios
      .get(`${baseUrl}/creditTypes${buildSearchURL(params)}`)
      .then((response) => response.data);
  },
};
