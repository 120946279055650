var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", { staticClass: "vidswap-leagues" }, [_vm._v("VidSwap Leagues")]),
    _c(
      "div",
      {
        directives: [
          {
            name: "click-outside",
            rawName: "v-click-outside",
            value: _vm.onOutsideClick,
            expression: "onOutsideClick",
          },
        ],
        staticClass: "multi-select-checkbox-dropdown",
        class: { active: _vm.count, open: _vm.isDropdownOpen },
      },
      [
        _c(
          "button",
          {
            staticClass: "toggle-dropdown",
            attrs: { "aria-expanded": _vm.isDropdownOpen },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.toggleDropdown.apply(null, arguments)
              },
            },
          },
          [
            _c("span", { staticClass: "title" }, [
              _vm._v("Please select one or more leagues"),
            ]),
            _c("img", {
              staticClass: "open-indicator",
              class: { "open-dropdown": _vm.isDropdownOpen },
              attrs: {
                src: "assets/images/arrow.svg",
                width: "10",
                height: "10",
              },
            }),
          ]
        ),
        _c("div", { staticClass: "dropdown-menu" }, [
          _c("div", { staticClass: "search-area" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.search,
                  expression: "search",
                },
              ],
              ref: "search",
              staticClass: "search-field",
              attrs: { placeholder: "Search" },
              domProps: { value: _vm.search },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.search = $event.target.value
                  },
                  _vm.searchValue,
                ],
              },
            }),
            _c("img", {
              staticClass: "search-icon",
              attrs: {
                src: "assets/images/search.svg",
                width: "16",
                height: "16",
              },
            }),
          ]),
          _c("div", { staticClass: "divider" }),
          _c(
            "ul",
            {
              directives: [
                {
                  name: "infinite-scroll",
                  rawName: "v-infinite-scroll",
                  value: _vm.loadMoreData,
                  expression: "loadMoreData",
                },
              ],
              ref: "scrollList",
            },
            _vm._l(_vm.filterOptions, function (option) {
              return _c(
                "li",
                { key: option.id },
                [
                  _c(
                    "checkbox",
                    {
                      staticClass: "option-name",
                      attrs: { value: _vm.isSelected(option.id) },
                      on: {
                        input: function ($event) {
                          return _vm.toggleOption(option.id)
                        },
                      },
                    },
                    [_vm._v(_vm._s(option.name))]
                  ),
                  _c("div", { staticClass: "option-label" }, [
                    _vm._v(
                      _vm._s(option.country) +
                        " " +
                        _vm._s(option.state) +
                        " " +
                        _vm._s(option.sportName) +
                        " " +
                        _vm._s(option.organizationType)
                    ),
                  ]),
                ],
                1
              )
            }),
            0
          ),
        ]),
      ]
    ),
    _vm.viewedLeagues.length > 0
      ? _c(
          "div",
          { staticClass: "leagues-container" },
          _vm._l(_vm.viewedLeagues, function (option) {
            return _c("div", { key: option.id, staticClass: "league-card" }, [
              _c("img", {
                staticClass: "remove-league-icon",
                attrs: {
                  src: "assets/images/close.svg",
                  width: "8",
                  height: "8",
                },
                on: {
                  click: function ($event) {
                    return _vm.toggleOption(option.id)
                  },
                },
              }),
              _c("div", { staticClass: "league-card__name" }, [
                _vm._v(_vm._s(option.name)),
              ]),
              _c("div", { staticClass: "league-card__organization" }, [
                _vm._v(_vm._s(option.organizationType)),
              ]),
            ])
          }),
          0
        )
      : _vm._e(),
    _vm.isShowMoreAvailable
      ? _c("div", { staticClass: "view-more-section" }, [
          _c(
            "a",
            {
              staticClass: "button",
              on: {
                click: function ($event) {
                  return _vm.showMoreItems()
                },
              },
            },
            [_vm._v("View more")]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }