<template lang="pug">
    .clear-filters-component
        .header-button-block-wrapper.pull-right(:class="{ active: isDirty }" @click="reset")
            a.clear-items &nbsp;

</template>

<script>
import _ from "lodash";
import { createNamespacedHelpers } from "vuex";

const { mapGetters: mapUserGetters } = createNamespacedHelpers("user");

export default {
  name: "ClearFilters",
  props: {
    filters: {
      type: Array,
      required: true,
      default: () => [],
    },
    resetFilters: Boolean,
  },
  computed: {
    ...mapUserGetters(["isAccountAdmin"]),

    isDirty() {
      return this.filtersData.some((filter) => {
        if (_.isObject(filter)) {
          return (
            this.$route.query[filter.name] !== undefined &&
            this.$route.query[filter.name] != filter.value
          );
        }

        if (this.$route.query[filter] === "upcoming,live,initializing") {
          return false;
        }

        return this.$route.query[filter] !== undefined;
      });
    },
    filtersData() {
      if (this.isAccountAdmin) {
        return this.filters.filter((item) => item !== "tenant");
      }

      return this.filters;
    },
  },
  watch: {
    resetFilters(val) {
      if (val) {
        this.reset();
      }
    },
  },
  methods: {
    reset() {
      if (!this.isDirty) {
        return;
      }
      const [remove, add] = _.partition(this.filtersData, (filter) => !_.isObject(filter));
      const query = _.omit(this.$route.query, remove);

      if (this.$route.query.status) {
        query.status = this.$route.query.status;
      }
      add.forEach((filter) => (query[filter.name] = filter.value));
      // Emit "reset" only after push succeeded to avoid navigation failures.
      this.$router.push({ query }).then(() => this.$emit("reset"));
    },
  },
};
</script>

<style lang="stylus">
.clear-filters-component
    .header-button-block-wrapper
        opacity 0.55
        cursor default
        height 42px
        .clear-items
            display inline-block
            width 16px
            height 16px
            background url('/assets/images/cross.svg') no-repeat
            background-size contain
            position relative
            top 3px
    .active
        cursor pointer
        box-shadow inset 0 0 4px #000;
        background-color rgba(32,41,50,0.8);
</style>
