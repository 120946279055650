<template lang="pug">
.search-component.pull-right(v-click-outside="hideDropdown")
  input#search-field(
    v-model="searchValue",
    :placeholder="placeholder",
    :class="{ 'open-dropdown': dropdownFocused }",
    @input="updateSearchQuery($event.target.value)",
    @focus="dropdownFocused = true"
  )
  label.search-icon(for="search-field")
  .dropdown-menu-search(v-if="multiSearch")
    .search-content
      .search-by
        span(v-if="!checkAllOptions && selectedFiltersNames.length > 0") Search by {{ selectedFiltersNames }}
        span(v-if="checkAllOptions && ($route.query.q || selectedFiltersNames.length > 0)") Search by all
      ul
        li(v-for="option in searchFilters", :key="option.id")
          checkbox(v-model="option.selected", @input="toggleSearchOption(option)") {{ option.name }}
</template>

<script>
import { ClickOutside } from "@/utils/directives";
import { Checkbox } from "@/components/shared";

export default {
  name: "PageSearch",
  directives: { ClickOutside },
  components: { Checkbox },
  props: {
    placeholder: {
      type: String,
      default: "Search",
    },
    multiSearch: {
      type: Boolean,
      default: false,
    },
    searchDelay: {
      type: Number,
      default: 700,
    },
    searchFilters: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    const defaultSearchQuery = this.searchFilters.map((item) => item.value).join(",");

    return {
      checkAllOptions: true,
      dropdownFocused: false,
      timer: null,
      selectedFiltersValues: "",
      selectedFiltersNames: "",
      searchValue: "",
      defaultSearchQuery,
    };
  },
  watch: {
    "$route.query.q": {
      immediate: true,
      handler(value) {
        this.searchValue = value;
      },
    },
    "$route.query.search": {
      immediate: true,
      handler(value) {
        this.changeSearchFiltersState();
      },
    },
  },
  methods: {
    hideDropdown() {
      this.dropdownFocused = false;
    },
    updateSearchQuery(search, searchOption) {
      search = search.trim();

      if (this.searchDelay && this.timer) {
        clearTimeout(this.timer);
      }

      if (/^([0-9a-f]{24})$/.test(search) && !searchOption) {
        this.searchFilters.forEach((item) => {
          if (item.value === "system.id" || item.value === "internalId") {
            item.selected = true;
          }
        });
        this.updateSelectedData();
      }

      const updateSearchQuery = () => {
        const query = {
          q: search || undefined,
          offset: 0,
          search: this.multiSearch ? this.selectedFiltersValues : undefined,
        };

        this.searchValue = search;
        this.$updateQuery(query);
      };

      this.timer = this.searchDelay
        ? setTimeout(updateSearchQuery, this.searchDelay)
        : updateSearchQuery();
    },
    toggleSearchOption() {
      this.updateSelectedData();

      if (this.searchValue) {
        this.updateSearchQuery(this.searchValue, "searchOption");
      }
    },
    changeSearchFiltersState() {
      this.searchFilters.forEach((filter) => {
        const querySearchFilters = this.$route.query.search
          ? this.$route.query.search.split(",").map((item) => item.trim())
          : [];
        const preperedValue = filter.value.split(",");

        filter.selected = preperedValue.some((val) => {
          return querySearchFilters.includes(val);
        });
      });

      this.updateSelectedData();
    },
    updateSelectedData() {
      const selected = this.searchFilters.filter((filter) => filter.selected);

      this.selectedFiltersNames =
        selected.map((item) => item.name).join(", ") ||
        this.searchFilters.map((item) => item.name).join(", ");
      this.selectedFiltersValues =
        selected.map((item) => item.value).join(",") || this.defaultSearchQuery;
      this.checkAllOptions =
        this.selectedFiltersNames.split(",").length === this.searchFilters.length;
    },
  },
};
</script>

<style lang="stylus">
.search-component {
  position: relative;
  color: text-color;

  .dropdown-menu-search {
    .search-content {
      position: absolute;
      right: 0;
      opacity: 0;
      z-index: -1;
      background: #fff;
      overflow: hidden;
      transition: max-height 0.95s ease;
      max-height: 0;
      visibility: hidden;
    }

    ul {
      padding: 0 10px;
      margin: 0;

      li {
        list-style: none;
        line-height: 2;

        label {
          font-weight: normal;
          cursor: pointer;
          display: flex;
          align-items: center;

          .checkbox-component {
            margin-right: 11px;
          }
        }
      }
    }

    .search-by {
      padding: 10px;
      font-size: 13px;
      font-weight: 500;
      line-height: 1.46;
      color: search-input-color;
    }

    .error-container {
      padding: 0 10px;
      margin-bottom: 8px;
      color: error-msg-text-color;
    }
  }

  .search-icon {
    background: url('/assets/images/search.svg');
    width: 20px;
    height: 20px;
    position: absolute;
    right: 11px;
    top: 11px;
    margin: 0;
    cursor: pointer;
  }

  #search-field {
    padding: 13px 32px 13px 0;
    text-indent: 11px;
    width: 280px;
    height: 42px;
    border: none;
    transition: width 0.5s;
    outline: none;
    border-radius: 3px;
    border: solid 1px search-input-color;

    &.open-dropdown {
      & ~ .dropdown-menu-search {
        .search-content {
          display: block;
          visibility: visible;
          opacity: 1;
          width: 100%;
          z-index: 1000;
          max-height: 350px;
          margin-top: -2px;
          border-radius: 3px;
          box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.2);
          border: solid 1px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}

@media (max-width: 1320px) {
  .search-component {
    #search-field {
      width: 200px;
    }
  }
}
</style>
