/* globals branch */
import axios from "./axios";
import store from "@/store";
import { buildResponseArrayGetInterceptor, buildSearchURL } from "@/utils";

const DEVICES = [
  { id: "ios", name: "ios" },
  { id: "android", name: "android" },
];

const TYPES = [
  { id: "preroll", name: "preroll" },
  { id: "midroll", name: "midroll" },
];

export default {
  get devices() {
    return DEVICES;
  },

  get types() {
    return TYPES;
  },

  getAdds(condition = { limit: 500 }) {
    return axios
      .get(`/api/v3/announcements${buildSearchURL(condition)}`)
      .then((response) => buildResponseArrayGetInterceptor(response, condition));
  },

  getAdvertisingById(id) {
    return axios.get(`/api/v3/announcements/${id}`).then((response) => response.data);
  },

  createAdvertising(data = null) {
    return axios.post("/api/v3/announcements", data).then((response) => response.data);
  },

  updateAdvertising(id, data) {
    return axios.patch(`/api/v3/announcements/${id}`, data).then((response) => response.data);
  },

  deleteAdvertising(addId) {
    return axios.delete(`/api/v3/announcements/${addId}`);
  },

  getApplications(condition = { isActive: true }) {
    const isAccountAdmin = store.getters["user/isAccountAdmin"];

    if (isAccountAdmin) {
      condition.tenant = store.state.user.activeTenant;
    }

    return axios
      .get(`/api/v3/applications${buildSearchURL(condition)}`)
      .then((response) => buildResponseArrayGetInterceptor(response, condition));
  },

  getApplicationById(id) {
    return axios.get(`/api/v3/applications/id/${id}`).then((response) => response.data);
  },

  createApplication(data = null) {
    return axios.post("/api/v3/applications", data).then((response) => response.data);
  },

  updateApplication(id, data) {
    return axios.patch(`/api/v3/applications/${id}`, data).then((response) => response.data);
  },

  getTemplates(params = {}) {
    return axios
      .get(`/api/v3/templates${buildSearchURL(params)}`)
      .then((response) => buildResponseArrayGetInterceptor(response, params));
  },

  updateTemplate(id, data) {
    return axios.put(`/api/v3/templates/${id}`, data).then((response) => response.data);
  },

  init(key) {
    return new Promise((resolve, reject) => {
      branch.init(key, {}, (err, data) => {
        if (err) {
          return reject(err);
        }

        resolve(data);
      });
    });
  },

  generateLink(data) {
    return new Promise((resolve, reject) => {
      branch.link({ data: data }, (err, link) => {
        if (err) {
          return reject(err);
        }

        resolve(link);
      });
    });
  },
};
