var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showElement,
          expression: "showElement",
        },
      ],
      class: {
        "block-overlay": _vm.partialLoader,
        "white-background": _vm.whiteBackground,
      },
      attrs: { id: "overlay" },
    },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "floatingCirclesG" } }, [
      _c("div", { staticClass: "f_circleG", attrs: { id: "frotateG_01" } }),
      _c("div", { staticClass: "f_circleG", attrs: { id: "frotateG_02" } }),
      _c("div", { staticClass: "f_circleG", attrs: { id: "frotateG_03" } }),
      _c("div", { staticClass: "f_circleG", attrs: { id: "frotateG_04" } }),
      _c("div", { staticClass: "f_circleG", attrs: { id: "frotateG_05" } }),
      _c("div", { staticClass: "f_circleG", attrs: { id: "frotateG_06" } }),
      _c("div", { staticClass: "f_circleG", attrs: { id: "frotateG_07" } }),
      _c("div", { staticClass: "f_circleG", attrs: { id: "frotateG_08" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }