<template lang="pug">
    validation-provider(
        tag="div"
        :vid="vid || name"
        :rules="rules"
        :name="errorLabel || label || name"
        class="pxl-select"
        v-slot="{ errors }"
    )
        v-select(
            ref="select"
            :dropdownShouldOpen="dropdownOpen"
            :options="filteredOptions"
            :filterable="false"
            :id="name"
            :value="value"
            :reduce="reducer"
            :label="optionsLabel"
            :disabled="disabled"
            :multiple="multiple"
            :placeholder="optionsPlaceholder"
            :get-option-key="getOptionKey"
            :get-option-label="getOptionLabel"
            :class="{ 'invalid': errors[0], 'has-value': hasValue, 'focus': focused }"
            @input="updateValue"
            @option:selecting="onOptionSelect"
            @search="onSearch"
            @open="onOpen"
            @close="onClose"
        ) 
            template(#selected-option="option")
                slot(name="selected-option" :option="apiFetchInitialOption ? selectedOption : option" :id="option.id")
            template(#option="option")
                slot(name="option" :option="option" :id="option.id")
            template(#list-footer="")
                li(ref="load" class="vs__dropdown-option loader", v-show="canLoadMore && isInfiniteScroll") Loading more options...
        label(:for="name" :class="{ 'dropdown-open': focused }" @click="focusInput")
            span {{ label || name }}
        .errors-container(v-if="errors[0]")
            span(v-for="(error, index) in errors" :key="index")
                strong Error:&nbsp;
                | {{ error }}
</template>

<script>
import _ from "lodash";
import vSelect from "vue-select";
import { ValidationProvider } from "vee-validate";
import "vue-select/dist/vue-select.css";

export default {
  name: "AutocompleteSelect",
  components: { ValidationProvider, vSelect },
  props: {
    vid: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    errorLabel: {
      type: String,
      default: "",
    },
    rules: {
      type: [Object, String],
      default: "",
    },
    optionsPlaceholder: {
      type: String,
      default: "",
    },
    optionsLabel: {
      type: String,
      default: "name",
    },
    optionsKey: {
      type: String,
      default: "id",
    },
    value: {
      type: [Array, Object, String, Number],
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    // Should return promise, and data.items[] as result for options
    apiFetch: {
      type: Function,
      required: true,
    },
    apiFetchInitialOption: {
      type: Function,
      default: null,
    },
    reduce: {
      type: Function,
      default: null,
    },
    dropdownOpen: {
      type: Function,
    },
    getOptionLabel: {
      type: Function,
      default: function (option) {
        if (typeof option === "object") {
          if (!option.hasOwnProperty(this.label)) {
            return;
          }

          return option[this.label];
        }

        return option;
      },
    },
    getOptionKey: {
      type: Function,
      default: undefined,
    },
    loadLimit: {
      type: Number,
      default: 10,
    },
    isInfiniteScroll: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      options: [],
      offset: 0,
      observer: null,
      focused: false,
      canLoadMore: true,
      selectedOption: {},
      isSearchReady: false,
      loading: null,
      tempSearchValue: "",
      search: "",
    };
  },
  computed: {
    hasValue() {
      if (this.value instanceof Array) {
        return Boolean(this.value.length);
      }

      return Boolean(this.value);
    },
    reducer() {
      if (this.reduce) {
        return this.reduce;
      } else if (this.optionsKey) {
        const key = this.optionsKey;

        return (option) => option[key];
      }

      return (option) => option;
    },
    filteredOptions() {
      return this.options.filter((option) => {
        if (this.multiple && this.value) {
          return !this.value.some((item) =>
            typeof item === "object" && !this.optionsKey
              ? item.id === option.id
              : this.reducer(item) === this.reducer(option),
          );
        }

        return true;
      });
    },
  },
  watch: {
    options(value) {
      this.$emit("update:options", value);
    },
    isSearchReady(value) {
      if (value && this.tempSearchValue) {
        this.onSearch(this.tempSearchValue, this.loading);
      }
    },
    name() {
      this.resetParams();
    },
  },
  mounted() {
    if (this.isInfiniteScroll) {
      this.observer = new IntersectionObserver(this.infiniteScroll);
    }

    if (this.value && this.apiFetchInitialOption) {
      this.apiFetchInitialOption(this.value).then((option) => (this.selectedOption = option));
    }
  },
  methods: {
    focusInput() {
      this.$refs.select && this.$refs.select.$refs.search.focus();
    },
    updateValue(value) {
      this.$emit("input", value);
    },
    setFocus(value) {
      this.focused = value;
    },
    resetParams() {
      this.offset = 0;
      this.search = "";
      this.options = [];
      this.canLoadMore = true;
    },
    onOptionSelect(value) {
      this.selectedOption = value;
    },

    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;

        const scrollTop = target.offsetParent.scrollTop;

        await this.fetchOptions();

        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    async onSearch(search, loading) {
      // save search in temp variable to have it when init data received and search is ready
      this.tempSearchValue = search;
      this.loading = loading;

      if (!this.isSearchReady) {
        return;
      }

      loading(true);
      this.resetParams();
      this.search = search;
      this.fetchSearchOptions(this, loading);
      this.tempSearchValue = "";
    },

    async onOpen() {
      this.setFocus(true);

      const canFetchOptions = this.isInfiniteScroll && this.options.length === 0;

      if (canFetchOptions) {
        await this.fetchOptions();
      }

      if (this.isInfiniteScroll) {
        await this.$nextTick();

        this.$refs.load && this.observer.observe(this.$refs.load);
      }
    },
    async onClose() {
      if (this.isInfiniteScroll) {
        await this.observer.disconnect();
      }

      this.resetParams();
      this.setFocus(false);
    },

    onError(error) {
      console.log("Error occurred", { error });
    },
    onSuccess({ items: options } = []) {
      this.isSearchReady = true;

      if (options.length) {
        this.options = this.options.concat(options);
        this.offset += this.loadLimit;

        this.canLoadMore = this.isInfiniteScroll && options.length >= this.loadLimit;
      } else {
        this.canLoadMore = false;
      }
    },

    fetchOptions() {
      this.isSearchReady = false;

      if (
        !this.canLoadMore ||
        !this.focused ||
        this.name === "categoriesTeam" ||
        this.name === "relatedCategories"
      ) {
        return;
      }

      const params = {
        q: this.search || undefined,
        limit: this.loadLimit,
        offset: this.offset,
      };

      return this.apiFetch(params).then(this.onSuccess).catch(this.onError);
    },

    fetchSearchOptions: _.debounce(async (vm, loading) => {
      await vm.fetchOptions();
      loading(false);

      if (vm.isInfiniteScroll) {
        await vm.$nextTick();

        vm.$refs.load && vm.observer.observe(vm.$refs.load);
      }
    }, 350),
  },
};
</script>

<style lang="stylus"></style>
