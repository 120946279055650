export default [
  {
    name: "Tools",
    path: "/tools",
    meta: { title: "Tools", requiresAuth: true, accessRoles: ["superadmin", "accountadmin"] },
    component: (resolve) => require(["@tools/pages/index.vue"], resolve),
    redirect: {
      name: "Tenants",
    },
    children: [
      {
        name: "Tenants",
        path: "/tools/tenants",
        meta: {
          title: "Tools: Tenants",
          requiresAuth: true,
          group: "Tools",
          accessRoles: ["superadmin", "accountadmin"],
        },
        component: (resolve) => require(["@tools/pages/tenants/index.vue"], resolve),
      },
      {
        name: "Applications",
        path: "/tools/applications",
        meta: {
          title: "Tools: Applications",
          requiresAuth: true,
          group: "Tools",
          accessRoles: ["superadmin"],
        },
        component: (resolve) => require(["@tools/pages/applications/index.vue"], resolve),
      },
      {
        name: "Templates",
        path: "/tools/templates",
        meta: {
          title: "Tools: Templates",
          requiresAuth: true,
          group: "Tools",
          accessRoles: ["superadmin"],
        },
        component: (resolve) => require(["@tools/pages/templates.vue"], resolve),
      },
      {
        name: "DeepLinks",
        path: "/tools/deep-links",
        meta: {
          title: "Tools: Deep Links",
          requiresAuth: true,
          group: "Tools",
          accessRoles: ["superadmin", "accountadmin"],
        },
        component: (resolve) => require(["@tools/pages/deep-links.vue"], resolve),
      },
    ],
  },
  {
    name: "AnnounceCreate",
    path: "/tenants/announce/create",
    meta: {
      title: "Create announce",
      requiresAuth: true,
      group: "Tools",
      accessRoles: ["superadmin", "accountadmin"],
    },
    component: (resolve) => require(["@tools/pages/tenants/announce/create.vue"], resolve),
  },
  {
    name: "AnnounceEdit",
    path: "/tenants/announce/:id/edit",
    meta: {
      title: "Edit advertising",
      requiresAuth: true,
      group: "Tools",
      accessRoles: ["superadmin", "accountadmin"],
    },
    component: (resolve) => require(["@tools/pages/tenants/announce/_id/edit.vue"], resolve),
  },
  {
    name: "ApplicationCreate",
    path: "/applications/create",
    meta: {
      title: "Create application",
      requiresAuth: true,
      group: "Tools",
      accessRoles: ["superadmin"],
    },
    component: (resolve) => require(["@tools/pages/applications/create.vue"], resolve),
  },
  {
    name: "ApplicationEdit",
    path: "/applications/:id/edit",
    meta: {
      title: "Edit application",
      requiresAuth: true,
      group: "Tools",
      accessRoles: ["superadmin"],
    },
    component: (resolve) => require(["@tools/pages/applications/_id/edit.vue"], resolve),
  },
];
/**
 * TODO: Fix route path's for
 * - AnnounceCreate
 * - AnnounceEdit
 * - ApplicationCreate
 * - ApplicationEdit
 *
 * They all need to follow same base path: "/tools"
 */
