export const addAdditionStatusFields = (field) => {
  switch (field) {
    case "camera":
    case "connection":
      return ["Live", "Reset", "Sleep", "Maintenance"];
    case "health":
    case "gpu":
    case "cpu":
    case "hd":
    case "pano":
      return ["Live", "Reset"];
    default:
      return [];
  }
};
