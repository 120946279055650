import _ from "lodash";
import axios from "./axios";
import store from "@/store";
import { buildResponseArrayGetInterceptor, buildSearchURL } from "@/utils";

const baseUrl = "/api/v3/events";

const CLOUD_MAPPER = {
  created: { value: "Created", color: "green" },
  init: { value: "Init", color: "#ffcc00" },
  notCreated: { value: "Not Created", color: "red" },
  notScheduled: { value: "-", color: "black" },
};
const SEARCH_FILTERS = [
  { name: "Name", value: "name" },
  { name: "System Name", value: "venueName" },
  { name: "Venue ID", value: "venueId" },
  { name: "Event ID", value: "id" },
  { name: "Dongle ID", value: "dongleIdentifier" },
];
const ALL_EVENTS_TAB = "All Events";
const INITIALIZING_TAB = "Initializing";
const PROBLEMATIC_TAB = "Problematic";
const TABS = [{ title: ALL_EVENTS_TAB }, { title: INITIALIZING_TAB }, { title: PROBLEMATIC_TAB }];

export default {
  get tableCols() {
    return [
      {
        name: "name",
        title: "Name",
      },
      {
        name: "system",
        title: "System",
      },
      {
        name: "streamConfig",
        title: "Live Streams",
        class: "width-8",
      },
      {
        name: "streamData",
        hidden: true,
      },
      {
        name: "streamStatus",
        title: "Stream Status",
        class: "width-8",
        dropdownOptions: this.options.streamStatus,
      },
      {
        name: "smStatus",
        title: "Status",
        class: "width-8",
        dropdownOptions: this.options.smStatus,
      },
      {
        name: "connection",
        title: "Connection",
        dropdownOptions: this.options.connection,
      },
      {
        name: "camera",
        title: "Camera",
        class: "width-8",
        dropdownOptions: this.options.camera,
      },
      {
        name: "health",
        title: "Health",
        class: "width-8",
        dropdownOptions: this.options.health,
      },
      {
        name: "cloudStatus",
        title: "Cloud",
        class: "width-8",
        dropdownOptions: this.options.cloudStatus,
      },
      {
        name: "systemMetrics",
        multiple: true,
        first: {
          name: "cpu",
          title: "CPU",
          dropdownOptions: this.options.cpu,
        },
        second: {
          name: "gpu",
          title: "GPU",
          dropdownOptions: this.options.gpu,
        },
      },
      {
        name: "urls",
        multiple: true,
        first: {
          name: "hd",
          title: "HD",
          dropdownOptions: this.options.hd,
        },
        second: {
          name: "pano",
          title: "Pano",
          dropdownOptions: this.options.pano,
        },
      },
      {
        name: "isReadOnly",
        hidden: true,
      },
      {
        name: "startTime",
        hidden: true,
      },
      {
        name: "status",
        hidden: true,
      },
    ];
  },
  get filtersValues() {
    return [
      "smStatus",
      "connection",
      "cloudStatus",
      "camera",
      "health",
      "streamStatus",
      "cpu",
      "gpu",
      "hd",
      "pano",
    ];
  },
  get clearableFilters() {
    return [
      "tenant",
      "search",
      "q",
      "showExternal",
      "hideExternal",
      { name: "offset", value: "0" },
    ];
  },
  get searchFilters() {
    return SEARCH_FILTERS;
  },
  get tabs() {
    return TABS;
  },
  get initializingTab() {
    return INITIALIZING_TAB.toLowerCase();
  },
  get problematicTab() {
    return PROBLEMATIC_TAB.toLowerCase();
  },
  get options() {
    return {
      smStatus: [
        {
          id: "Live",
          title: "Live",
        },
        {
          id: "Sleep",
          title: "Sleep",
        },
        {
          id: "Reset",
          title: "Reset",
        },
        {
          id: "Offline",
          title: "Offline",
        },
        {
          id: "Maintenance",
          title: "Maintenance",
        },
      ],
      connection: [
        {
          id: "Ok",
          title: "Connected",
        },
        {
          id: "Error",
          title: "Not connected",
        },
      ],
      camera: [
        {
          id: "Ok",
          title: "Good",
        },
        {
          id: ["Error", "NA"].join(","),
          title: "Not Connected",
        },
        {
          id: "Warning",
          title: "Low FPS",
        },
      ],
      cloudStatus: [
        {
          id: "init",
          title: "Init",
        },
        {
          id: "created",
          title: "Created",
        },
        {
          id: "notCreated",
          title: "Not created",
        },
      ],
      health: [
        {
          id: "Ok",
          title: "Ok",
        },
        {
          id: "Warning",
          title: "Warning",
        },
        {
          id: "Error",
          title: "Error",
        },
      ],
      cpu: [
        {
          id: "Ok",
          title: "Ok",
        },
        {
          id: "Warning",
          title: "Warning",
        },
        {
          id: "Error",
          title: "Error",
        },
      ],
      gpu: [
        {
          id: "Ok",
          title: "Ok",
        },
        {
          id: "Warning",
          title: "Warning",
        },
        {
          id: "Error",
          title: "Error",
        },
      ],
      hd: [
        {
          id: "Ok",
          title: "Ok",
        },
        {
          id: "Warning",
          title: "Warning",
        },
        {
          id: "Error",
          title: "Error",
        },
      ],
      pano: [
        {
          id: "Ok",
          title: "Ok",
        },
        {
          id: "Warning",
          title: "Warning",
        },
        {
          id: "Error",
          title: "Error",
        },
      ],
      streamStatus: [
        {
          id: "connected",
          title: "Connected",
        },
        {
          id: "disconnected",
          title: "Disconnected",
        },
      ],
    };
  },

  mapWsEvent(item, event) {
    // need for proper merging messages with videos field
    item = _.cloneDeep(item);

    const mapped = {};

    if (item.name) {
      mapped.name = item.name;
    }

    if (item.cloudStatus) {
      mapped.cloudStatus = this.mapCloudStatus(item.cloudStatus, _.get(event, "systemMetrics"));
    }

    if (item.videos && item.videos.live) {
      mapped.urls = item.videos.live;
      mapped.status = "live";
    }

    if (item.startDate) {
      mapped.startTime = this.timeDelta(item.startDate);
    }

    if (item.endDate) {
      mapped.endsIn = this.timeDelta(item.endDate);
    }

    return mapped;
  },
  timeDelta(date) {
    return (new Date(date) - new Date()) / 1000;
  },
  mapMonitoringEvent(item) {
    const mapped = {};

    if (item.startDateTime) {
      mapped.startDateTime = new Date(item.startDateTime);
      mapped.startTime = (mapped.startDateTime - new Date()) / 1000;
      mapped.endsIn = (new Date(item.endDateTime) - new Date()) / 1000;
    }

    mapped.cloudStatus = this.mapCloudStatus(item.cloudStatus, _.get(item, "systemMetrics.values"));

    [
      "tenant",
      "system.id",
      "system.name",
      "dongleIdentifier",
      "systemVersion",
      "streamStatus",
      "polyEventId",
      "polyPublicIp",
      "publicIp",
    ].forEach((elem) => {
      if (!_.get(item, elem)) {
        _.set(mapped, elem, "-");
      }
    });

    return mapped;
  },
  mapCloudStatus(cloud, metrics = {}) {
    if (!cloud) {
      return { value: "-", color: "black" };
    }

    const status = _.get(metrics, "status.description") || _.get(metrics, "status.value", "-");

    switch (status) {
      case "NotSet":
      case "Offline":
      case "-":
        return { value: "-", color: "black" };
      default:
        return Object.assign({}, CLOUD_MAPPER[cloud]);
    }
  },
  getMonitoringEvents(params) {
    const activeTenant = store.state.user.activeTenant;

    if (activeTenant && !params.tenant) {
      params.tenant = activeTenant;
    }

    return axios
      .get(`${baseUrl}/monitoring${buildSearchURL(params)}`)
      .then((response) => buildResponseArrayGetInterceptor(response, params));
  },
  getPolyEventId(eventId) {
    return axios.get(`${baseUrl}/${eventId}/poly-event-id`).then((item) => item.data);
  },
  getEventPublicIP(eventId) {
    return axios.get(`${baseUrl}/${eventId}/public-ip`).then((item) => item.data);
  },
};
