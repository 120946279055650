export default [
  {
    name: "Teams",
    path: "/teams",
    meta: {
      title: "Teams",
      group: "Teams",
      requiresAuth: true,
      requiresAccountTenant: true,
      accessRoles: ["superadmin", "accountadmin"],
    },
    component: (resolve) => require(["@teams/pages/index.vue"], resolve),
  },
  {
    name: "TeamsCreate",
    path: "/teams/create",
    meta: { title: "Create team", group: "Teams", requiresAuth: true },
    component: (resolve) => require(["@teams/pages/create.vue"], resolve),
  },
  {
    name: "TeamsEdit",
    path: "/teams/:id/edit",
    meta: { title: "Edit team", group: "Teams", requiresAuth: true },
    component: (resolve) => require(["@teams/pages/_id/edit.vue"], resolve),
  },
];
