import { root } from "@/constants";
import _ from "lodash";

const { DEFAULT_COUNT } = root.pagination;

export const isEmptyArray = (arr) => Array.isArray(arr) && arr.length === 0;

export const removeEmptyValues = (obj) => {
  return _(Object.assign({}, obj))
    .omitBy(_.isUndefined)
    .omitBy(_.isNull)
    .omitBy(isEmptyArray)
    .omit("view")
    .value();
};

export const buildSearchURL = (params) => {
  const queryParams = new URLSearchParams();

  _.map(removeEmptyValues(params), (value, key) => {
    if (Array.isArray(params[key])) {
      _.map(value, (item) => queryParams.append(key, item));
    } else {
      queryParams.append(key, value);
    }
  });

  return queryParams.toString() ? `?${queryParams.toString()}` : "";
};

export const buildResponseArrayGetInterceptor = (response, params) => {
  const items = _.isArray(response.data) ? response.data : response.data.items;
  const meta = response.meta || {};
  const links = response.links || {};

  if (!_.isArray(response.data) && response.data.total) {
    meta.total = response.data.total;
  }

  const pagination = buildPagination(items, meta.total, params, links.pagination);

  return { items, meta, pagination };
};

export const buildPagination = (items, total, params = {}, pagination) => {
  const offset = Number(params.offset) || 0;
  const limit = Number(params.limit) || DEFAULT_COUNT;
  const page = Math.floor(offset / limit);
  const onPage = _.size(items);
  // eslint-disable-next-line no-extra-parens
  const next = Boolean(pagination?.next) || (total > offset + limit && offset >= 0);
  const prev = offset !== 0;

  return { limit, offset, total, page, onPage, next, prev };
};
