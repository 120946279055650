<template lang="pug">
    div(v-if="domInserted")
        .modal-backdrop(:class="[visibleClass]")
        .modal.open(:class="[visibleClass, modalWindowClass]")
            .modal-dialog
                .modal-content
                    .modal-header
                        h3.modal-title {{ options.title }}
                    .modal-body
                        .row
                            .col-xs-12.text-center
                            | {{ options.text }}
                    .modal-footer
                        button.btn.btn-default(type="button" @click="onCancelConfirm") No
                        button.btn.btn-primary(type="button" @click="onSuccessConfirm") Yes
</template>

<script>
import { ClickOutside } from "@/utils/directives";
import ModalComponents from "@/modules/modals.js";
import ModalMixin from "./modalMixin";

export default {
  name: "ConfirmModal",
  components: ModalComponents,
  directives: { ClickOutside },
  mixins: [ModalMixin],
  data() {
    return {
      domInserted: false,
      visible: false,
      type: null,
      options: {
        title: "",
        text: "",
      },
    };
  },
  methods: {
    show(type = "confirm", options = {}) {
      this.type = type;
      this.options = options;
      this.setShowing(true);
    },
  },
};
</script>

<style lang="stylus"></style>
