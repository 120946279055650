export default [
  {
    name: "Clubs",
    path: "/clubs",
    meta: { title: "Clubs", group: "Clubs", requiresAuth: true, requiresAccountTenant: true },
    component: (resolve) => require(["@clubs/pages/index.vue"], resolve),
  },
  {
    name: "ClubsCreate",
    path: "/clubs/create",
    meta: { title: "Create club", requiresAuth: true, group: "Clubs" },
    component: (resolve) => require(["@clubs/pages/create.vue"], resolve),
  },
  {
    name: "ClubsEdit",
    path: "/clubs/:id/edit",
    meta: { title: "Edit club", requiresAuth: true, group: "Clubs" },
    component: (resolve) => require(["@clubs/pages/_id/edit.vue"], resolve),
  },
  {
    name: "ClubsEditBulk",
    path: "/clubs/bulk",
    meta: { title: "Bulk: Edit clubs", requiresAuth: true, group: "Clubs" },
    component: (resolve) => require(["@clubs/pages/bulk.vue"], resolve),
  },
];
