var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "clear-filters-component" }, [
    _c(
      "div",
      {
        staticClass: "header-button-block-wrapper pull-right",
        class: { active: _vm.isDirty },
        on: { click: _vm.reset },
      },
      [_c("a", { staticClass: "clear-items" }, [_vm._v(" ")])]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }