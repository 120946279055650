import axios from "./axios";
import { buildSearchURL } from "@/utils";

const baseUrl = "/api/v3/files";

export default {
  getSignedUrlForTemporaryFile(contentType, checkSum) {
    return axios
      .get(
        `${baseUrl}/signedUrl/temporary${buildSearchURL({
          contentType,
          checkSum,
        })}`,
      )
      .then((response) => response.data);
  },
  getSignedUrlForInstaller(contentType) {
    return axios
      .get(`${baseUrl}/signedUrl/firmware?${buildSearchURL({ contentType })}`)
      .then((response) => response.data);
  },
  putFileBySignedUrl(url, file) {
    const urlObject = new URL(url);
    const options = {
      method: "PUT",
      headers: {
        "Content-Type": file.type,
        "Content-Disposition": `filename*= UTF-8' '${encodeURIComponent(file.name)}`,
      },
      body: file,
    };

    return fetch(url, options).then(() => urlObject.pathname);
  },
  uploadLogoToTemporaryFile(logo) {
    // Logo is not touched
    if (logo === undefined) {
      return Promise.resolve();
    }

    // Logo is deleted
    if (logo === null) {
      return Promise.resolve(null);
    }

    return this.getSignedUrlForTemporaryFile(logo.type).then(({ url, fileKey }) =>
      this.putFileBySignedUrl(url, logo).then(() => fileKey),
    );
  },
  getMultipartUploadId(params) {
    return axios
      .get(`${baseUrl}/multipart-upload-id${buildSearchURL(params)}`)
      .then((response) => response.data);
  },
  getUploadPart(params) {
    return axios
      .get(`${baseUrl}/multipart-upload-part${buildSearchURL(params)}`)
      .then((response) => response.data);
  },
  completeMultipartUpload(data) {
    return axios
      .post(`${baseUrl}/complete-multipart-upload`, data)
      .then((response) => response.data);
  },
  abortMultipartUpload(data) {
    return axios.post(`${baseUrl}/abort-multipart-upload`, data).then((response) => response.data);
  },
  createAccessCodes(params, data) {
    return axios
      .post(`${baseUrl}/access-codes${buildSearchURL(params)}`, data)
      .then((response) => response);
  },
};
