var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "ul",
      { staticClass: "nav nav-tabs" },
      _vm._l(_vm.tabs, function (tab, idx) {
        return _c(
          "li",
          {
            key: idx,
            staticClass: "uib-tab nav-item",
            class: _vm.tabClasses(tab, idx),
          },
          [
            tab.removeOption
              ? _c("a", {
                  staticClass: "delete-icon",
                  attrs: { title: "Delete Tab" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.deleteTab(idx)
                    },
                  },
                })
              : _vm._e(),
            _c(
              "a",
              {
                staticClass: "nav-link",
                attrs: { href: "" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.select(idx)
                  },
                },
              },
              [_vm._v(_vm._s(tab.title))]
            ),
          ]
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "tab-content" },
      [
        _vm._l(_vm.tabs, function (tab, idx) {
          return _c(
            "div",
            {
              key: idx,
              staticClass: "tab-pane",
              class: _vm.tabClasses(tab, idx),
            },
            [_vm._t(idx)],
            2
          )
        }),
        _vm._t("default"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }