<template lang="pug">
    .create-component
        router-link(:to="path")
            a.button.btn.create-button(:disabled="disabled")
                .plus-icon
</template>

<script>
export default {
  name: "CreateBtn",
  props: {
    disabled: Boolean,
    path: {
      type: [String, Object],
      required: true,
    },
  },
};
</script>

<style lang="stylus">
.create-component
    .create-button
        width 60px
        height 60px
        position fixed
        bottom 4%
        padding 0
        right 20px
        border-radius 50%
        background-color button-background-color
        border none
        display flex
        justify-content center
        align-items center
        transition bottom ease 0.5s
        box-shadow 1px 2px 8px 2px rgba(0, 0, 0, 0.14), 1px 1px 0 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.12)
        .plus-icon
            background-image url("/assets/images/add.png")
            width 20px
            height 20px
            background-size contain
        &.move-up
            bottom 8%
</style>
