/* eslint-disable no-console */
import _ from "lodash";
import Bugsnag from "@bugsnag/js";
import AuthApi from "@/api/AuthApi";
import ReconnectingWebSocket from "reconnecting-websocket";
import { setEventMetadata } from "@/plugins/bugsnag";

const topics = {};

let instance = null;

const parseMessage = function (msg) {
  try {
    msg = JSON.parse(msg.data);
  } catch (e) {
    console.error(`Failed to parse message, error ${e.toString()}`);

    return null;
  }

  return msg;
};

const sendToSubscribers = function (msg) {
  _.get(topics, `${msg.type}`, []).forEach(({ fn, context }) => fn.call(context, msg));
};

export function connect() {
  return AuthApi.generateWSPToken({ mode: "monitoring" })
    .then((res) => {
      if (instance?.readyState < 2) {
        return;
      }

      instance = new ReconnectingWebSocket(
        `${res.wsp.protocol}://${res.wsp.hostname}:${res.wsp.port}/?token=${res.token}`,
      );

      instance.onopen = function () {
        console.log("WS connection is opened");
      };

      instance.onmessage = function (msg) {
        msg = parseMessage(msg);

        if (msg === null) {
          return;
        }

        if (msg.type === "control") {
          instance.isReady = msg.message.isReady;
        }

        if (msg.type === "ping") {
          sendData({ type: "pong", payload: msg.payload });

          return;
        }

        sendToSubscribers(msg);
      };

      instance.onclose = function () {
        console.log("WS connection is closed");
      };
      instance.onerror = function (error) {
        console.error("WS coonection error", error);

        if (error instanceof Error) {
          Bugsnag.notify(error, (event) => setEventMetadata(event));
        } else {
          const message = error.message || error.name || "Error Occurred";
          const err = new Error(message);

          Bugsnag.notify(err, (event) => setEventMetadata(event));
        }
      };

      return instance;
    })
    .catch((error) => {
      console.log("WS Error", error);
      Bugsnag.notify(error, (event) => setEventMetadata(event));
    });
}

export const subscribe = (pattern, fn, context = null) => {
  if (Array.isArray(topics[pattern])) {
    topics[pattern].push({ fn, context });
  } else {
    topics[pattern] = [{ fn, context }];
  }
};

export const unsubscribe = (pattern, fn) => {
  topics[pattern] = _.reject(topics[pattern], { fn: fn });
};

export const sendData = (data) => {
  instance.send(JSON.stringify({ data }));
};
