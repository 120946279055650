<template lang="pug">
div
  Loader
  AdminLayout(v-if="isAdminLayout")
    router-view(v-bind="$props")
  router-view(v-else v-bind="$props")
</template>

<script>
import AdminLayout from "@/layouts/admin.vue";
import { Loader } from "@/components/interface";
import { createNamespacedHelpers } from "vuex";
import { AuthApi } from "@/api";

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");
const { mapGetters: mapUserGetters } = createNamespacedHelpers("user");

export default {
  name: "App",
  components: { AdminLayout, Loader },
  computed: {
    ...mapAuthGetters(["isAuthenticated"]),
    ...mapUserGetters(["role"]),

    isAdminLayout() {
      const authRoutes = [
        "/",
        "/login",
        "/login/forgot",
        "/login/confirm",
        "/login/reset",
        "/invite",
        "/accept-invite",
        "/email-approve",
      ];

      // TODO: Temporary solution, needs to be adjusted in the future.
      return this.isAuthenticated && !authRoutes.includes(this.$route.path);
    },
  },
  async mounted() {
    const to = this.$route;
    const hasToken = await AuthApi.getAuthToken().catch(() => {});
    const isAuthenticated = Boolean(this.isAuthenticated && hasToken);
    const requiresAuth = to.matched.some((r) => r.meta.requiresAuth);
    const accessRoles = to.meta.accessRoles;
    const IS_UNAUTHENTICATED = requiresAuth && (!isAuthenticated || this.role === "user");
    const IS_WRONG_ROLE = accessRoles && !accessRoles.includes(this.role);

    if (IS_UNAUTHENTICATED) {
      this.$router.push({ name: "Login", query: { redirect: to.fullPath } });
    } else if (IS_WRONG_ROLE) {
      this.$router.push({ name: "Events" });
    }
  },
};
</script>
