import Vue from "vue";
import store from "@/store";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";

// TODO: Think about better local environment detection
const isLocal = window.location.hostname === "localhost";
const appReleaseStage = isLocal ? "local" : appConfig.releaseStage;

if (process.env.NODE_ENV !== "test") {
  Bugsnag.start({
    apiKey: globalUrls.bugsnagApiKey,
    appType: wlConfig.name,
    appVersion: clientVersion,
    autoTrackSessions: true,
    autoDetectErrors: true,
    releaseStage: appReleaseStage,
    plugins: [new BugsnagPluginVue()],
    enabledReleaseStages: ["devServer", "staging", "production"],
    enabledErrorTypes: {
      unhandledExceptions: true,
      unhandledRejections: true,
    },
    onError: setEventMetadata,
  });

  const bugsnagVue = Bugsnag.getPlugin("vue");

  bugsnagVue.installVueErrorHandler(Vue);
}

export function setEventMetadata(event) {
  const user = store.getters["user/currentUser"];

  if (user) {
    event.setUser(user.id, user.attributes.email, user.attributes.fullName);
    event.addMetadata("user", user);
  }
}
