var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pixellot-popover" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "click-outside",
            rawName: "v-click-outside",
            value: _vm.hide,
            expression: "hide",
          },
        ],
        staticClass: "pixellot-popover-face",
        on: { click: _vm.toggle },
      },
      [_vm._t("face")],
      2
    ),
    _vm.opened
      ? _c(
          "div",
          {
            staticClass: "pixellot-popover-content",
            class: "popover-" + _vm.position,
          },
          [
            _c(
              "div",
              { staticClass: "pixellot-popover-content-inner" },
              [_vm._t("content")],
              2
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }