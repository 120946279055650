import Vue from "vue";
import store from "@/store";
import { isSubtypeOf } from "@/utils";

import firebase from "./firebase";
import "./bugsnag";
import "./validation";
import Modal from "./modal";
import Toaster from "./toaster";

Vue.prototype.$modal = Modal;
Vue.prototype.$toaster = Toaster;
Vue.prototype.$firebase = firebase;
Vue.prototype.$updateQuery = function (queryToUpdate = {}) {
  if (!this.$router || !this.$route) {
    return;
  }

  const query = this.$route.query;
  const routeName = this.$route.name;
  const sameParams = isSubtypeOf(query, queryToUpdate);

  if (!sameParams) {
    this.$router.replace({
      name: routeName,
      query: { ...query, ...queryToUpdate },
    });
  }
};
Vue.prototype.$backRoute = function (backRouteName, fallbackQuery) {
  if (!this.$router || !this.$route) {
    return;
  }

  if (
    store.state.temp.previousRoute &&
    store.state.temp.previousRoute.name === backRouteName &&
    !fallbackQuery
  ) {
    this.$router.back();
  } else {
    this.$router.push({ name: backRouteName, query: fallbackQuery });
  }
};
