export default [
  {
    name: "BulkResult",
    path: "/bulk/result",
    meta: { title: "Bulk: Results", requiresAuth: true },
    component: (resolve) => require(["@admin/pages/bulk/result.vue"], resolve),
  },
  {
    name: "About",
    path: "/about",
    meta: { title: "About", requiresAuth: true },
    component: (resolve) => require(["@admin/pages/about.vue"], resolve),
  },
];
