import axios from "./axios";
import store from "@/store";
import { Cookies } from "@/utils/cookies";
import firebase from "@/plugins/firebase";

export default {
  async getAuthToken() {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const urlToken = urlParams.get("token");

      if (urlToken) {
        Cookies.set("token", urlToken);
      }

      const isFirebaseProvider = store.getters["user/isFirebaseProvider"];

      if (isFirebaseProvider) {
        // get firebase token, refreshed by default in 1 hour
        const token = await firebase.getToken();

        if (token) {
          Cookies.set("token", token);

          const refreshedToken = firebase.startTokenRefreshInterval();

          if (refreshedToken) {
            Cookies.set("token", refreshedToken);
          }
        }
      }

      return Promise.resolve(Cookies.get("token"));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  setAuthToken(token) {
    Cookies.set("token", token);
  },

  login({ email, password }) {
    return axios
      .post("/api/v3/auth/login", { email, password }, { skipAuth: true })
      .then((response) => response);
  },

  logout() {
    Cookies.set("token", "");
  },

  generateWSPToken(data) {
    return axios.post("/api/v3/auth/wsp-token", data);
  },

  getUsersMe() {
    return axios.get("/api/v3/users/me").then((response) => response);
  },

  setAuthorizationHeader(token) {
    axios.defaults.headers.common["X-Authorization"] = `Bearer ${token}`;
  },

  sendResetPasswordCode({ email }) {
    return axios.post("/api/v3/users/sendResetCode", { email }, { skipAuth: true });
  },

  async verifyResetPasswordCode({ code }) {
    return axios
      .post("/api/v3/users/checkResetCode", { code }, { skipAuth: true })
      .then((response) => response.data);
  },

  resetPassword(data) {
    return axios.post("/api/v3/users/resetPassword", data, { skipAuth: true });
  },

  approveEmail(config) {
    return axios.post("/api/v3/users/confirm-mail", {}, config);
  },
};
