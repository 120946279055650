<template lang="pug">
    validation-provider(
        tag="div"
        :vid="vid || name"
        :name="errorLabel || label || name"
        :rules="rules"
        v-slot="{ errors }"
    )
        vue-datepicker(
            :disabled="disabled"
            :type="type"
            :format="format"
            :value="value"
            :showSecond="showSecond"
            @input="onChange"
            :disabled-date="disabledDate"
            :disabled-time="disabledTime"
            class="pixellot-inline-date-picker"
        )
            template(v-slot:input)
                input(
                    ref="input"
                    class="form-control"
                    :id="name"
                    type="text"
                    :value="formatTime"
                    :disabled="disabled"
                    :class="{ 'ng-invalid ng-dirty': errors[0], 'ng-touched ng-valid has-value': hasValue }"
                    :autocomplete="autocomplete"
                )
                label(:for="name" @click="focusInput")
                    span {{ label || name }}
                .errors-container(v-if="errors[0]")
                    span(v-for="(error, index) in errors" :key="index")
                        strong Error:&nbsp;
                        | {{ error }}
</template>

<script>
import { ValidationProvider } from "vee-validate";
import vueDatepicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";

export default {
  name: "DatePicker",
  components: { ValidationProvider, vueDatepicker },
  props: {
    vid: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    errorLabel: {
      type: String,
      default: "",
    },
    rules: {
      type: [Object, String],
      default: "",
    },
    value: {
      type: [Date, String, Number],
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: "off",
    },
    format: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: undefined,
    },
    showSecond: {
      type: Boolean,
    },
    disabledDate: {
      type: Function,
      default: () => false,
    },
    disabledTime: {
      type: Function,
      default: () => false,
    },
  },
  computed: {
    hasValue() {
      return Boolean(this.value);
    },
    formatTime() {
      return this.value ? moment(this.value).format(this.format) : "";
    },
  },
  methods: {
    onChange(value) {
      this.$emit("input", value);
    },
    focusInput() {
      this.$refs.input && this.$refs.input.focus();
    },
  },
};
</script>

<style lang="stylus">
.pixellot-inline-date-picker
    width 100%
.mx-time-column .mx-time-list::after
    display none
</style>
