var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "itemsPerPage" }, [
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.selectedValue,
            expression: "selectedValue",
          },
        ],
        on: {
          change: [
            function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.selectedValue = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
            _vm.change,
          ],
        },
      },
      _vm._l(_vm.options, function (option) {
        return _c("option", { domProps: { value: option.id } }, [
          _vm._v(_vm._s(option.name)),
        ])
      }),
      0
    ),
    _c("span", [_vm._v("Per Page")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }