var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "menu" }, [
    _c("aside", [
      _c(
        "div",
        { staticClass: "brand-logo", class: { "stats-logo": _vm.isStats } },
        [
          _c(
            "label",
            {
              class: { "collapse-menu": _vm.isMenuOpened },
              attrs: { for: "nav-trigger" },
            },
            [
              _c("i", {
                staticClass: "fa fa-bars",
                attrs: { "aria-hidden": "true" },
              }),
            ]
          ),
          _c("div", {
            staticClass: "pxl-logo-img",
            class: {
              "collapse-pxl-logo": _vm.isMenuOpened,
              "pxl-logo": !_vm.isMenuOpened,
            },
          }),
        ]
      ),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.isMenuOpened,
            expression: "isMenuOpened",
          },
        ],
        staticClass: "nav-trigger",
        attrs: { id: "nav-trigger", type: "checkbox" },
        domProps: {
          checked: Array.isArray(_vm.isMenuOpened)
            ? _vm._i(_vm.isMenuOpened, null) > -1
            : _vm.isMenuOpened,
        },
        on: {
          change: [
            function ($event) {
              var $$a = _vm.isMenuOpened,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.isMenuOpened = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.isMenuOpened = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.isMenuOpened = $$c
              }
            },
            function ($event) {
              return _vm.animationCollapse()
            },
          ],
        },
      }),
      _c("div", { staticClass: "navbar-menu" }, [
        _c(
          "ul",
          { class: { "is-opened": _vm.isMenuOpened } },
          _vm._l(_vm.menuItems, function (item) {
            return item.enabled
              ? _c(
                  "li",
                  {
                    key: item.title,
                    staticClass: "dark-hover menu-item",
                    class: {
                      extended:
                        _vm.hasMenuItemSubItems(item) &&
                        _vm.$route.meta.group === item.to.name,
                    },
                  },
                  [
                    item.to && !_vm.hasMenuItemSubItems(item)
                      ? _c("router-link", {
                          attrs: {
                            to: item.to,
                            title: "Go to " + item.title,
                            custom: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var href = ref.href
                                  var isActive = ref.isActive
                                  var navigate = ref.navigate
                                  return [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "menu-link",
                                        class: {
                                          "selected-item":
                                            isActive ||
                                            _vm.$route.meta.group ===
                                              item.to.name,
                                        },
                                        attrs: { href: href },
                                        on: { click: navigate },
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "sidebar-icon",
                                          attrs: {
                                            src: _vm.getSideBarIcon(
                                              item,
                                              isActive ||
                                                _vm.$route.meta.group ===
                                                  item.to.name
                                            ),
                                            alt: "Icon",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            class: {
                                              animate: _vm.isAnimationAllowed,
                                            },
                                          },
                                          [_vm._v(_vm._s(item.title) + " ")]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      : _vm._e(),
                    _vm.hasMenuItemSubItems(item)
                      ? _c(
                          "router-link",
                          {
                            staticClass: "menu-link",
                            class: { "selected-item": _vm.isRouteGroup(item) },
                            attrs: { to: item.to },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "menu-sub-item",
                                class: { animate: _vm.isAnimationAllowed },
                              },
                              [
                                _c("img", {
                                  staticClass: "sidebar-icon",
                                  attrs: {
                                    src: _vm.getSideBarIcon(
                                      item,
                                      _vm.isRouteGroup(item)
                                    ),
                                    alt: "Icon",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    class: { animate: _vm.isAnimationAllowed },
                                  },
                                  [_vm._v(_vm._s(item.title))]
                                ),
                                _vm.isRouteGroup(item) &&
                                _vm.hasMenuItemSubItems(item)
                                  ? _c(
                                      "div",
                                      { staticClass: "menu-sub-item-wrapper" },
                                      _vm._l(item.subItems, function (s) {
                                        return s.enabled
                                          ? _c(
                                              "li",
                                              { key: s.title },
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    staticClass:
                                                      "menu-sub-link",
                                                    class: {
                                                      "selected-sub-link":
                                                        _vm.$route.meta
                                                          .title === s.title,
                                                    },
                                                    attrs: { to: s.to },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "menu-sub-item-title",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(s.title) + " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      }),
                                      0
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    item.clickHandler
                      ? _c(
                          "a",
                          {
                            staticClass: "menu-link",
                            attrs: { title: item.title },
                            on: {
                              click: function ($event) {
                                return item.clickHandler()
                              },
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "sidebar-icon",
                              attrs: {
                                src: _vm.getSideBarIcon(item),
                                alt: "Sidebar icon",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              { class: { animate: _vm.isAnimationAllowed } },
                              [_vm._v(_vm._s(_vm.getIconName(item)))]
                            ),
                          ]
                        )
                      : _vm._e(),
                    item.title === "Logout"
                      ? _c(
                          "div",
                          { staticClass: "logout-item" },
                          [
                            _c("img", {
                              staticClass: "sidebar-icon",
                              attrs: {
                                src: _vm.getSideBarIcon(item),
                                alt: "Sidebar icon",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                class: { animate: _vm.isAnimationAllowed },
                                attrs: {
                                  "uib-dropdown": "",
                                  "is-open": "isUserDropdownOpen",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { attrs: { "uib-dropdown-toggle": "" } },
                                  [_vm._v(_vm._s(_vm.getIconName(item)))]
                                ),
                              ]
                            ),
                            _c("popover", {
                              staticClass: "dropdown-component open",
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "face",
                                    fn: function () {
                                      return [
                                        _c(
                                          "a",
                                          {
                                            class: {
                                              animate: _vm.isAnimationAllowed,
                                            },
                                            attrs: {
                                              "uib-dropdown-toggle": "",
                                            },
                                          },
                                          [
                                            _c("div", {
                                              staticClass: "dropdown-arrow",
                                              class: {
                                                "open-dropdown":
                                                  _vm.isUserDropdownOpen,
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                  {
                                    key: "content",
                                    fn: function () {
                                      return [
                                        _c("div", [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "dropdown-menu",
                                              staticStyle: { display: "block" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "main-info" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "user-profile",
                                                    },
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          src: _vm.userProfileIcon,
                                                          alt: "user-profile",
                                                          title: "User profile",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "full-name",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.user.attributes
                                                            .fullName
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "email" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.user.attributes
                                                            .email
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "router-link",
                                                    {
                                                      staticClass: "sign-out",
                                                      attrs: { to: "/logout" },
                                                    },
                                                    [
                                                      _vm._v("Sign-Out"),
                                                      _c("span", {
                                                        staticClass:
                                                          "sign-out-icon",
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm.isAccountAdmin
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "account-section",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "accounts-header",
                                                        },
                                                        [_vm._v("Accounts")]
                                                      ),
                                                      _c(
                                                        "ul",
                                                        _vm._l(
                                                          _vm.sortedUserTenants,
                                                          function (
                                                            tenant,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "li",
                                                              {
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.setActiveTenant(
                                                                        null,
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    attrs: {
                                                                      "uib-tooltip":
                                                                        "tenant",
                                                                      "show-tooltip-on-text-overflow":
                                                                        "",
                                                                      "tooltip-placement":
                                                                        "right",
                                                                      "tooltip-enable":
                                                                        "false",
                                                                      "tooltip-append-to-body":
                                                                        "true",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        tenant
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c("input", {
                                                                  staticClass:
                                                                    "tenant-input",
                                                                  attrs: {
                                                                    type: "radio",
                                                                  },
                                                                }),
                                                                _vm.checkTenant(
                                                                  index
                                                                )
                                                                  ? _c("span", {
                                                                      staticClass:
                                                                        "check-tenant",
                                                                    })
                                                                  : _vm._e(),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e()
          }),
          0
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "menu-profile-info dropdown-component",
          class: { collapsed: _vm.isMenuOpened },
        },
        [
          _c("div", { staticClass: "user-profile" }, [
            _c("img", {
              attrs: {
                src: _vm.userProfileIcon,
                alt: "user-profile",
                title: "User profile",
              },
            }),
          ]),
          _c(
            "div",
            {
              staticClass: "welcome-user",
              class: { animate: _vm.isAnimationAllowed },
              attrs: { "uib-dropdown": "", "is-open": "isUserDropdownOpen" },
            },
            [
              _c("div", { attrs: { "uib-dropdown-toggle": "" } }, [
                _c("div", { staticClass: "name" }, [
                  _vm._v(_vm._s(_vm.user && _vm.user.attributes.fullName)),
                ]),
                _c("div", { staticClass: "role" }, [
                  _vm._v(_vm._s(_vm.userRole)),
                ]),
              ]),
            ]
          ),
          _c("popover", {
            staticClass: "dropdown-component open",
            scopedSlots: _vm._u([
              {
                key: "face",
                fn: function () {
                  return [
                    _c(
                      "a",
                      {
                        class: { animate: _vm.isAnimationAllowed },
                        attrs: { "uib-dropdown-toggle": "" },
                      },
                      [
                        _c("div", {
                          staticClass: "dropdown-arrow",
                          class: { "open-dropdown": _vm.isUserDropdownOpen },
                        }),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "content",
                fn: function () {
                  return [
                    _c("div", [
                      _c(
                        "div",
                        {
                          staticClass: "dropdown-menu",
                          staticStyle: { display: "block" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "main-info" },
                            [
                              _c("div", { staticClass: "user-profile" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.userProfileIcon,
                                    alt: "user-profile",
                                    title: "User profile",
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "full-name" }, [
                                _vm._v(_vm._s(_vm.user.attributes.fullName)),
                              ]),
                              _c("div", { staticClass: "email" }, [
                                _vm._v(_vm._s(_vm.user.attributes.email)),
                              ]),
                              _c(
                                "router-link",
                                {
                                  staticClass: "sign-out",
                                  attrs: { to: "/logout" },
                                },
                                [
                                  _vm._v("Sign-Out"),
                                  _c("span", { staticClass: "sign-out-icon" }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm.isAccountAdmin
                            ? _c("div", { staticClass: "account-section" }, [
                                _c("span", { staticClass: "accounts-header" }, [
                                  _vm._v("Accounts"),
                                ]),
                                _c(
                                  "ul",
                                  _vm._l(
                                    _vm.sortedUserTenants,
                                    function (tenant, index) {
                                      return _c(
                                        "li",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.setActiveTenant(
                                                null,
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                "uib-tooltip": "tenant",
                                                "show-tooltip-on-text-overflow":
                                                  "",
                                                "tooltip-placement": "right",
                                                "tooltip-enable": "false",
                                                "tooltip-append-to-body":
                                                  "true",
                                              },
                                            },
                                            [_vm._v(_vm._s(tenant))]
                                          ),
                                          _c("input", {
                                            staticClass: "tenant-input",
                                            attrs: { type: "radio" },
                                          }),
                                          _vm.checkTenant(index)
                                            ? _c("span", {
                                                staticClass: "check-tenant",
                                              })
                                            : _vm._e(),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }