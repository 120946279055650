const StreamTargetFormModal = () => import("@events/components/modals/StreamTargetFormModal.vue");
const HighlightFormModal = () => import("@events/components/modals/HighlightFormModal.vue");
const DemoEventFormModal = () => import("@events/components/modals/DemoEventFormModal.vue");
const EventFormModal = () => import("@events/components/modals/EventFormModal.vue");
const PpvTicketFormModal = () => import("@events/components/modals/PpvTicketFormModal.vue");
const AccessCodesLimitModal = () => import("@events/components/modals/AccessCodesLimitModal.vue");
const AddExtensionModal = () => import("@events/components/modals/AddExtensionModal.vue");

export default {
  StreamTargetFormModal,
  HighlightFormModal,
  DemoEventFormModal,
  EventFormModal,
  PpvTicketFormModal,
  AccessCodesLimitModal,
  AddExtensionModal,
};
