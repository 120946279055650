import store from "@/store";

export default function (to, from, next) {
  const isAccountAdmin = store.getters["user/isAccountAdmin"];
  const activeTenant = store.state.user.activeTenant || undefined;

  /**
   * This parameter specifies whether we need to update the query with the active tenant value,
   * in case user is account admin
   */
  const requiresAccountTenant = to.matched.some((r) => r.meta.requiresAccountTenant);

  if (isAccountAdmin && requiresAccountTenant) {
    const defaultTenant = store.state.user.data.attributes.tenants[0];
    const currentTenant = activeTenant || defaultTenant;

    if (to.query.tenant === undefined) {
      to.query.tenant = currentTenant;
      next(to);
    } else {
      next();
    }
  } else {
    next();
  }
}
