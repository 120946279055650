<template lang="pug">
    .col-xs-12.pagination-wrapper.filters
        items-per-page(v-if="showItemsSelector" :options="paginationItemsOptions")
        slot
        .pagination-section
            .count-of-elements(v-show="pagination.onPage > 0")
                span {{ paginateFrom }}
                | -
                span {{ paginateTo }}
                span(v-if="pagination.total") &nbsp;of&nbsp;{{ pagination.total }}
            .prev-next-component
                .pagination-buttons
                    a.button.btn.btn-primary.pxl-button.prev-button(
                        type="button"
                        @click="changePage(actions.PREV)"
                        :disabled="!isPrev"
                    )
                        i.fa.fa-caret-left(aria-hidden="true")
                    span.page-number(v-if="currentPageNumber") {{currentPageNumber}}
                    a.button.btn.btn-primary.pxl-button.next-button(
                        type="button"
                        @click="changePage(actions.NEXT)"
                        :disabled="!isNext"
                    )
                        i.fa.fa-caret-right(aria-hidden="true")
</template>

<script>
import { isObjEmpty } from "@/utils";
import ItemsPerPage from "./ItemsPerPage.vue";
import { root, service, analytics } from "@/constants";
import segmentAnalytics from "@/utils/analytics";

export default {
  name: "Pagination",
  components: { ItemsPerPage },
  props: {
    showItemsSelector: {
      type: Boolean,
      default: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    paginationItemsOptions: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      actions: root.pagination.actions,
      isPaginationActionAllowed: true,
    };
  },
  computed: {
    paginateFrom() {
      return this.pagination.offset + 1;
    },
    paginateTo() {
      const to = this.pagination.limit * this.currentPageNumber;

      if (!this.pagination.total) {
        return to;
      }

      return this.pagination.total > to ? to : this.pagination.total;
    },
    isNext() {
      return this.pagination.next;
    },
    isPrev() {
      return this.pagination.prev;
    },
    currentPageNumber() {
      return this.pagination.page + 1;
    },
  },
  watch: {
    pagination: {
      immediate: true,
      handler(value) {
        this.isPaginationActionAllowed = value && !isObjEmpty(value);
      },
    },
  },
  methods: {
    changePage(type) {
      if (!this.isPaginationActionAllowed) {
        return;
      }

      const { EVENT, LABEL } = analytics[service.ANALYTICS];

      segmentAnalytics.track(EVENT.PAGINATION_CLICK, {
        label: type === this.actions.NEXT ? LABEL.NEXT : LABEL.PREVIOUS,
      });

      this.isPaginationActionAllowed = false;

      const page = this.getPaginationPageByActionType(type);

      this.$updateQuery({
        limit: this.pagination.limit,
        offset: page * this.pagination.limit,
      });
    },
    getPaginationPageByActionType(type) {
      let currentPage = this.pagination.page;

      switch (type) {
        case this.actions.NEXT:
          return ++currentPage;
        case this.actions.PREV:
          return --currentPage;
        case this.actions.FIRST:
        default:
          return 0;
      }
    },
  },
};
</script>

<style lang="stylus">
.pagination-section
    color #647a8a
    .count-of-elements
        display inline-flex
        margin-right 10px
    .prev-next-component
        display inline-flex
        .pagination-buttons
            a
                border-radius 3px
                display inline-flex
                height 28px
                align-items center
            .prev-button
                margin-right 5px
        .page-number
            margin 0 10px 0 5px
</style>
