<template lang="pug">
    .bottom-bar-wrapper(:class="{ 'show-component': data.count > 0, 'callapsed-menu': isCollapseMenu }")
        .content
            span.selected Selected {{ data.name }} ({{ data.count }})
            template(v-for="(action, index) in selectActions")
                a.button.btn.select-all(
                    v-if="action.name === data.selectAction"
                    :key="index"
                    @click="selectData(action)"
                ) {{ action.viewName }}
            .actions-section
                .tooltip-wrapper(
                    v-for="(item, index) in bottomBarActions"
                    :key="index"
                    :title="item.tooltipMessage"
                )
                    .button.btn.btn-primary.pxl-button.action(
                        @click="selectData(item)"
                        :disabled="item.disabled"
                    )
                        i(:class="item.class")
                        | &nbsp;
                        | {{ item.viewName }}

</template>

<script>
import _ from "lodash";
import { root } from "@/constants";

export default {
  name: "BulkBottomBar",
  props: {
    data: {
      type: Object,
      required: true,
      validator: function (value) {
        return (
          _.isNumber(value.count) &&
          _.isString(value.name) &&
          _.isArray(value.actions) &&
          _.isString(value.selectAction)
        );
      },
    },
  },
  data() {
    const isCollapseMenu = JSON.parse(window.localStorage.getItem("isCollapseMenu")) || false;

    return {
      isCollapseMenu,
      selectActions: [
        {
          name: root.selectActions.SELECT_ALL,
          viewName: "Select All",
        },
        {
          name: root.selectActions.SELECT_ALL_PAGES,
          viewName: "Select All Pages",
        },
        {
          name: root.selectActions.CLEAR_SELECTION,
          viewName: "Clear selection",
        },
      ],
      bottomBarActions: [],
    };
  },
  watch: {
    "data.count": {
      immediate: true,
      handler: "moveUpElements",
    },
    "data.actions": {
      immediate: true,
      handler: "mapDataActions",
    },
  },
  methods: {
    selectData(action) {
      this.$emit("on-action", action.name);
    },
    mapDataActions(dataActions = []) {
      const mapActions = (values) => {
        return values.map((item) => ({
          name: item.action,
          viewName: item.action.charAt(0).toUpperCase() + item.action.slice(1),
          class: `bulk-${item.action}-icon`,
          disabled: true,
          tooltipMessage: undefined,
        }));
      };

      this.bottomBarActions = mapActions(dataActions);

      dataActions.forEach((item) => {
        const action = this.bottomBarActions.find((action) => action.name === item.action);

        action.disabled = !item.allowed;
        action.tooltipMessage = item.blockReason || "";
      });
    },
    moveUpElements(count) {
      const elements = document.querySelectorAll(
        ".create-button, .create-bulk-button, .list-wrapper",
      );
      const elementsArr = Array.from(elements);

      if (count > 0) {
        elementsArr.forEach((elem) => elem.classList.add("move-up"));
      } else {
        elementsArr.forEach((elem) => elem.classList.remove("move-up"));
      }
    },
  },
};
</script>

<style lang="stylus">
icon-styl = @block {
    display inline-block
    width 18px
    height 21px
}

.bottom-bar-wrapper
    transition all .6s ease-in-out
    position fixed
    bottom  0
    height 0
    z-index 1000
    margin-left calc(-15px)
    width 85%
    box-shadow 0 -2px 9px 0 rgba(0, 0, 0, 0.17)
    border solid 1px #d4d4d4
    background-image linear-gradient(to bottom, rgba(255, 255, 255, 0.9), #eeeeee)
    &.callapsed-menu
        width 94%
    &.show-component
        height 60px
        max-height 60px

        .content
            max-height 100%
            opacity 1
    .content
        opacity 0
        transition max-height .6s ease-in-out, opacity .6s ease-in-out
        font-size 16px
        padding 0 15px
        width 100%
        max-height 0
        height 100%
        display flex
        align-items center
        .select-all
            color #38a0b4
            text-decoration underline
            font-size 16px
            &:before
                content ''
                width 2px
                display inline-block
                background #ccc
                vertical-align middle
                height 16px
                margin 0 10px 0 0
        .selected
            padding 0
        .actions-section
            justify-content flex-end
            display flex
            flex 1 1

            .tooltip-wrapper
                margin-left 10px

                .action
                    display inline-flex
                    align-items center
                    .bulk-edit-icon
                        {icon-styl}
                        background url('/assets/images/bulk-edit-icon.svg')
                    .bulk-delete-icon
                        {icon-styl}
                        background url('/assets/images/bulk-delete-icon.svg')
                    .bulk-export-icon
                        {icon-styl}
                        background url('/assets/images/bulk-excel-icon.svg')
</style>
