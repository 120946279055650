<template lang="pug">
    div(:class="{'inverse': data.isInverseFilter }")
        span {{ data.title }}
        span.sort-elements(v-if="sorting" :class="sorting.className" @click="updateSorting")
        dropdown-checkbox(
            v-if="data.dropdownOptions && data.isInverseFilter"
            :isInverseFilter="data.isInverseFilter"
            :filter-name="data.name"
            :filter-options="filterOptionsForTable"
            :selected-item="selectedOptionsforTable"
            search-placeholder="Search"
            :all-options="true"
            :isSearch="data.isSearch"
            :showTitle="false"
            @select-filter="onChangeFilterInverse"
            @search="searchFilter"
            :ref="`filterSelect-${data.name}`"
        )
        template(v-else)
            dropdown(
                v-if="data.dropdownOptions"
                :name="data.name"
                :options="data.dropdownOptions"
                :selected-options.sync="selectedOptions[data.name]"
                :isMultiplyFilter="isMultiplyFilter"
                :disableShowAll="data.disableShowAll"
                :isSearch="data.isSearch"
                @change="onChangeFilter"
            )
            .dropdown-selected-items
                | {{ getSelectedFilter(data.name) }}
</template>

<script>
import { addAdditionStatusFields } from "./table";
import { Dropdown } from "@/components/interface";
import DropdownCheckbox from "@/components/interface/header/DropdownCheckbox.vue";

export default {
  name: "ColHead",
  components: { Dropdown, DropdownCheckbox },
  props: {
    data: {
      type: Object,
      required: true,
    },
    selectedOptions: {
      type: Object,
      required: true,
    },
    isMultiplyFilter: Boolean,
  },
  data() {
    return {
      filterOptionsForTable: [],
      searchValue: "",
    };
  },
  computed: {
    sorting() {
      if (!this.data.sort) {
        return null;
      }
      const sortValue = this.$route.query.sort || "";
      const sortType =
        sortValue && sortValue.includes(this.data.name)
          ? sortValue.charAt(0) === "-"
            ? "desc"
            : "asc"
          : "none";
      const sortClasses = { desc: "desc-sort", asc: "asc-sort", none: "" };

      return {
        isActive: sortValue && sortValue.includes(this.data.name),
        type: sortType,
        fieldName: this.data.name,
        className: sortClasses[sortType],
      };
    },
    selectedOptionsforTable() {
      const { name, dropdownOptions } = this.data;

      return dropdownOptions && this.selectedOptions?.[name]
        ? this.selectedOptions?.[name]?.map((item) => item.title)
        : [];
    },
    mappedOptionsForTable() {
      return this.data.dropdownOptions?.map((item) => ({ name: item.title, id: item.id }));
    },
  },
  watch: {
    "data.dropdownOptions": {
      handler(newVal) {
        this.filterOptionsForTable = this.mappedOptionsForTable;
      },
    },
    searchValue(newVal) {
      if (newVal === "") {
        this.filterOptionsForTable = this.mappedOptionsForTable;
      }
    },
  },
  methods: {
    getSelectedFilter(field) {
      return (this.selectedOptions[field] || []).map((option) => option.title).join(", ");
    },
    updateSorting() {
      if (this.sorting.isActive) {
        const nextSortValue =
          this.sorting.type === "asc" ? `-${this.sorting.fieldName}` : this.sorting.fieldName;

        this.$updateQuery({ sort: nextSortValue });
      } else {
        this.$updateQuery({ sort: this.sorting.fieldName });
      }
      this.$forceUpdate();
    },
    onChangeFilter({ options, field }) {
      const queryParams = { offset: 0 };

      if (options.length) {
        const smStatusQuery = !this.$route.query.smStatus && addAdditionStatusFields(field).length;

        queryParams[field] = options.map((option) => option.id).join(",");

        if (smStatusQuery) {
          queryParams.smStatus = addAdditionStatusFields(field).join(",");
        }
      } else {
        queryParams[field] = undefined;
      }

      this.$updateQuery(queryParams);
      this.$forceUpdate();
    },
    onChangeFilterInverse(data) {
      this.searchValue = "";
      const { values, filterName } = data;
      const queryParams = { offset: 0 };

      this.selectedOptions[filterName] = values;

      if (values.length) {
        queryParams[filterName] = values.join(",");
      } else if (this.data.name === filterName) {
        queryParams[filterName] = undefined;
      }

      this.$updateQuery(queryParams);
      this.$forceUpdate();
    },
    searchFilter(options = {}) {
      this.searchValue = options.q;
      this.filterOptionsForTable = options.q
        ? this.mappedOptionsForTable.filter((item) =>
            item.id.toLowerCase().includes(options.q.toLowerCase()),
          )
        : this.mappedOptionsForTable;
    },
  },
};
</script>

<style lang="stylus">
.inverse
    display flex
    flex-direction column

    & > .multi-select-checkbox-component.dropdown-component
        &.header-button-block-wrapper
            padding 0
            height 22px
            background-color transparent
            box-shadow none

        .dropdown-menu
            max-width dropdown-menu-width

            .search-area
                .search-field
                    font-size 13px
                    line-height 14px

        .dropdown-toggle
            display flex
            align-items center
            padding 6px 6px 6px 0px
            min-width 44px

            &:active
                box-shadow none

            .dropdown-arrow
                margin-left 0
                margin-right 6px
                margin-top 0
                height 10px

            .count-items
                margin-right 6px
                line-height 1
                font-size 12px

        .clear-items
            width 8px
            height 8px
            position relative
            top 0

        &.header-button-block-wrapper
            padding 0
            height 22px
            background-color transparent
            box-shadow none
</style>
