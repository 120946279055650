import Vue from "vue";
import Modal from "./Modal.vue";
import ConfirmModal from "./ConfirmModal.vue";
import store from "@/store";

const modalManager = Vue.extend(Modal);
const modalConfirmManager = Vue.extend(ConfirmModal);

const types = ["confirm"];
const managers = {
  confirm: modalConfirmManager,
  default: modalManager,
};

const ModalService = () => {
  const instances = {
    default: null,
    confirm: null,
  };

  const createInstance = (instance, type) => {
    instance = new managers[type]({
      store,
      el: document.createElement("div"),
    });

    document.body.appendChild(instance.$el);

    return instance;
  };

  return {
    open(type, options) {
      const modalType = types.includes(type) ? type : "default";

      if (!instances[modalType]) {
        instances[modalType] = createInstance(instances[modalType], modalType);
        createInstance(instances[modalType], modalType);
      }

      instances[modalType].show(type, options);
    },

    confirm(options) {
      this.open("confirm", options);
    },

    error(options) {
      this.open("error", options);
    },
  };
};

export default new ModalService();
