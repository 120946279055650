<template lang="pug">
    .multi-select-checkbox-component.dropdown-component.header-button-block-wrapper.dropdown(
        :class="{ active: count, open: isDropdownOpen }"
        v-click-outside="onOutsideClick"
    )
        button.btn.dropdown-btn.dropdown-toggle(@click="toggleDropdown" :aria-expanded="isDropdownOpen")
            span.dropdown-arrow(:class="{ 'open-dropdown': isDropdownOpen }")
            span.count-items(v-if="count") {{ count }}
            span.title(v-if="showTitle") {{ filterName }}
            a.clear-items(@click="clear" v-if="count") &nbsp;
        .dropdown-menu
            .search-area(v-if="isSearch")
                input.search-field(
                    :placeholder="searchPlaceholder"
                    @input="searchValue"
                    v-model="search"
                )
            .divider(v-if="isSearch")
            ul(v-infinite-scroll="loadMoreData" ref="scrollList")
                li(v-show="allowAllOptions")
                    checkbox(v-model="selectedAll") Select All
                li(v-for="option in filterOptions" :key="option.id")
                    checkbox(:value="isChecked(option)" @input="toggleOption(option)") {{ option.displayName || option.name }}
            .divider
            a.apply-area(@click="applyFilters" :class="{ active: allowApply === true && filterOptions.length }")
                | Apply
</template>

<script>
import _ from "lodash";
import SelectedItem from "./SelectedItem";
import { InfiniteScroll, ClickOutside } from "@/utils/directives";
import { Checkbox } from "@/components/shared";

export default {
  name: "DropdownCheckbox",
  components: { Checkbox },
  directives: { InfiniteScroll, ClickOutside },
  props: {
    filterName: {
      type: String,
      required: true,
    },
    filterOptions: {
      type: Array,
      required: true,
    },
    selectedItem: {
      type: Array,
      required: true,
    },
    isSearch: {
      type: Boolean,
      default: true,
    },
    allOptions: Boolean,
    searchPlaceholder: {
      type: String,
      default: "",
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    isInverseFilter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const checkedItems = new SelectedItem();

    return {
      allowApply: false,
      isDropdownOpen: false,
      search: "",
      checkedItems,
      selectedAll: false,
    };
  },
  computed: {
    allowAllOptions() {
      return this.allOptions !== false;
    },
    count() {
      return this.checkedItems.count;
    },
  },
  watch: {
    selectedItem: "setItems",
    selectedAll: {
      immediate: true,
      handler(value) {
        if (value) {
          this.$emit("load-all", this.search);
          this.checkedAll();
        } else if (
          !value &&
          this.checkedItems.itemsCount === this.filterOptions.length &&
          this.isDropdownOpen
        ) {
          this.checkedItems.reset();
        }
        this.allowApply = true;
      },
    },
    filterOptions: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal && newVal.length !== oldVal.length && this.selectedAll) {
          this.checkedAll();
          this.allowApply = true;
        }
      },
    },
  },
  mounted() {
    this.setItems();
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      this.selectedAll =
        this.filterOptions.length > 0 && this.checkedItems.itemsCount === this.filterOptions.length;
      this.setItems();
    },
    onOutsideClick() {
      this.isDropdownOpen = false;
      this.setItems();
    },
    clear() {
      this.allowApply = true;
      this.selectedAll = false;
      this.checkedItems.reset();
      this.applyFilters();
    },
    toggleOption({ id }) {
      this.allowApply = true;

      if (this.selectedAll) {
        this.selectedAll = false;
      }

      this.checkedItems.has(id) ? this.checkedItems.remove(id) : this.checkedItems.add(id, true);
    },
    setItems() {
      if (!this.isInverseFilter) {
        this.checkedItems.reset();
      }
      (this.selectedItem || []).forEach((key) => this.checkedItems.add(key, true));
    },
    loadMoreData() {
      if (this.selectedAll) {
        return;
      }

      this.$emit("load-more", this.search);
    },
    searchValue: _.debounce(function () {
      this.$emit("search", { q: this.search });

      this.$refs.scrollList.scrollTop = 0;
    }, 350),
    isChecked({ id }) {
      return this.checkedItems.has(id);
    },
    checkedAll() {
      this.filterOptions.forEach((option) => this.checkedItems.add(option.id, true));
    },
    applyFilters() {
      if (!this.allowApply) {
        return;
      }

      this.isDropdownOpen = false;
      this.allowApply = false;
      this.selectedAll = false;
      this.search = "";

      this.$emit("select-filter", {
        filterName: this.filterName,
        values: this.checkedItems.selectedItemsKeys,
      });
    },
  },
};
</script>

<style lang="stylus">
.multi-select-checkbox-component
    &.header-button-block-wrapper
        padding 0

    .dropdown-menu
        left -30px

        .search-area
            margin 10px

            .search-field
                border none
                outline none
                font-size 13px
                line-height 2
                width 100%

                label
                    margin 0
                    cursor pointer
                    font-weight normal

                    .checkbox-component
                        margin-right 11px

                        &:before
                            margin-bottom -2px

        .apply-area
            line-height 2
            color label-color
            padding-left 16px
            cursor not-allowed

            &.active
                color text-color
                cursor pointer

        .divider
            background-color #ddd

    .dropdown-btn
        height inherit
        background transparent

        &:hover, &:focus
            color #fff

        &:active
            outline none

    .dropdown-toggle
        .dropdown-arrow
            margin-left 0
            margin-right 8px

        .count-items
            margin-right 5px

        .title
            margin-right 5px

    .clear-items
        display inline-block
        width 15px
        height 15px
        background url('/assets/images/cross.svg') no-repeat
        background-size contain
        position relative
        top 3px

.multi-select-checkbox-component
    &.active
        box-shadow inset 0 0 4px rgba(0, 0, 0, 1)
        background-color rgba(32, 41, 50, 0.8)
</style>
