import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./plugins";

// import styles
import "animate.css/animate.css";
import "font-awesome/css/font-awesome.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-social/bootstrap-social.css";
import "@/assets/styles/main.styl";

// import 3rd party dependencies
import "branch-sdk/dist/build.min.js";

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
