<template>
  <TextInput ref="textInput" v-bind="$attrs" :value="value.name" @input="updateValue" />
</template>

<script>
import LocationApi from "@/api/LocationApi";
import TextInput from "./TextInput.vue";

const locationTypes = ["zipcode", "location"];

export default {
  name: "LocationsAutocomplete",
  components: {
    TextInput,
  },
  props: {
    locations: {
      type: Object,
      required: false,
    },
    locationType: {
      type: String,
      default: "location",
      validator: (value) => locationTypes.includes(value),
    },
    value: {
      type: Object,
      default: () => ({ name: "" }),
    },
  },
  data: () => ({
    innerInput: null,
    locationApi: null,
  }),
  mounted() {
    if (LocationApi.isGooglePlacesAPIAvailable()) {
      this.$nextTick(() => {
        this.innerInput = this.$refs.textInput.$refs.input;
        this.locationApi = new LocationApi(this.innerInput, this.onSelectPlace);
      });
    }
  },
  methods: {
    onSelectPlace(place) {
      this.updateLocations();

      const payload = {
        name: this.locationApi.getPlaceLocationType(this.locationType),
        lat: place.geometry?.location?.lat(),
        lng: place.geometry?.location?.lng(),
        placeId: place.place_id,
      };

      this.$emit("input", payload);
    },
    updateValue(value) {
      if (LocationApi.isGooglePlacesAPIAvailable()) {
        this.locationApi.getLocationPredictions(value, true);
      }

      this.$emit("input", { name: value });
    },
    updateLocations() {
      const {
        location: line1,
        locality: city,
        country,
        postal_code,

        administrative_area_level_1,
        administrative_area_level_2,
        administrative_area_level_3,
      } = this.locationApi.getPlaceLocationTypes(locationTypes);
      const state =
        administrative_area_level_1 || administrative_area_level_2 || administrative_area_level_3;

      if (!line1) {
        this.$refs.textInput.updateValue("");
      }

      this.$emit("update:locations", {
        line1,
        city,
        state,
        country,
        postal_code,
      });
    },
  },
};
</script>
