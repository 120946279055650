import auth from "./auth";
import user from "./user";
import temp from "./temp";

export const initialState = {
  auth: auth.state(),
  user: user.state(),
  temp: temp.state(),
};

export default {
  auth,
  user,
  temp,
};
