<template lang="pug">
    div
        h2.vidswap-leagues VidSwap Leagues
        
        .multi-select-checkbox-dropdown(
            :class="{ active: count, open: isDropdownOpen }"
            v-click-outside="onOutsideClick"
        )
            button.toggle-dropdown(@click.prevent="toggleDropdown" :aria-expanded="isDropdownOpen")
                span.title Please select one or more leagues
                img.open-indicator(:class="{ 'open-dropdown': isDropdownOpen }" src="assets/images/arrow.svg" width="10" height="10")
            .dropdown-menu
                .search-area
                    input.search-field(
                        placeholder="Search"
                        @input="searchValue"
                        v-model="search"
                        ref="search"
                    )
                    img.search-icon(src="assets/images/search.svg" width="16" height="16")
                .divider
                ul(v-infinite-scroll="loadMoreData" ref="scrollList")
                    li(v-for="option in filterOptions" :key="option.id")
                        checkbox.option-name(:value="isSelected(option.id)" @input="toggleOption(option.id)") {{ option.name }}
                        .option-label {{option.country}} {{option.state}} {{option.sportName}} {{option.organizationType}}
        .leagues-container(v-if="viewedLeagues.length > 0")
            .league-card(v-for="option in viewedLeagues" :key="option.id")
                img.remove-league-icon(@click="toggleOption(option.id)" src="assets/images/close.svg" width="8" height="8")
                .league-card__name {{ option.name }}
                .league-card__organization {{ option.organizationType }}
        .view-more-section(v-if="isShowMoreAvailable")
            a.button(@click="showMoreItems()")
                | View more
</template>

<script>
import _ from "lodash";
import { InfiniteScroll, ClickOutside } from "@/utils/directives";
import { Checkbox } from "@/components/shared";

export default {
  name: "DropdownCheckbox",
  components: { Checkbox },
  directives: { InfiniteScroll, ClickOutside },
  props: {
    filterOptions: {
      type: Array,
      required: true,
    },
    selectedOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isDropdownOpen: false,
      search: "",
      paginationLeagueNumber: 3,
      moreItemsIndex: 1,
      viewedLeagues: [],
    };
  },
  watch: {
    filterOptions() {
      this.getViewedLeagues();
    },
    selectedOptions() {
      this.getViewedLeagues();
    },
  },
  computed: {
    isShowMoreAvailable() {
      return this.selectedOptions.length > this.viewedLeagues.length;
    },
    count() {
      return this.selectedOptions.length;
    },
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      /* set focus on search input */
      if (this.isDropdownOpen) {
        this.$nextTick(function () {
          this.$refs.search.focus();
        });
      }
    },
    onOutsideClick() {
      this.isDropdownOpen = false;
    },
    isSelected(id) {
      return this.selectedOptions.includes(id);
    },
    toggleOption(id) {
      if (this.isSelected(id)) {
        this.$emit("check-item", id, "remove");
      } else {
        this.$emit("check-item", id, "add");
      }
    },
    loadMoreData() {
      if (!this.search) {
        this.$emit("load-leagues", this.search);
      }
    },
    searchValue: _.debounce(function () {
      if (this.search) {
        this.$emit("load-leagues", { q: this.search });
      }

      this.$refs.scrollList.scrollTop = 0;
    }, 350),
    showMoreItems() {
      this.moreItemsIndex++;
      this.getViewedLeagues();
    },
    getViewedLeagues() {
      const selectedLeagues = this.filterOptions.filter((element) =>
        this.isSelected(element.id) ? element : null,
      );
      this.viewedLeagues = selectedLeagues.slice(
        0,
        this.moreItemsIndex * this.paginationLeagueNumber,
      );
    },
  },
};
</script>

<style lang="stylus">
h2.vidswap-leagues
  margin 24px 0
  font-size 14px
  font-weight bold
  font-stretch normal
  font-style normal
  line-height 1.36
  letter-spacing normal
  color #2a4c70

.multi-select-checkbox-dropdown
  padding 0
  @media (min-width sm-breakpoint)
    width 40%
  @media (max-width sm-breakpoint)
    width 100%

  .toggle-dropdown
    width 100%
    height 32 px
    font-size 13px
    line-height 1.2
    text-align left
    border-radius 0
    color #7b8da0
    background #fff
    border solid 1px #4c5e70
    padding 1px 8px
    .open-indicator
      float right
      vertical-align middle
      margin-top 3px
      transition transform .1s ease-in-out
      &.open-dropdown
        transform rotate(-180deg)

  .dropdown-menu
    color text-color
    border-radius 0
    padding 0 0 5px 0
    background-color #fff
    top 40px
    margin-top 40px
    box-shadow 0 5px 7px 0 rgba(0, 0, 0, 0.2)
    border solid 1px rgba(0, 0, 0, 0.1)
    @media (min-width sm-breakpoint)
      width 40%
    @media (max-width sm-breakpoint)
      width 100%

    ul
      overflow auto
      max-height 150px
      min-width 172px
      padding 0
      list-style none
      margin-bottom 0
      &::-webkit-scrollbar
        max-height 150px
        width 6px

      &::-webkit-scrollbar-thumb
        border-radius 10px
        width 6px
        height auto
        background-color #d8d8d8

      li
        line-height 2
        padding 0 16px
        cursor pointer

        label
          position relative
          margin 0
          left 0
          cursor pointer
          font-size 14px
          font-weight 500
          line-height 1.36
          color #2a4c70
          height 19px

        .option-name
          font-size 14px
          font-weight 600
          font-stretch normal
          font-style normal
          line-height 1.36
          letter-spacing normal
          color #2a4c70
          margin-bottom 4px

        .option-label
          font-size 12px
          font-weight normal
          font-stretch normal
          font-style normal
          line-height 1.33
          letter-spacing normal
          color #2a4c70
          margin-top 6px

    .search-area
      margin 5px 10px 10px 10px

      .search-field
        width calc(100% - 16px)
        height 15px
        outline none
        border none
        font-size 13px
        font-weight normal
        font-stretch normal
        font-style normal
        line-height normal
        letter-spacing normal
        color #7b8da0

      img.search-icon
        margin-top 5px
        float right

    .divider
      background-color #ddd
      margin 0

.leagues-container
  left 0
  display flex
  flex-wrap wrap
  row-gap 17px
  column-gap 17px
  width 100%
  padding 24px 0

  .league-card
    height 70px
    padding 8px
    border solid 1px #ddd
    position relative
    display flex
    flex-direction column
    justify-content space-between
    @media (min-width sm-breakpoint)
      width calc(33.3333333333% - 12px)
    @media (max-width sm-breakpoint)
      width 100%

    img.remove-league-icon
      position absolute
      top 8px
      right 8px
      cursor pointer

    &__name
      font-size 14px
      font-weight bold
      font-stretch normal
      font-style normal
      line-height normal
      letter-spacing normal
      color #2a4c70
      padding-right 8px

    &__organization
      font-size 12px
      font-weight normal
      font-stretch normal
      font-style normal
      line-height 1.33
      letter-spacing normal
      color #2a4c70

.view-more-section
  display flex
  justify-content center
  padding 6px 0 14px

  .button
    text-decoration none
    font-size 14px
    color #00b5ca
    font-weight bold
    cursor pointer
</style>
