<template lang="pug">
    div(v-if="domInserted")
        .modal-backdrop(:class="[visibleClass]")
        .modal.open(:class="[visibleClass, modalWindowClass]")
            .modal-dialog(:class="[options.customClass]")
                .modal-content
                    template(v-if="type === 'default'")
                        .modal-header
                            button.close(type='button' @click='close') &times;
                            h4.modal-title(v-if='options.title') {{ options.title }}
                        .modal-body
                            p(v-if='options.text') {{ options.text }}
                            div(v-if='options.html' v-html='options.html')
                        .modal-footer
                            template(v-if="options.buttons && options.buttons.length")
                                button.btn(
                                    v-for='(button, index) in options.buttons'
                                    :key="index"
                                    :class='button.classes'
                                    @click='button.click($event)'
                                ) {{ button.text }}
                    template(v-else-if="type === 'error'")
                        .modal-header
                            button.close(type='button' @click='close') &times;
                            h4.modal-title(v-if='options.title') {{ options.title }}
                        .modal-body
                            p(v-if='options.message') {{ options.message }}
                            div(v-if='options.html' v-html='options.html')
                        .modal-footer
                            button.btn.btn-default(@click='onCancelConfirm') Cancel
                    template(v-else-if="type === 'custom'")
                        .modal-header
                            button.close(type='button' @click='close') &times;
                            h4.modal-title(v-if='options.title') {{ options.title }}
                        .modal-body
                            component(:is="options.component" v-bind="options.data" @close="close")
</template>

<script>
import { ClickOutside } from "@/utils/directives";
import ModalComponents from "@/modules/modals.js";
import ModalMixin from "./modalMixin";

export default {
  name: "Modal",
  components: ModalComponents,
  directives: { ClickOutside },
  mixins: [ModalMixin],
  data() {
    return {
      domInserted: false,
      visible: false,
      type: "default",
      options: {
        component: "",
        data: null,
        title: "",
        text: "",
        html: "",
        message: "",
        buttons: [],
        customClass: "",
      },
    };
  },
};
</script>

<style lang="stylus"></style>
