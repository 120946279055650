<template lang="pug">
  .iframe-container
    iframe(ref="player-iframe" class="responsive-iframe" :src="iframeUrl" frameborder="0" allowfullscreen scrolling="no")
</template>

<script>
import _ from "lodash";
import EventsApi from "@/api/EventsApi";

const cleanEmptyValues = (obj) => JSON.parse(JSON.stringify(obj, (k, v) => v || undefined));

export default {
  name: "PixellotPlayer",
  props: {
    source: {
      type: [Object, String],
      default: "",
    },
    event: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    iframeUrl() {
      const embedBase = `${globalUrls.webSDKUrl}embed.html`;
      const queryParams = {
        "hd-src": this.videoSrc.hd,
        "pano-src": this.videoSrc.pano,
        "project-id": globalUrls.sbProjectId,
        title: this.config.title,
        autoplay: this.config.autoplay,
        mute: this.config.mute,
      };
      const query = new URLSearchParams(cleanEmptyValues(queryParams)).toString();

      return embedBase + "?" + query;
    },
    videoSrc() {
      if (typeof this.source === "string") {
        return { hd: this.source };
      }

      return this.source;
    },
  },
  mounted() {
    const PXLT_IFRAME = {
      SOURCE: "pixellot-player",
      EVENTS: {
        PLAYER_READY: "player-ready",
      },
    };

    this.$refs["player-iframe"].contentWindow.addEventListener("message", (event) => {
      const eventSource = event.data && event.data.source;
      const eventName = event.data && event.data.eventName;

      if (eventSource === PXLT_IFRAME.SOURCE && eventName === PXLT_IFRAME.EVENTS.PLAYER_READY) {
        const player = this.$refs["player-iframe"].contentWindow.player;

        this.loadAnalytics(player);
        this.loadTags(player);
      }
    });
  },
  methods: {
    async loadTags(player) {
      if (!this.config.showTags) {
        return;
      }

      const tagsData = await this.getEventTagsData();

      if (tagsData?.length) {
        player.on("loadedmetadata", () => player.tags.show(tagsData));
      }
    },

    loadAnalytics(player) {
      if (!this.config.enableAnalytics) {
        return;
      }

      const analyticsOptions = this.getEventAnalytics(this.event, this.config.playbackType);

      player.analytics.updateOptions(analyticsOptions);

      player.on("source-changed", ({ mode }) => {
        const analyticsOptions = this.getEventAnalytics(this.event, this.config.playbackType, mode);

        player.analytics.updateOptions(analyticsOptions);
      });
    },

    getEventTagsData() {
      return EventsApi.getEventTags(this.event.id).then((response) => {
        return response.map((item) => {
          const { time, type } = item.attributes;

          return {
            id: item.id,
            status: "sync",
            startTime: time,
            type,
            iconUrl: `assets/images/tags/${type.split("_")[0]}/${type}.png`,
          };
        });
      });
    },

    getEventAnalytics(event, playbackType, playbackMode = "hd") {
      const { id, attributes } = event;
      const getAnalyticsEntityType = (playbackType, playbackMode = "hd") => {
        const videoModes = { hd: "HD", pano: "Pano" };

        const mapEntityTypes = {
          event: `Event ${videoModes[playbackMode]}`,
          highlight: "Game highlight",
        };

        return mapEntityTypes[playbackType];
      };

      return {
        contentTitle: attributes.name,
        clubName: attributes.clubName,
        clubID: attributes.clubId,
        entityID: id,
        entityType: getAnalyticsEntityType(playbackType, playbackMode),
        sportType: _.get(attributes, "gameInfo.name"),
        eventID: attributes.mainBEId || attributes.internalId,
        systemID: _.get(attributes, "system.id"),
      };
    },
  },
};
</script>

<style lang="stylus">
.iframe-container
    position: relative
    height: 100% //
    width: 100%
    overflow: hidden
    padding-top: 56.25% /* 16:9 Aspect Ratio */

.responsive-iframe
    position: absolute
    top: 0
    left: 0
    bottom: 0
    right: 0
    width: 100%
    height: 100%
    border: none
</style>
