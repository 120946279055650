import accountRoutes from "./accounts/routes";
import adminRoutes from "./admin/routes";
import authRoutes from "./auth/routes";
import clubsRoutes from "./clubs/routes";
import eventsRoutes from "./events/routes";
import inventoryRoutes from "./inventory/routes";
import mediaRoutes from "./media/routes";
import teamsRoutes from "./teams/routes";
import toolsRoutes from "./tools/routes";
import usersRoutes from "./users/routes";
import ottSettingsRoutes from "./ottSettings/routes";
import marketplaceRoutes from "./marketplace/routes";

const routes = [
  ...accountRoutes,
  ...adminRoutes,
  ...authRoutes,
  ...clubsRoutes,
  ...eventsRoutes,
  ...inventoryRoutes,
  ...mediaRoutes,
  ...teamsRoutes,
  ...toolsRoutes,
  ...usersRoutes,
  ...ottSettingsRoutes,
  ...marketplaceRoutes,
];

export default routes;
