<template lang="pug">
    validation-provider(tag="label" :name="name")
        input.checkbox-component(
            type="checkbox"
            :checked="value"
            :disabled="disabled"
            :indeterminate.prop="indeterminate"
            :value="value"
            @input="$emit('input', $event.target.checked)"
            ref="checkbox"
            )
        slot
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  name: "Checkbox",
  components: { ValidationProvider },
  props: {
    value: Boolean,
    indeterminate: Boolean,
    disabled: Boolean,
    name: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="stylus">
input[type="checkbox"]:focus
    outline 0
    outline-offset 0

input[type="checkbox"]:disabled
    opacity .5


.checkbox-component
    appearance none
    position relative
    cursor pointer
    margin-right 10px!important

.checkbox-component:before
    content ""
    width 15px
    height 15px
    border-radius 2px
    border solid 1px #ccc
    display inline-block
    background #fff

.checkbox-component:checked:after
    content  ''
    position absolute
    left 2px
    top 2px
    width 12px
    height 11px
    object-fit contain
    background url('/assets/images/checkbox-check.svg')

.checkbox-component:indeterminate:after
    content  ''
    position absolute
    left 2px
    top 2px
    width 11px
    height 11px
    background-size contain
    background url('/assets/images/indeterminate.svg')
</style>
