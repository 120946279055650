import _ from "lodash";
import AuthApi from "./AuthApi";
import { analytics, service, stateRefs, systems } from "@/constants";
import segmentAnalytics from "@/utils/analytics";

const { MODE } = systems[stateRefs.system].vpu;
const { OFFLINE } = systems[stateRefs.system].filterOptions.status;
const { EVENT } = analytics[service.ANALYTICS];

export default {
  openVpuManagement(systemId) {
    const newWindow = window.open("", "_blank");
    const payload = {
      data: { venueId: systemId },
      mode: MODE,
    };

    segmentAnalytics.track(EVENT.OPEN_VPU);

    return AuthApi.generateWSPToken(payload).then(({ host, token, wsp, idToken }) => {
      newWindow.location.href = `${host}?ws=${wsp.hostname}&port=${wsp.port}&token=${token}&idToken=${idToken}`;
    });
  },

  isVpuAllowed(event) {
    const status =
      _.get(event, "metrics.status.value") || _.get(event, "systemMetrics.status.value");

    return status && status !== OFFLINE;
  },
};
