var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TextInput",
    _vm._b(
      {
        ref: "textInput",
        attrs: { value: _vm.value.name },
        on: { input: _vm.updateValue },
      },
      "TextInput",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }