<template lang="pug">
    div
        ul.nav.nav-tabs
            li.uib-tab.nav-item(v-for="(tab, idx) in tabs" :key="idx" :class="tabClasses(tab, idx)")
                a.delete-icon(v-if="tab.removeOption" title="Delete Tab" @click.prevent="deleteTab(idx)")
                a.nav-link(href @click.prevent="select(idx)") {{ tab.title }}
        div.tab-content
            div.tab-pane(v-for="(tab, idx) in tabs" :key="idx" :class="tabClasses(tab, idx)")
                slot(:name="idx")
            slot(name="default")
</template>

<script>
export default {
  name: "UiTabs",
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      activeIndex: 0,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.activeIndex = value;
      },
    },
  },
  methods: {
    select(index) {
      if (this.activeIndex === index) {
        return;
      }

      this.activeIndex = index;
      this.$emit("input", index);
    },
    tabClasses(tab, index) {
      return {
        disabled: tab.disabled,
        active: this.activeIndex === index,
      };
    },
    deleteTab(index) {
      this.activeIndex = this.tabs.length - 1;
      this.$emit("on-delete", index);
    },
  },
};
</script>
