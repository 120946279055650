var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-xs-12 pagination-wrapper filters" },
    [
      _vm.showItemsSelector
        ? _c("items-per-page", {
            attrs: { options: _vm.paginationItemsOptions },
          })
        : _vm._e(),
      _vm._t("default"),
      _c("div", { staticClass: "pagination-section" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.pagination.onPage > 0,
                expression: "pagination.onPage > 0",
              },
            ],
            staticClass: "count-of-elements",
          },
          [
            _c("span", [_vm._v(_vm._s(_vm.paginateFrom))]),
            _vm._v("-"),
            _c("span", [_vm._v(_vm._s(_vm.paginateTo))]),
            _vm.pagination.total
              ? _c("span", [_vm._v(" of " + _vm._s(_vm.pagination.total))])
              : _vm._e(),
          ]
        ),
        _c("div", { staticClass: "prev-next-component" }, [
          _c("div", { staticClass: "pagination-buttons" }, [
            _c(
              "a",
              {
                staticClass: "button btn btn-primary pxl-button prev-button",
                attrs: { type: "button", disabled: !_vm.isPrev },
                on: {
                  click: function ($event) {
                    return _vm.changePage(_vm.actions.PREV)
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "fa fa-caret-left",
                  attrs: { "aria-hidden": "true" },
                }),
              ]
            ),
            _vm.currentPageNumber
              ? _c("span", { staticClass: "page-number" }, [
                  _vm._v(_vm._s(_vm.currentPageNumber)),
                ])
              : _vm._e(),
            _c(
              "a",
              {
                staticClass: "button btn btn-primary pxl-button next-button",
                attrs: { type: "button", disabled: !_vm.isNext },
                on: {
                  click: function ($event) {
                    return _vm.changePage(_vm.actions.NEXT)
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "fa fa-caret-right",
                  attrs: { "aria-hidden": "true" },
                }),
              ]
            ),
          ]),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }