const defaultSuccessMessage = "Copied";
const defaultErrorMessage = "Sorry, copy to clipboard failed";

const copy = async (copyText) => {
  if (!navigator.clipboard) {
    return false;
  }

  try {
    await navigator.clipboard.writeText(copyText);
  } catch (error) {
    return false;
  }

  return true;
};

export default {
  bind: function (el, { expression, value }, vnode) {
    el.copyToClipboard = async () => {
      let data;

      if (typeof value === "function") {
        data = await vnode.context[expression]();

        if (!data) {
          return;
        }
      } else {
        data = el.$copyValue;
      }

      const copyText = (data && data.text) || data;
      const successMessage = (data && data.successMessage) || defaultSuccessMessage;
      const errorMessage = (data && data.errorMessage) || defaultErrorMessage;

      const copied = await copy(copyText);

      if (copied) {
        vnode.context.$toaster.info(successMessage);
      } else {
        vnode.context.$toaster.error(errorMessage);
      }
    };
    el.$copyValue = value;
    el.addEventListener("click", el.copyToClipboard);
  },
  update: function (el, { value }) {
    el.$copyValue = value;
  },
  unbind: function (el) {
    el.removeEventListener("click", el.copyToClipboard);
  },
};
