<template lang="pug">
    .notification-toasters-wrap
        .notification-toaster(
            v-for="toaster in toasters"
            :key="toaster.id"
            :class="[toaster.type]"
            class=""
            @click="close(toaster.id)"
        )
            h4.toaster-title(v-if="toaster.title") {{ toaster.title }}
            .toaster-message(v-if="toaster.message") {{ toaster.message }}
</template>

<script>
import { uniqueKey } from "@/utils";

export default {
  name: "Toaster",
  data() {
    return {
      toasters: [],
    };
  },
  methods: {
    show(options) {
      const id = uniqueKey();
      const closeDuration = options.duration || 5000;

      this.toasters.push({ ...options, id });

      setTimeout(() => this.close(id), closeDuration);
    },

    close(id) {
      const index = this.toasters.findIndex((t) => t.id === id);

      if (index !== -1) {
        this.toasters.splice(index, 1);
      }
    },

    closeAll() {
      this.toasters = [];
    },
  },
};
</script>

<style lang="stylus">
.notification-toasters-wrap
    position fixed
    top 0
    right 10px;
    z-index 1000
    .notification-toaster
        position relative
        display flex
        flex-direction column
        align-items flex-start
        justify-content: flex-start;
        margin-top 10px
        padding 10px 20px
        width 200px
        min-height 50px
        min-width 250px
        border-radius 2px
        color white
        background-color rgba(110, 110, 110, 0.9)
        animation animatebottom 0.4s
        z-index 10
        cursor pointer
        &.success
            background-color rgba(81, 163, 81, .9)
        &.info
            background-color rgba(47, 150, 180, .9)
        &.error
            background-color rgba(195, 39, 35, .9)
        .toaster-title
        .toaster-message
            display block

@keyframes animatebottom
    from
        top -200px
    to
        top 0
</style>
