var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.domInserted
    ? _c("div", [
        _c("div", { staticClass: "modal-backdrop", class: [_vm.visibleClass] }),
        _c(
          "div",
          {
            staticClass: "modal open",
            class: [_vm.visibleClass, _vm.modalWindowClass],
          },
          [
            _c(
              "div",
              { staticClass: "modal-dialog", class: [_vm.options.customClass] },
              [
                _c(
                  "div",
                  { staticClass: "modal-content" },
                  [
                    _vm.type === "default"
                      ? [
                          _c("div", { staticClass: "modal-header" }, [
                            _c(
                              "button",
                              {
                                staticClass: "close",
                                attrs: { type: "button" },
                                on: { click: _vm.close },
                              },
                              [_vm._v("×")]
                            ),
                            _vm.options.title
                              ? _c("h4", { staticClass: "modal-title" }, [
                                  _vm._v(_vm._s(_vm.options.title)),
                                ])
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "modal-body" }, [
                            _vm.options.text
                              ? _c("p", [_vm._v(_vm._s(_vm.options.text))])
                              : _vm._e(),
                            _vm.options.html
                              ? _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(_vm.options.html),
                                  },
                                })
                              : _vm._e(),
                          ]),
                          _c(
                            "div",
                            { staticClass: "modal-footer" },
                            [
                              _vm.options.buttons && _vm.options.buttons.length
                                ? _vm._l(
                                    _vm.options.buttons,
                                    function (button, index) {
                                      return _c(
                                        "button",
                                        {
                                          key: index,
                                          staticClass: "btn",
                                          class: button.classes,
                                          on: {
                                            click: function ($event) {
                                              return button.click($event)
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(button.text))]
                                      )
                                    }
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      : _vm.type === "error"
                      ? [
                          _c("div", { staticClass: "modal-header" }, [
                            _c(
                              "button",
                              {
                                staticClass: "close",
                                attrs: { type: "button" },
                                on: { click: _vm.close },
                              },
                              [_vm._v("×")]
                            ),
                            _vm.options.title
                              ? _c("h4", { staticClass: "modal-title" }, [
                                  _vm._v(_vm._s(_vm.options.title)),
                                ])
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "modal-body" }, [
                            _vm.options.message
                              ? _c("p", [_vm._v(_vm._s(_vm.options.message))])
                              : _vm._e(),
                            _vm.options.html
                              ? _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(_vm.options.html),
                                  },
                                })
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "modal-footer" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-default",
                                on: { click: _vm.onCancelConfirm },
                              },
                              [_vm._v("Cancel")]
                            ),
                          ]),
                        ]
                      : _vm.type === "custom"
                      ? [
                          _c("div", { staticClass: "modal-header" }, [
                            _c(
                              "button",
                              {
                                staticClass: "close",
                                attrs: { type: "button" },
                                on: { click: _vm.close },
                              },
                              [_vm._v("×")]
                            ),
                            _vm.options.title
                              ? _c("h4", { staticClass: "modal-title" }, [
                                  _vm._v(_vm._s(_vm.options.title)),
                                ])
                              : _vm._e(),
                          ]),
                          _c(
                            "div",
                            { staticClass: "modal-body" },
                            [
                              _c(
                                _vm.options.component,
                                _vm._b(
                                  {
                                    tag: "component",
                                    on: { close: _vm.close },
                                  },
                                  "component",
                                  _vm.options.data,
                                  false
                                )
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            ),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }