var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "images-wrapper" }, [
    _c("div", { staticClass: "image-section" }, [
      _c("img", {
        ref: "img",
        attrs: { width: "210", height: "120", alt: "Image" },
      }),
      _vm.isImageInfo && _vm.showDetails
        ? _c("div", { staticClass: "info-block" }, [
            _c("div", { staticClass: "name" }, [
              _vm._v(_vm._s(_vm.image.name)),
            ]),
            _c("span", [_vm._v(_vm._s(_vm.imageSize) + " MB")]),
          ])
        : _vm._e(),
      _c("div", {
        staticClass: "opacity-wrapper",
        class: { disable: _vm.disabled },
      }),
      _c("i", {
        staticClass: "fa fa-trash-o",
        class: { hidden: _vm.disabled },
        on: {
          click: function ($event) {
            return _vm.$emit("onDelete")
          },
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }