var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Loader"),
      _vm.isAdminLayout
        ? _c(
            "AdminLayout",
            [_c("router-view", _vm._b({}, "router-view", _vm.$props, false))],
            1
          )
        : _c("router-view", _vm._b({}, "router-view", _vm.$props, false)),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }