export default [
  {
    name: "Categories",
    path: "/categories",
    meta: {
      title: "Categories",
      group: "OTT Settings",
      requiresAuth: true,
      requiresAccountTenant: true,
      accessRoles: ["accountadmin"],
    },
    redirect: {
      name: "Manage Categories",
    },
    component: (resolve) => require(["@ottSettings/pages/index.vue"], resolve),
    children: [
      {
        name: "Manage Categories",
        path: "/categories/manage",
        meta: {
          title: "Categories",
          group: "OTT Settings",
          requiresAuth: true,
          requiresAccountTenant: true,
          accessRoles: ["accountadmin"],
        },
        component: (resolve) => require(["@ottSettings/pages/manage.vue"], resolve),
      },
      {
        name: "All Categories",
        path: "/categories/all",
        meta: {
          title: "Categories",
          group: "OTT Settings",
          requiresAuth: true,
          requiresAccountTenant: true,
          accessRoles: ["accountadmin"],
        },
        component: (resolve) => require(["@ottSettings/pages/all-categories.vue"], resolve),
      },
    ],
  },
  {
    name: "CategoriesCreate",
    path: "/categories/create",
    meta: {
      title: "Create category",
      requiresAuth: true,
      accessRoles: ["accountadmin"],
      group: "OTT Settings",
    },
    component: (resolve) => require(["@ottSettings/pages/create.vue"], resolve),
  },
  {
    name: "CategoriesEdit",
    path: "/categories/:id/edit",
    meta: {
      title: "Edit category",
      requiresAuth: true,
      accessRoles: ["accountadmin"],
      group: "OTT Settings",
    },
    component: (resolve) => require(["@ottSettings/pages/_id/edit.vue"], resolve),
  },
  {
    name: "CategoriesEditBulk",
    path: "/categories/bulk",
    meta: { title: "Bulk: Edit categories", requiresAuth: true },
    component: (resolve) => require(["@ottSettings/pages/bulk.vue"], resolve),
  },
  {
    name: "Graphics",
    path: "/graphics",
    meta: {
      title: "Graphics",
      group: "OTT Settings",
      requiresAuth: true,
      requiresAccountTenant: true,
      accessRoles: ["accountadmin"],
    },
    component: (resolve) => require(["@ottSettings/pages/graphics.vue"], resolve),
  },
  {
    name: "Restrictions",
    path: "/restrictions",
    meta: {
      title: "Restrictions",
      group: "OTT Settings",
      requiresAuth: true,
      requiresAccountTenant: true,
      accessRoles: ["accountadmin"],
    },
    redirect: {
      name: "Geoblocking",
    },
    component: (resolve) => require(["@ottSettings/pages/restrictions.vue"], resolve),
    children: [
      {
        name: "Geoblocking",
        path: "/restrictions/geolocation",
        meta: {
          title: "Restrictions",
          group: "OTT Settings",
          requiresAuth: true,
          requiresAccountTenant: true,
          accessRoles: ["accountadmin"],
        },
        component: (resolve) => require(["@ottSettings/pages/geo-blocking.vue"], resolve),
      },
      {
        name: "Commerce",
        path: "/restrictions/commerce",
        meta: {
          title: "Restrictions",
          group: "OTT Settings",
          requiresAuth: true,
          requiresAccountTenant: true,
          accessRoles: ["accountadmin"],
        },
        component: (resolve) => require(["@ottSettings/pages/commerce.vue"], resolve),
      },
    ],
  },
  {
    name: "Subscriptions",
    path: "/subscriptions",
    meta: {
      title: "Subscriptions",
      group: "OTT Settings",
      requiresAuth: true,
      requiresAccountTenant: true,
      accessRoles: ["accountadmin"],
    },
    component: (resolve) => require(["@ottSettings/pages/subscriptions.vue"], resolve),
  },
  {
    name: "SubscriptionCreate",
    path: "/subscriptions/create",
    meta: {
      title: "Create subscription",
      requiresAuth: true,
      accessRoles: ["accountadmin"],
      group: "OTT Settings",
    },
    component: (resolve) => require(["@ottSettings/pages/create-subscription.vue"], resolve),
  },
  {
    name: "SubscriptionEdit",
    path: "/subscriptions/:id/edit",
    meta: {
      title: "Edit subscription",
      requiresAuth: true,
      accessRoles: ["accountadmin"],
      group: "OTT Settings",
    },
    component: (resolve) => require(["@ottSettings/pages/_id/edit-subscription.vue"], resolve),
  },
];
