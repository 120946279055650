var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vue-datepicker", {
    staticClass: "pixellot-date-range-picker",
    attrs: {
      disabled: _vm.disabled,
      type: "date",
      format: _vm.format,
      value: _vm.value,
      "disabled-date": _vm.disabledDates,
      range: true,
      "popup-class": "pixellot-date-range-picker-popup",
      shortcuts: _vm.shortcuts[_vm.mode],
      confirm: true,
      "confirm-text": "OK",
    },
    on: {
      input: _vm.onChange,
      close: function () {
        return (_vm.isActive = false)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "icon-calendar",
        fn: function () {
          return [_c("div")]
        },
        proxy: true,
      },
      {
        key: "input",
        fn: function () {
          return [
            _c(
              "div",
              {
                staticClass: "date-range-wrapper",
                class: {
                  "open-input": _vm.isOpenDatePickerInput,
                  "header-button-block-wrapper": !_vm.isOpenDatePickerInput,
                },
                attrs: { disabled: _vm.disabled },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return function () {
                      return (_vm.isActive = true)
                    }.apply(null, arguments)
                  },
                },
              },
              [
                _c("label", {
                  staticClass: "glyphicon glyphicon-calendar",
                  class: { "change-color": _vm.isOpenDatePickerInput },
                  attrs: { id: "calendar", for: "input-picker" },
                }),
                _c("input", {
                  staticClass: "input-picker",
                  attrs: {
                    id: "input-picker",
                    type: "text",
                    placeholder: "Choose Date Range",
                    autocomplete: _vm.autocomplete,
                    disabled: true,
                  },
                  domProps: { value: _vm.formattedDates },
                }),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }