import axios from "./axios";
import store from "@/store";
import { root, clubs, stateRefs } from "@/constants";
import { buildResponseArrayGetInterceptor, buildSearchURL } from "@/utils";

const baseUrl = "/api/v3/clubs";

const STREAM_TYPES = [
  { id: "wowza", name: "RTMP Push" },
  { id: "zixi", name: "Reliable UDP" },
];

const SEARCH_FILTERS = [{ name: "Club name", value: "name" }];

const SELECTION_MODE_OPTION = [
  { id: "add", name: "Add to existing" },
  { id: "replace", name: "Replace all with" },
];

export default {
  get filtersValues() {
    return ["name", "usersCount", "clubStatus", "streamType"];
  },
  get streamTypes() {
    return STREAM_TYPES;
  },
  get defaultStreamType() {
    return STREAM_TYPES[1].id;
  },
  get modeOptions() {
    return SELECTION_MODE_OPTION;
  },
  get clubStatus() {
    return clubs[stateRefs.club].clubStatus;
  },
  get clubStatuses() {
    return clubs[stateRefs.club].clubStatuses;
  },
  get defaultClubStatus() {
    return clubs[stateRefs.club].clubStatuses[0].id;
  },
  get filterOptions() {
    return clubs[stateRefs.club].filterOptions;
  },
  get searchFilters() {
    return SEARCH_FILTERS;
  },
  get adminsViewCount() {
    return clubs[stateRefs.club].adminsViewCount;
  },
  get bettingResolutions() {
    return root.bettingSettings;
  },
  get allowedBreakDownsSportTypes() {
    return root.ALLOWED_BREAKDOWNS_SPORTTYPES;
  },
  get kickOffTimeList() {
    return clubs[stateRefs.club].kickOffTimeList;
  },
  getClubs(params = { limit: 500 }) {
    const activeTenant = store.state.user.activeTenant;

    if (activeTenant && !params.tenant) {
      params.tenant = activeTenant;
    }

    return axios
      .get(`${baseUrl}${buildSearchURL(params)}`)
      .then((response) => buildResponseArrayGetInterceptor(response, params));
  },
  createClub(data) {
    return axios.post(`${baseUrl}`, data);
  },
  getClubById(id) {
    return axios.get(`${baseUrl}/${id}`).then((response) => response.data);
  },
  getClubRequest(clubId, userId, status) {
    return axios
      .get(`${baseUrl}/${clubId}/request?userId=${userId}&status=${status}`)
      .then((response) => response.data)
      .then((data) => {
        if (data.length === 0) {
          throw new Error("Not found!");
        }

        const response = {
          data,
          clubIds: data.map((i) => i.attributes.clubId),
          userIds: data.map((i) => i.attributes.userId),
        };

        return response;
      });
  },
  partialUpdate(clubId, data) {
    return axios.patch(`${baseUrl}/${clubId}`, data).then((response) => response.data);
  },
  syncClubUsersCount(clubId) {
    return axios.post(`${baseUrl}/${clubId}/users/count`).then((response) => response.data);
  },
  addPreRoll(clubId, data) {
    return axios.patch(`${baseUrl}/${clubId}/pre-rolls`, data);
  },
  addClubLogo(clubId, data) {
    return axios
      .post(`${baseUrl}/${clubId}/logos`, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => response.data);
  },
  updateClubLogo(clubId, logoId, data) {
    return axios.patch(`${baseUrl}/${clubId}/logos/${logoId}`, data);
  },
  updateClub(clubId, data) {
    return axios.patch(`${baseUrl}/${clubId}`, data);
  },
  deleteClubLogo(clubId, logoId) {
    return axios.delete(`${baseUrl}/${clubId}/logos/${logoId}`);
  },
  bulkUpdateClubs(formData) {
    return axios.patch(`${baseUrl}/bulk`, formData).then((response) => {
      return {
        updatedClubs: response.data.updated,
        failedClubs: response.data.failed,
      };
    });
  },
  getVenues(params) {
    return axios
      .get(`${baseUrl}/venues${buildSearchURL(params)}`)
      .then((response) => buildResponseArrayGetInterceptor(response, params));
  },
  getApplications(params) {
    return axios
      .get(`/api/v3/applications${buildSearchURL(params)}`)
      .then((response) => buildResponseArrayGetInterceptor(response, params));
  },
  getClubsExportData(params) {
    return axios
      .get(`/api/v3/clubs/export-data${buildSearchURL(params)}`)
      .then((response) => response);
  },
  convertClubsToPlay(data) {
    return axios.post(`${baseUrl}/convert-to-play`, data);
  },
};
