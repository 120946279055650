export const isObjEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const s4 = () => {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
};

export const uniqueKey = () => {
  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

export const isSubtypeOf = (obj1, obj2) => {
  return Object.keys(obj2).every((obj2Key) => {
    // eslint-disable-next-line eqeqeq
    return obj1[obj2Key] == obj2[obj2Key];
  });
};

export const getUpdatedState = (initialState, state, ignoredKeys = []) => {
  return Object.keys(state).reduce((acc, stateKey) => {
    if (Array.isArray(state[stateKey])) {
      if (
        state[stateKey].length !== initialState[stateKey]?.length ||
        !objCompare(state[stateKey], initialState[stateKey])
      ) {
        acc[stateKey] = state[stateKey];
      }
    } else if (state[stateKey] !== null && getType(state[stateKey]) === "Object") {
      const diff = getUpdatedState(initialState[stateKey], state[stateKey], ignoredKeys);

      if (!isObjEmpty(diff)) {
        acc[stateKey] = diff;
      }
    } else if (initialState?.[stateKey] !== state[stateKey] && !ignoredKeys.includes(stateKey)) {
      acc[stateKey] = state[stateKey];
    }

    return acc;
  }, {});
};

export const objDeepClone = (obj) => {
  const cloneObj = Array.isArray(obj) ? [] : {};

  for (const key in obj) {
    cloneObj[key] =
      Array.isArray(obj[key]) || getType(obj[key]) === "Object" ? objDeepClone(obj[key]) : obj[key];
  }

  return cloneObj;
};

export const getType = (value) => Object.prototype.toString.call(value).slice(8, -1);

export const objCompare = (objA, objB) => JSON.stringify(objA) === JSON.stringify(objB);

export const convertBytesToMB = (size) => {
  return (size / 1000000).toFixed(1);
};

export const convertBytesToGB = (size) => {
  return (size / 1000000000).toFixed(1);
};

export const convertKbToMb = (size) => {
  const converted = size / 1000;

  return converted >= 1 ? Math.round(converted) : converted.toFixed(1);
};

export const promiseTimeout = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const safeCb = (cb) => {
  return typeof cb === "function" ? cb : () => {};
};

export const omitBy = (object, predicate) => {
  const result = {};

  for (const [key, value] of Object.entries(object)) {
    if (!predicate(key, value)) {
      result[key] = value;
    }
  }

  return result;
};

export const getParamFromHash = (hash, param) => {
  return new URLSearchParams(hash.substring(1)).get(param);
};

export const decodeBase64 = (string) => {
  const decodedHash = atob(string);

  // Decoding from special symbols and hieroglyphs
  const decodedUnicode = decodedHash
    .split("")
    .map((character) => {
      return "%" + ("00" + character.charCodeAt(0).toString(16)).slice(-2);
    })
    .join("");

  const decodedURIComponent = decodeURIComponent(decodedUnicode);

  return JSON.parse(decodedURIComponent);
};

export const convertDays = (countOfDays) => {
  let months = 0;
  let years = 0;
  let days = 0;
  let weeks = 0;

  while (countOfDays) {
    if (countOfDays >= 365) {
      years++;
      countOfDays -= 365;
    } else if (countOfDays >= 30) {
      months++;
      countOfDays -= 30;
    } else if (countOfDays >= 7) {
      weeks++;
      countOfDays -= 7;
    } else {
      days++;
      countOfDays--;
    }
  }

  return {
    years,
    months,
    weeks,
    days,
  };
};

export const toSentenceCase = (name) => {
  name = name.replace(/([A-Z])/g, " $1");
  name = name.charAt(0).toUpperCase() + name.slice(1);

  return name;
};

export const combineDateAndTime = (date, time) => {
  const dateTime = date instanceof Date ? new Date(date.getTime()) : new Date(date);

  time = time instanceof Date ? time : new Date(time);
  dateTime.setHours(time.getHours());
  dateTime.setMinutes(time.getMinutes());
  dateTime.setSeconds(0);

  return dateTime;
};

export const getDate = (date) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const normalizedDate = new Date(date);

  return `${
    months[normalizedDate.getMonth()]
  } ${normalizedDate.getDate()}, ${normalizedDate.getFullYear()}`;
};

export const sortArrayAlphabetically = (arr) => {
  return arr.sort((a, b) => a.localeCompare(b));
};
