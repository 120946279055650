<template lang="pug">
    div {{ timeLeft }}
</template>

<script>
import moment from "moment";

const dayInMilliseconds = 1000 * 60 * 60 * 24;
const defaultInterval = 1000;
const normalize = (value) => (value < 10 ? `0${value}` : value);

export default {
  name: "Timer",
  props: {
    countdown: {
      type: Number,
      required: true,
    },
    filter: {
      type: Function,
      required: false,
      default: null,
    },
    interval: {
      type: Number,
      required: false,
      default: null,
    },
    callback: {
      type: Function,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      timeLeft: 0,
      timer: null,
    };
  },
  watch: {
    countdown: "resetTimer",
  },
  mounted() {
    this.resetTimer();
  },
  beforeDestroy() {
    this.destroyTimer();
  },
  methods: {
    timerCozyFilter(timeObject) {
      const showCountdown = timeObject._milliseconds < dayInMilliseconds;

      let result = "";

      if (showCountdown) {
        result = ["hours", "minutes", "seconds"]
          .map((item) => normalize(timeObject._data[item]))
          .join(":");
      } else {
        // TODO: Calculate time to using provided timeObject
        result = moment(new Date(Date.now() + timeObject._milliseconds)).from();
      }

      return result;
    },
    updateTime(diff) {
      const filter = this.filter || this.timerCozyFilter;

      this.timeLeft = filter(diff);
    },
    resetTimer() {
      this.destroyTimer();

      const interval = this.interval || defaultInterval;

      let diff = moment.duration(this.countdown, "seconds");

      this.updateTime(diff);

      this.timer = setInterval(() => {
        diff = moment.duration(diff.asMilliseconds() - interval, "milliseconds");

        if (diff <= 0) {
          this.destroyTimer();

          if (this.callback) {
            this.callback();
          }

          return;
        }

        this.updateTime(diff);
      }, interval);
    },
    destroyTimer() {
      clearInterval(this.timer);
    },
  },
};
</script>
