import _ from "lodash";
import axios from "./axios";
import store from "@/store";
import { root } from "@/constants";
import { buildResponseArrayGetInterceptor, buildSearchURL } from "@/utils";

const baseUrl = "/api/v3/teams";

export default {
  getTeams(params) {
    const activeTenant = store.state.user.activeTenant;

    if (activeTenant && !params.tenant) {
      params.tenant = activeTenant;
    }

    if (params.q) {
      params.search = params.q;
      params.q = undefined;
    }

    return axios
      .get(`${baseUrl}${buildSearchURL(params)}`)
      .then((response) => buildResponseArrayGetInterceptor(response, params));
  },

  getTeamById(id) {
    return axios.get(`${baseUrl}/${id}`).then((response) => response.data);
  },

  createTeam(data) {
    return axios.post(baseUrl, data);
  },

  updateTeam(id, data) {
    return axios.patch(`${baseUrl}/${id}`, data);
  },

  getLogoFromModel(images, resolution, useDefaultImage = true) {
    const defaultValue = useDefaultImage
      ? "https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcT9zYZmlDK8gCz2rPw6d3kBnZp_wSs1qPStFhFNgQOOvmPJjpTQOA"
      : undefined;

    return _.get(images, `logo.${resolution}.url`, defaultValue);
  },

  getTeamsExportData(params) {
    return axios
      .get(`${baseUrl}/export-data${buildSearchURL(params)}`)
      .then((response) => response);
  },
  get socialMediasLinks() {
    return root.SOCIAL_MEDIA_LINK_TYPES;
  },
  get generalUrlRegex() {
    return root.GENERAL_URL_REGEX;
  },
};
