var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.domInserted
    ? _c("div", [
        _c("div", { staticClass: "modal-backdrop", class: [_vm.visibleClass] }),
        _c(
          "div",
          {
            staticClass: "modal open",
            class: [_vm.visibleClass, _vm.modalWindowClass],
          },
          [
            _c("div", { staticClass: "modal-dialog" }, [
              _c("div", { staticClass: "modal-content" }, [
                _c("div", { staticClass: "modal-header" }, [
                  _c("h3", { staticClass: "modal-title" }, [
                    _vm._v(_vm._s(_vm.options.title)),
                  ]),
                ]),
                _c("div", { staticClass: "modal-body" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-xs-12 text-center" }),
                    _vm._v(_vm._s(_vm.options.text)),
                  ]),
                ]),
                _c("div", { staticClass: "modal-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { type: "button" },
                      on: { click: _vm.onCancelConfirm },
                    },
                    [_vm._v("No")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: { click: _vm.onSuccessConfirm },
                    },
                    [_vm._v("Yes")]
                  ),
                ]),
              ]),
            ]),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }