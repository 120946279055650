var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("validation-provider", {
    staticClass: "pxl-select",
    attrs: {
      tag: "div",
      vid: _vm.vid || _vm.name,
      rules: _vm.rules,
      name: _vm.errorLabel || _vm.label || _vm.name,
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var errors = ref.errors
          return [
            _c("v-select", {
              ref: "select",
              class: {
                invalid: errors[0],
                "has-value": _vm.hasValue,
                focus: _vm.focused,
              },
              attrs: {
                options: _vm.filteredOptions,
                id: _vm.name,
                value: _vm.value,
                reduce: _vm.reducer,
                label: _vm.optionsLabel,
                disabled: _vm.disabled,
                multiple: _vm.multiple,
                clearable: _vm.clearable,
                placeholder: _vm.placeholder,
                "get-option-key": _vm.getOptionKey,
                "get-option-label": _vm.getOptionLabel,
                "create-option": _vm.createOption,
                "push-tags": _vm.pushTags,
                taggable: _vm.taggable,
              },
              on: {
                input: _vm.updateValue,
                search: _vm.onSearch,
                open: _vm.onOpen,
                close: _vm.onClose,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "selected-option",
                    fn: function (option) {
                      return [
                        _vm._t("selected-option", null, { option: option }),
                      ]
                    },
                  },
                  {
                    key: "option",
                    fn: function (option) {
                      return [_vm._t("option", null, { option: option })]
                    },
                  },
                ],
                null,
                true
              ),
            }),
            _vm.label !== null
              ? _c(
                  "label",
                  {
                    class: { "dropdown-open": _vm.focused },
                    attrs: { for: _vm.name },
                    on: { click: _vm.focusInput },
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.label || _vm.name))])]
                )
              : _vm._e(),
            errors[0]
              ? _c(
                  "div",
                  { staticClass: "errors-container" },
                  _vm._l(errors, function (error, index) {
                    return _c("span", { key: index }, [
                      _c("strong", [_vm._v("Error: ")]),
                      _vm._v(_vm._s(error)),
                    ])
                  }),
                  0
                )
              : _vm._e(),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }