<template lang="pug">
    .pixellot-popover
        .pixellot-popover-face(@click="toggle" v-click-outside="hide")
            slot(name="face")
        .pixellot-popover-content(v-if="opened" :class="`popover-${position}`")
            .pixellot-popover-content-inner
                slot(name="content")
</template>

<script>
import { ClickOutside } from "@/utils/directives";

export default {
  name: "Popover",
  directives: { ClickOutside },
  props: {
    position: {
      type: String,
      default: "up",
      validator: (value) => ["up", "down"].indexOf(value) !== -1,
    },
  },
  data() {
    return {
      opened: false,
    };
  },
  computed: {
    positionPopoverClass() {
      return `popover-${this.position}`;
    },
  },
  methods: {
    hide() {
      this.opened = false;
    },
    show() {
      this.opened = true;
    },
    toggle() {
      this.opened = !this.opened;
    },
  },
};
</script>

<style lang="stylus">
.pixellot-popover
    position relative
    .pixellot-popover-content
        position absolute
        z-index 1010
        text-align left
        white-space normal
        background-color #fff
        background-clip padding-box
        .pixellot-popover-content-inner
            position relative
            width max-content
            padding 16px

    .popover-up
        bottom calc(100% + 10px)
        left -43px
        max-width: 276px
        padding 1px
        border 1px solid #ccc
        border 1px solid rgba(0, 0, 0, .2)
        border-radius 6px
        box-shadow 0 5px 10px rgba(0, 0, 0, .2)
    .popover-down
        bottom -63px
        left -146px
        padding 5px 0
        border-radius 4px
        border solid 1px rgba(0, 0, 0, 0.1)
</style>
