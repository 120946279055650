var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("validation-provider", {
    staticClass: "pxl-input full-width relative",
    attrs: {
      tag: "div",
      vid: _vm.vid || _vm.name,
      rules: _vm.rules,
      "custom-messages": _vm.customMessages,
      name: _vm.errorLabel || _vm.label || _vm.name,
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var errors = ref.errors
          return [
            _c("input", {
              ref: "input",
              staticClass: "form-control",
              class: {
                "ng-invalid ng-dirty": errors[0],
                "ng-touched ng-valid has-value": _vm.hasValue,
                "ng-untouched has-value": _vm.placeholder,
              },
              attrs: {
                id: _vm.name,
                type: _vm.type,
                disabled: _vm.disabled,
                placeholder: _vm.placeholder,
                autocomplete: _vm.autocomplete,
              },
              domProps: { value: _vm.value },
              on: {
                input: function ($event) {
                  return _vm.updateValue($event.target.value)
                },
              },
            }),
            _c(
              "label",
              { attrs: { for: _vm.name }, on: { click: _vm.focusInput } },
              [_c("span", [_vm._v(_vm._s(_vm.label || _vm.name))])]
            ),
            errors[0]
              ? _c(
                  "div",
                  { staticClass: "errors-container" },
                  _vm._l(errors, function (error, index) {
                    return _c("span", { key: index }, [
                      _c("strong", [_vm._v("Error: ")]),
                      _vm._v(_vm._s(error)),
                    ])
                  }),
                  0
                )
              : _vm._e(),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }