var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.hideDropdown,
          expression: "hideDropdown",
        },
      ],
      staticClass: "multi-select-component dropdown-component dropdown",
      class: { open: _vm.isDropdownOpen },
    },
    [
      _c(
        "a",
        {
          staticClass: "dropdown-toggle",
          attrs: { "aria-expanded": _vm.isDropdownOpen },
          on: { click: _vm.toggleDropdown },
        },
        [
          _c("div", {
            staticClass: "dropdown-arrow",
            class: { "open-dropdown": _vm.isDropdownOpen },
          }),
        ]
      ),
      _c("div", { staticClass: "dropdown-menu" }, [
        _vm.isSearch
          ? _c("div", { staticClass: "search-area" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.search,
                    expression: "search",
                  },
                ],
                staticClass: "search-field",
                attrs: { placeholder: "Search" },
                domProps: { value: _vm.search },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.search = $event.target.value
                  },
                },
              }),
            ])
          : _vm._e(),
        _vm.isSearch ? _c("div", { staticClass: "divider" }) : _vm._e(),
        _c(
          "ul",
          [
            !_vm.disableShowAll
              ? _c(
                  "li",
                  {
                    class: { "selected-item": _vm.isSelectedAll },
                    on: { click: _vm.toggleAll },
                  },
                  [_c("label", [_vm._v("Show all")])]
                )
              : _vm._e(),
            _vm._l(_vm.filteredList, function (option, index) {
              return _c(
                "li",
                {
                  key: index,
                  class: { "selected-item": _vm.isSelected(option) },
                  on: {
                    click: function ($event) {
                      return _vm.toggleOption(option)
                    },
                  },
                },
                [_c("label", [_vm._v(_vm._s(option.title))])]
              )
            }),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }