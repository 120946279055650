import { AuthApi } from "@/api";
import toaster from "@/plugins/toaster";
import firebase from "@/plugins/firebase";
import Bugsnag from "@bugsnag/js";

const state = () => {
  return {
    data: null,
  };
};

const getters = {
  isAuthenticated(state, getters, rootState) {
    const hasUser = Boolean(rootState.user.data?.attributes?.role);

    return hasUser;
  },
};

const actions = {
  async login(store, { email, password }) {
    const handleUserResponse = (user) => {
      if (user.data?.attributes.isActive === false) {
        toaster.error("You are not allowed to login with this user. User is inactive.");

        return Promise.reject(new Error(`You are not allowed to login with this user.`));
      }

      if (user.data?.attributes.role === "user") {
        try {
          toaster.error("User account is not an administrator.");
        } catch (error) {
          Bugsnag.notify(error);

          return Promise.reject(new Error(`You are not allowed to login with this user.`));
        }
      }

      store.dispatch("loginWithUser", user);
    };

    try {
      const user = await AuthApi.login({ email, password });

      handleUserResponse(user);
    } catch (err) {
      if (err && err.status !== 404) {
        store.dispatch("logout");

        return Promise.reject(new Error("Username or password is incorrect"));
      }

      // try to sign in user to Firebase when user does not exist in ABE db
      await firebase
        .signInUserWithEmailAndPassword({ email, password })
        .then(async (user) => {
          AuthApi.setAuthToken(user.token);

          const abeUser = await AuthApi.getUsersMe();
          user.data.attributes.role = abeUser.data?.attributes.role;
          user.data.attributes.tenants = abeUser.data?.attributes.tenants;

          handleUserResponse(user);
        })
        .catch(() => {
          store.dispatch("logout");

          return Promise.reject(new Error("Username or password is incorrect"));
        });
    }
  },
  loginWithUser(store, user) {
    AuthApi.setAuthToken(user.token);
    AuthApi.setAuthorizationHeader(user.token);
    store.commit("user/SET_USER", user.data, { root: true });

    const role = user.data.attributes.role;

    if (role === "accountadmin") {
      const defaultTenant = user.data.attributes.tenants[0];
      const currentTenant = store.rootState.user.activeTenant || defaultTenant;

      store.commit("user/SET_ACTIVE_TENANT", currentTenant, { root: true });
    }
  },
  logout(store) {
    AuthApi.logout();
    store.commit("user/SET_USER", null, { root: true });
    store.commit("user/SET_ACTIVE_TENANT", null, { root: true });
    store.commit("user/SET_TEMPORARY_SYSTEM_TAB", [], { root: true });
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
