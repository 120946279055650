var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "create-component" },
    [
      _c("router-link", { attrs: { to: _vm.path } }, [
        _c(
          "a",
          {
            staticClass: "button btn create-button",
            attrs: { disabled: _vm.disabled },
          },
          [_c("div", { staticClass: "plus-icon" })]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }