<template lang="pug">
    .multi-select-component.dropdown-component.dropdown(:class="{ open: isDropdownOpen }" v-click-outside="hideDropdown")
        a.dropdown-toggle(@click="toggleDropdown" :aria-expanded="isDropdownOpen")
            // span(class="main-title" v-if="mainTitle") {{ mainTitle }}
            .dropdown-arrow(:class="{ 'open-dropdown': isDropdownOpen }")
        .dropdown-menu
            .search-area(v-if="isSearch")
                input.search-field(
                    placeholder="Search"
                    v-model="search"
                )
            .divider(v-if="isSearch")
            ul
                li(v-if="!disableShowAll" @click="toggleAll" :class="{ 'selected-item': isSelectedAll }")
                    label Show all
                li(v-for="(option, index) in filteredList" :key="index" :class="{ 'selected-item': isSelected(option) }" @click="toggleOption(option)")
                    label {{ option.title }}
</template>

<script>
import { ClickOutside } from "@/utils/directives";

export default {
  name: "Dropdown",
  directives: { ClickOutside },
  props: {
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    selectedOptions: {
      type: Array,
      default: () => [],
    },
    disableShowAll: Boolean,
    isMultiplyFilter: Boolean,
    isSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDropdownOpen: false,
      search: "",
    };
  },
  computed: {
    selectedIds() {
      return this.selectedOptions.map((option) => option.id);
    },
    isSelectedAll() {
      return !this.selectedOptions.length;
    },
    filteredList() {
      return this.isSearch
        ? this.options.filter((item) => {
            return item.title.toLowerCase().includes(this.search.trim().toLowerCase());
          })
        : this.options;
    },
  },
  methods: {
    hideDropdown() {
      this.isDropdownOpen = false;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      this.search = "";
    },
    toggleAll() {
      this.options.forEach((option) => (option.selected = false));
      this.change([]);
      this.search = "";
    },
    toggleOption(option) {
      // at least one element should be selected when there is no 'Show All' option
      if (this.disableShowAll && this.isSelected(option)) {
        return;
      }

      let selected = this.selectedOptions.slice(0);

      if (this.isSelected(option)) {
        const index = selected.findIndex((opt) => opt.id === option.id);

        selected.splice(index, 1);
      } else {
        if (!this.isMultiplyFilter) {
          selected = [];
        }
        selected.push(option);
      }

      this.change(selected);
    },
    change(options) {
      this.$emit("change", { field: this.name, options });
      this.$emit("update:selectedOptions", options);
    },
    isSelected(option) {
      return this.selectedIds.includes(option.id);
    },
  },
};
</script>

<style lang="stylus">
.multi-select-component
    & + .dropdown-selected-items
        font-size 12px
        font-weight normal
        color #b3cade
        overflow hidden
        white-space nowrap
        text-overflow ellipsis
        width 100%
        position relative
    .dropdown-menu
        ul li label
            color link-color
        ul li.selected-item label
            font-weight 900
            color button-background-color
</style>
