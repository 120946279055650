var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "iframe-container" }, [
    _c("iframe", {
      ref: "player-iframe",
      staticClass: "responsive-iframe",
      attrs: {
        src: _vm.iframeUrl,
        frameborder: "0",
        allowfullscreen: "",
        scrolling: "no",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }