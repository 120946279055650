export default [
  {
    name: "Media",
    path: "/media",
    meta: { title: "Media", requiresAuth: true, accessRoles: ["admin"] },
    component: (resolve) => require(["@media/pages/index.vue"], resolve),
  },
  {
    name: "ClubPreRolls",
    path: "/media/:id/pre-rolls",
    meta: { title: "Media: Club PreRolls", requiresAuth: true },
    component: (resolve) => require(["@media/pages/_id/pre-rolls.vue"], resolve),
  },
  {
    name: "ClubImages",
    path: "/media/:id/image-gallery",
    meta: { title: "Media: Image Gallery", requiresAuth: true },
    component: (resolve) => require(["@media/pages/_id/image-gallery.vue"], resolve),
  },
];
