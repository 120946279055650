var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-container" },
    [
      _c("pixellot-sidebar"),
      !_vm.hidePlayer
        ? _c("widget-player", {
            attrs: {
              event: _vm.activeEvent,
              "active-highlight": _vm.activeHighlight,
            },
            on: { close: _vm.hideWidgetPlayer },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "main-content container-fluid col-md-10" },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }