import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import modules, { initialState } from "./modules";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules,

  mutations: {
    reset(state) {
      Object.keys(state).forEach((key) => Object.assign(state[key], initialState[key]));
    },
  },
  strict: debug,
  plugins: [
    createPersistedState({
      paths: ["user.data", "user.activeTenant", "user.temporarySystemsTabs"],
    }),
  ],
});
